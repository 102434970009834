.fixed_err_mespostion .Toastify__toast-container.Toastify__toast-container--bottom-right{
    bottom: 6em;
}

.Toastify__toast-container {
    width: auto!important;
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
  color: #155724!important;
    background-color: #d4edda!important;
    border-color: #c3e6cb!important;
  }
  .Toastify__progress-bar--success {
  background-color: #155724!important;
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
  color: #721c24!important;
    background-color: #f8d7da!important;
    border-color: #f5c6cb!important;
  }
  .Toastify__progress-bar--error {
  background-color: #721c24!important;
  }
  .Toastify__close-button {
    color: #000!important;
  }