@font-face 
{
	font-family: my_font;
	src: url(./font/myfont.otf);
}

body {
  background-color: #ffffff!important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.processing_text{    position: absolute;
  left: 30%;
  top: 7px;
  color: #ee7b70;
}
.import_processing_text{    position: absolute;
  left: 42%;
  top: 9px;
  color: #ee7b70;
}