.recodash_importbooksdata_popup {
    // margin: 3rem auto auto 0rem;
    // max-width: 375px;


    & .recodash_importbooksdata_tabbox {
        & nav.recodash_importbooksdata_tabbox_navtab {
            padding-top: 4px;
            padding-bottom: 5px;

            & .nav.nav-tabs {
                border-bottom: 0px solid #dee2e6;

                & a.nav-item.nav-link.recodash_importbooksdata_taball {
                    color: #31719b;
                    background-color: #ffffff;
                    border-color: #31719b;
                    outline-color: #31719b !important;
                    border-radius: 0px;
                    padding: 0.3rem 2.5rem 0.3rem 2.5rem;
                    font-size: 12px;
                    border: 1px solid #31719b;
                    border-right: 0px;
                    font-weight: 500;
                    text-align: center;
                }

                & a.nav-item.nav-link.recodash_importbooksdata_taball.active {
                    color: #ffffff !important;
                    background-color: #fc7d72;
                }

                & a.nav-item.nav-link.recodash_importbooksdata_tab_r2 {
                    border-right: 1px solid #31719b;
                }
            }

        }
    }

    & .card.recodash_importbooksdata_tallybox {
        text-align: center;
        font-weight: 700;
        padding: 6.5px 15px;
        background: linear-gradient(182deg, #fdb6ae 50%, #fff1d7);
        margin: auto auto;
        cursor: pointer;


        & h4.recodash_tallywithconfig_titlefs {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0.8rem;
        }

        & span.tally_imgbox {
            padding-bottom: 7px;
        }


    }

    & .card.recodash_importbooksdata_excelbox {
        text-align: center;
        font-weight: 700;
        padding: 18px 15px;
        background: linear-gradient(181deg, #8eb6b3 5%, #fff1d7);
        margin: auto auto;

        & span.excelbox_imgbox {
            margin-bottom: 0rem;
            cursor: pointer;
        }

        & .recodash_importbooksdata_exceltext {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0rem;

            & span.recodash_importbooksdata_exceldelbox {
                vertical-align: bottom;
                cursor: pointer;
            }
        }
    }

    & button.btn.btn-default.recodash_importbooksdata_submitbtn {
        background-color: #31719b;
        border-color: #31719b;
        padding: 3px 10px 5px 10px;
        border-radius: 40px;
        border: 0px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
    }

}

button.btn.btn-default.recodash_Blank_template_btn {
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    border-radius: 40px;
    padding: 3px 10px 5px 10px;
    background-color: #eeeeee;
    color: #000;
}

button.btn.btn-default.recodash_gstr1blanktemp_btn {
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    border-radius: 40px;
    padding: 5px 10px 7px 10px;
    background-color: #eeeeee;
    color: #000;
    width: 100%;
}

span.recodash_Blank_template_orbox {
    padding: 0px 5px;
}

h4.importtextortextfs {
    position: relative;
    line-height: 5;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

span.textdate_bold {
    font-weight: 700;
}

.card.recodash_importbooksdata_card {


    & table.table.table-striped.recodash_importbooksdata_TB {

        & thead tr th {
            border-top: 0;
            font-size: 12px;
            padding: 0;
            font-weight: 500;
        }

        & tr td {
            font-size: 11px;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
        }

        & .recodash_importbooksdata_borgrayclr {
            border-bottom: 0px solid #dee2e6 !important;
            text-align: center;
        }

        & .downloadtempimpostd {
            font-size: 9px;
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;
        }

        & .recodash_importbooksdata_borboot {
            border-bottom: 1px solid #dee2e6 !important;
            padding-bottom: 5px;
            padding-top: 5px;

        }

        & .recodash_importbooksdata_borgrayclr {
            border-right: 1px solid #dee2e6 !important;
        }

        & span.recodash_importbooksdata_browdeliconaligright {
            float: right;
        }

        span.browsegstr1 img {
            width: 14px;
            cursor: pointer;
            margin-left: 5px;
        }

        td.recodash_importbooksdata_monthtextclr {
            color: rgb(252, 125, 114);
            text-align: left;
        }

        td.rdTextCenter {
            text-align: center;
        }

    }

    .recodash_importbooksdata_impoforsuptext {
        font-size: 10px;
        color: #9c9c9c;
        text-align: right;
    }


}

.recodash_importbooksdata_impoforsuptext {
    font-size: 10px;
    color: #9c9c9c;
    text-align: right;
}

span.recodash_importbooksdata_wesupportgovttemptext {
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
    color: #1b1b1b;
}

span.btn.btn-default.recodash_importbooksdata_gsrt1pupupdonebtn {
    background-color: #31719b;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 3px 15px 5px 15px;
    color: #fff;
    border: 0px;
}

.recodash_importbooksdata_gstr1donttem {
    font-size: 10px;
    color: #9e9e9e;
    float: left;
}

.recodash_importbooksdata_stpopup {
    background-color: #222222db;
}


.recodash_importbooksdata_tallycong_popup {
    background-color: #1818189e;

    & .card.recodash_tally_cardbox {
        background-color: aliceblue;
        padding: 5px 10px 5px 10px;

        & .card.tally_cardbox span.tally_config_textfs {
            font-size: 14px;
            font-weight: 700;
        }

        & .btn.btn-default.tally_sync_btnbgclr {
            background-color: #31719b;
            border-color: #31719b;
            padding: 2px 15px 4px 15px;
            border-radius: 40px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
        }

        & span.tallyauto_sync_textfs.tallyauto_sync_border {
            background-color: #31719b;
            border-color: #31719b;
            padding: 4px 10px 6px 10px;
            border-radius: 40px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            top: 0px;
            position: relative;
            cursor: pointer;

            /*============ end switch btn toggle css ===========*/
        }
    }

    .card.recodash_tallyconfg_card {
        background-color: #efefef;
        padding: 7px 10px;

        & p.tally_config_Ginfofs {
            font-size: 14px;
            font-weight: 500;
            font-style: italic;
        }

        & .tally_config_textfs {
            font-size: 12px;
            font-weight: 500;
        }

        & ul.importbooksdt_tally_listbox li {
            line-height: 22px;
            list-style: none;
            font-size: 12px;

            & span.tally_listbox_checkicon_clrblue {
                color: #31719b;
                font-size: 12px;
                font-weight: 500;
            }
        }

        button.btn.btn-default.Unmapbtnbgclr {
            background-color: #31719b;
            border-color: #31719b;
            padding: 2px 15px 4px 15px;
            border-radius: 40px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
        }
    }

}

button.btn.btn-default.resendmail_btnbgclr {
    background-color: #31719b;
    border-color: #31719b;
    padding: 2px 15px 4px 15px;
    border-radius: 40px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

button.btn.btn-default.recodash_import_browse_btn {
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    border-radius: 40px;
    padding: 3px 20px 5px 20px;
    background-color: #eeeeee;
    color: #000;
}

button.btn.btn-default.recodash_filename_btn {
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #ddd;
    width: 100%;
    text-align: left;
}

span.btn.btn-default.recodash_filenameremove_btn {
    font-size: 12px;
    font-weight: 500;
    background-color: red;
    color: #fff;
    border: 0px;
    border-radius: 40px;
    padding: 2px 10px 4px 10px;
}

p.importformat_textfs {
    font-size: 12px;
    font-weight: 500;
    padding: 6px 0px 0px 5px;
    margin-bottom: 2px;
}

p.recodash_notetextfs {
    font-size: 12px;
    font-weight: 500;
    padding: 0px 0px 0px 5px;
    color: #939393;
    font-style: italic;
}


.form-check.recodash_impbooksdata_checkbox label.form-check-label {
    font-size: 12px;
    font-weight: 500;
}

button.btn.btn-default.recodash_gstrdropfile_btn {
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    border: 2px dashed #eee;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    width: 100%;
    border-radius: 40px;
}