body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-group label {
    font-size: 12px;
    color: #777171;
}
.form-group{
    font-size: 12px!important;
    color: #777171;
}
.profile_name {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    background-color: #F2F2F2;
    font-size: 40px;
    padding: 17px 14px;
    margin: auto;
}

.profile_profile_photo,
.profile_company_photo {
    text-align: center;
    width: 100%;
}

.profile_edit img,
.profile_logo_edit img {
    width: 20px;
    cursor: pointer;

}
h4.profiletex_fs {
    font-size: 1.2rem;
    top: 5px;
    position: relative;
}
.profile_edit {
    background-color: #FC7E72;
    padding: 7px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: auto;
    top: 35%;
    position: absolute;
    right: 30%;
}

.profile_logo_edit {
    background-color: #FC7E72;
    padding: 7px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: auto;
    bottom: 3%;
    position: absolute;
    right: 30%;
}
.card.padding_top_manage.profilecardpadd input {
    font-size: 12px;
    font-weight: 500;
}
.bdr_left {
    border-left: 1px solid #dddddd;
}

.create_new_return {
    width: 20px;
}

.card.padding_top_manage.profilecardpadd .grey_bg {
    background-color: #fafafa;
    padding: 10px 15px;
    width: 100%;
}

small.uploadpfsize {
    font-size: 12px;
}

button.profilsavebtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    border: 0px;
    padding: 5px 22px 7px;
    font-size: 12px;
    font-weight: 500;
    
}

.input-group-prepend.inpgpbx.profileuseraddmob {
    margin-right: -1px;
    padding: 6px 4px 6px 4px;
    border: 1px solid #ced4da;
    border-radius: .25rem 0px 0px .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group-prepend.eyeipbox.profiladduserpass {
    margin-right: -1px;
    padding: 10px 4px 2px 3px;
    border: 1px solid #ced4da;
    border-radius: 0px .25rem .25rem 0px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #31719b;
}

.card.padding_top_manage.profilecardpadd {
    background-color: #fff;
    border-radius: 3px;
    width: 100%;
    float: left;
    padding: 0px 15px 0px 15px;
}

.flag_icon {
    object-fit: scale-down;
}

.forminput_verified {
    float: right;
    font-size: 12px;
    cursor: pointer;
}

.forminput_verified img {
    width: 16px;
    vertical-align: middle;
    top: 2px;
    position: relative;
    right: 2px;
}

.forminput_verified .notverified{
    color: #31719b;
    text-decoration: underline;
}

.forminput_verified .verified{
    color: rgb(33, 167, 33);
    vertical-align: middle;
    top: 2px;
    position: relative;
}


.modal.gst_verifyyremailid_modal.show .modal-dialog {
    margin: 9rem auto auto auto;
    max-width: 370px!important;
}

.modal-header.gst_verifyyremailid_header {
    padding: 3px 15px;
    background-color: #eee;
}

.modal-header.gst_verifyyremailid_header button.close {
    position: relative;
    top: 0px;
}

.modal-header.gst_verifyyremailid_header button.close:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.gst_verifyyremailid_title {
    font-size: 1rem !important;
    padding: 3px 0px 0px 0px !important;
    font-weight: 500 !important;
}

p.gst_verifyyremailid_textfs {
    font-size: 14px;
    font-weight: 500;
}

input.form-control.gst_verifyyremailid_inptfs {
    font-size: 12px;
    font-weight: 500;
}

input.form-control.gst_verifyyremailid_inptfs:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

button.gst_verifyyremailid_emailidbgclr {
    background-color: #fc7d72;
    color: #fff;
    font-size: 12px;
    border: 0px;
    padding: 4px 15px 6px 15px;
    border-radius: 40px;
    font-weight: 500;
}

h4.gst_verifyyremailid_emialidfs {
    font-size: 1rem;
    font-weight: 500;
}

button.gst_verifyyremailid_btnresent_bgclr {
    border: 0px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    color: #9e9b9b;
}




.emaileditIcon {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 0.3rem;
    cursor: pointer;
    height: 100%;
    align-items: center;
    display: flex;
}

.mobileeditIcon {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 0.3rem;
    cursor: pointer;
    height: 100%;
    align-items: center;
    display: flex;
}


.modal.gst_editmobileno_modal.show .modal-dialog {
    margin: 4rem auto auto auto;
    max-width: 429px!important;
}
.modal-header.gst_editmobileno_header {
    padding: 3px 15px;
    background-color: #eee;
}

.modal-header.gst_editmobileno_header button.close {
    position: relative;
    top: 0px;
}
.modal-header.gst_editmobileno_header button.close:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
.gst_editmobileno_title {
    font-size: 1rem !important;
    padding: 3px 0px 0px 0px !important;
    font-weight: 500 !important;
}
p.gst_editmobileno_textfs {
    font-size: 12px;
    font-weight: 500;
}
label.gst_editmobileno_textfs {
    font-size: 12px;
    font-weight: 500;
}
input.form-control.gst_editmobileno_inptfs {
    font-size: 12px;
    font-weight: 500;
    width: 22rem;
}
input.form-control.gst_editmobileno_inptfs:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
button.gst_editmobileno_btndone_bgclr {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    border: 0px;
    padding: 4px 20px 6px 20px;
    border-radius: 40px;
    font-weight: 500;
}

.modal.unverify_email_address_modal.show .modal-dialog {
    margin: 4rem auto auto auto;
    max-width: 370px;
}
.modal-header.unverify_email_address_header {
    padding: 0px 15px;
    background-color: #eee;
}
.modal-header.unverify_email_address_header .unverify_email_address_title.modal-title.h4 {
    padding: 4px 0px;
    margin: 0px;
    font-size: 1rem;
    font-weight: 500;
}


.modal-header.unverify_email_address_header button.close {
    position: relative;
    top: 0px;
}
.modal-header.unverify_email_address_header button.close:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
.unverify_email_address_title {
    font-size: 1rem;
    padding: 3px 0px 0px 0px;
    font-weight: 500;
}
p.unverify_email_address_textfs {
    font-size: 12px;
    font-weight: 500;
}

button.unverify_email_address_emailidbgclr {
    background-color: #31719b;
    color: #fff;
    font-size: 12px;
    border: 0px;
    padding: 4px 15px 6px 15px;
    border-radius: 40px;
    font-weight: 500;
}
.emailopacity{
    opacity: 0.65;
}
.formerr_validation{
    font-size: 12px;
    color: red;
    text-align: right;
    flex: auto;
    padding-right: 1rem;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


label.txt-blue{
    background-color: #2166B1;
    border-color: #2166B1;  
    color: #fff!important;
    border: 0px;
    padding: 3px 5px;
    font-size: 12px;
    font-weight: 700!important;
    cursor: pointer!important;
}

svg.copy-icon{
    font-size: 20px; 
    padding-top:2px; 
    cursor:pointer;
    color:#2166B1;
    position: relative;
    top: 3px;
}
  
