   body{ 
    background-color: #fff!important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500!important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.bs-slider{
    overflow: hidden;
    max-height: 700px;
    position: relative;
    background: #fff !important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}
.bs-slider > .carousel-inner > .item > img, .bs-slider > .carousel-inner > .item > a > img {
    margin: 0px 0px !important;
    width: 40%;
	max-width: 40% !important ;
}
.bs-slider:hover {
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.bs-slider:active {
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.bs-slider .bs-slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   /* background-color: rgba(0, 0, 0, 0.40);*/
}
.bs-slider > .carousel-inner > .item > img,
.bs-slider > .carousel-inner > .item > a > img {
    margin: auto;
    width: 100% !important;
}

/********************
*****Slide effect
**********************/
/*.item {
    top: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    position: absolute;
    left: 0 !important;
    display: block !important;
    -webkit-transition: opacity ease-in-out 1s;
    -moz-transition: opacity ease-in-out 1s;
    -ms-transition: opacity ease-in-out 1s;
    -o-transition: opacity ease-in-out 1s;
    transition: opacity ease-in-out 1s;
}

.item.active {
    opacity: 1;
    z-index: 2;
    -webkit-transition: opacity ease-in-out 1s;
    -moz-transition: opacity ease-in-out 1s;
    -ms-transition: opacity ease-in-out 1s;
    -o-transition: opacity ease-in-out 1s;
    transition: opacity ease-in-out 1s;
}*/

.topcont {
    position: relative;
    -webkit-animation-name: animate-bottom;
    -webkit-animation-duration: 1s;
    animation-name: animate-bottom;
    animation-duration: 1s;
  }

.animation_1 {
    position: relative;
    -webkit-animation-name: animate-bottom;
    -webkit-animation-duration: 1.5s;
    animation-name: animate-bottom;
    animation-duration: 1.5s;
  }

  .animation_2 {
    position: relative;
    -webkit-animation-name: animate-bottom;
    -webkit-animation-duration: 2s;
    animation-name: animate-bottom;
    animation-duration: 2s;
  }  

  .animation_3 {
    position: relative;
    -webkit-animation-name: animate-bottom;
    -webkit-animation-duration: 2.5s;
    animation-name: animate-bottom;
    animation-duration: 2.5s;
  }

  @-webkit-keyframes animate-bottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  
  @keyframes animate-bottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }


  .progress_bar{margin-bottom: 10px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;}

/*---------- LEFT/RIGHT ROUND CONTROL ----------*/

.slide-text a:hover, .slide-text a:active {
    color: #23527c !important;
    background: #ffffff !important;
    -webkit-transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
    transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
}

/*---------- INDICATORS CONTROL ----------*/
.bs-slider.control-round.indicators-line.ciinersliderheight .carousel-indicators {
    position: absolute !important;
    right: 0 !important;
    top: 40% !important;
    bottom: auto !important;
    z-index: 15 !important;
    left: auto !important;
    display: block !important;
    justify-content: center !important;
    padding-left: 0 !important;
    margin-right: auto !important;
    margin-left: auto !important;
    list-style: none !important;
}
.bs-slider.control-round.indicators-line.ciinersliderheight .carousel-indicators li {
    background-color: #31719b;
    border: 0 solid #31719b;
    width: 10px;
    height: 10px;
    opacity: 1;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 0px !important;
}
.bs-slider.control-round.indicators-line.ciinersliderheight .carousel-indicators li.active {
    background-color: #fc7d72 !important;
}

/*---------- SLIDE CAPTION ----------*/
.slide_style_left {
    text-align: left !important;
}
.slide_style_right {
    text-align: right !important;
}
.slide_style_center {
    text-align: center !important;
}

.slide-text {
	left: 10px !important;
    width: 60%;
    right: 0;
    margin: auto;
    padding: 10px;
    position: absolute;
    text-align: left !important;
    padding: 10px 40px;
	top: 22% !important;
    text-align: left !important;
    margin: auto auto auto 35% !important;
    height: 650px;
    overflow-x: auto;
}

h1{
	    line-height: 42px;
	
}
.slide-text h1 {
    padding: 0;
    color: #fc7d72;
    font-size: 16px;
    font-style: normal;
    line-height: 42px;
    margin-bottom: 30px;
    letter-spacing: 1px;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
	font-weight: 600;
}
.slide-text p {
       padding: 0;
    color: #fc7d72;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 12px;
    letter-spacing: 1px;
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}
span.textblodfs {
    font-weight: 600;
}
.slide-text a.btn-default{
    color: #337ab7;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    margin-right: 10px;
    text-align: center;
    padding: 17px 30px;
    white-space: nowrap;
    letter-spacing: 1px;
    display: inline-block;
    border: none;
    text-transform: uppercase;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slide-text a.btn-primary{
    color: #ffffff;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    margin-left: 10px;
    text-align: center;
    padding: 17px 30px;
    white-space: nowrap;
    letter-spacing: 1px;
    background: #00bfff;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.slide-text a:hover,
.slide-text a:active {
    color: #ffffff;
    background: #222222;
    -webkit-transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
    transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
}


/* ================ slider coption css =======================*/
.slide-text p.secodcontsect01 {
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
}
.slide-text p.secodcontsect02 {
    -webkit-animation-delay: 2.9s;
    animation-delay: 2.9s;
}
.slide-text p.secodcontsect03 {
    -webkit-animation-delay: 3.1s;
    animation-delay: 3.1s;
}
.slide-text a.btn-default{
	    -webkit-animation-delay: 3.9s;
    animation-delay: 3.9s;
	
}
span.bussuserbox {
    box-sizing: border-box;
    border: 1px solid #eee;
    padding: 3px 5px 3px 5px;
    border-radius: 3px;
    box-shadow: 0px 1px 5px #e8e6ff;
    color: #31719b;
}
h1.leftpadd {
    z-index: 3;
    position: relative;
    right: 0px;
    left: -35px;
    margin-bottom: 0px;
	font-weight: 600;
}

h1.leftpadd.titleblueclr {
    color: #31719b;
}
span.textblodfs.textblue {
   color: #31719b;
}
p.textblue{
	   color: #31719b;
}

.wanttoexbtn {
    -webkit-animation-delay: 3.9s;
    animation-delay: 3.9s;
    color: #337ab7 !important;
    padding: 0px;
    cursor: pointer;
	text-decoration: underline;
}
.wanttoexbtn:hover {
   text-decoration: none;
}
h1.tablefilingscreen {
    margin:-15px 0px !important;
}

h1.titleblueclr {
	 color: #fc7d72;
}
thead.topbornonetb th {
    border-top: 0px;
    font-size: 13.5px;
    font-weight: 500;
    text-align: center;
    padding-top: 0px;
    color: #fc7d72;
}
table.table.table-striped.finlingsttb thead tr th {
    border-top: 0px;
    font-size: 13.5px;
    font-weight: 500;
    text-align: center;
    padding-top: 0px;
    color: #fc7d72;
}
table.table.table-striped.finlingsttb tr td {
    font-size: 12px;
    font-weight: 500;
    padding: .25rem .75rem;
    color: #fc7d72;
    background-color: #fff;
}

table.table.table-striped.finlingsttb tr th {
   font-size: 13.5px;
    color: #fc7d72;
    background-color: #fff;
}

table.table.table-striped.finlingsttb tr.topbor0 th {
    border-top: 0px;
    background-color: #fff;
	    font-size: 13.5px;
}
.tbourbor {
    padding: 8px 5px;
    border-radius: 17px;
    border: 1px solid #eee;
    box-shadow: 0px 15px 21px 9px #eee;
    margin: 25px 0px;
}
.ciinersliderheight .carousel-inner {
    position: relative;
    width: 100%;
       overflow: hidden;
    height: 90vh;
}


.gstindashpoup.modal .modal-dialog {
    overflow:hidden;
    width: 980px;
    max-width: 980px;
    margin: 5px auto 0px auto;
}
span.circleiconclr {
    color: #ec4848;
    font-size: 14px;
}
h1.topcont {
    font-size: 18px;
    width: 100%;
    padding: 2px 21px;
    margin: 22px 0px 22px 0px;
    box-sizing: border-box;
    line-height: 28px;
    font-weight: 600;
	text-align: center;
    color: #31719b;
}

tr.trtdcontentblue td {
    color: #31719b !important;
}

.tbrightsidebor {
    border-right: 1px solid #ddd;
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
user agent stylesheet
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
ol.authenlist {
    padding-left: 22px;
    color: #fc7d72;
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 12px;
    letter-spacing: 1px;
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}
button.GOtodashbtn.btn.btn-default {
    border: 1px solid #33729c;
    outline-color: #33729c;
    border-radius: 40px;
    color: #fff;
    background-color: #33729c;
    padding: 5px 13px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
/*animation popup css===============*/
.gstindashpoup.modal .modal-content {
 
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 1.7s;
}
/* Add Animation */
@-webkit-keyframes slideIn {
  from {bottom: -300px; opacity: 0} 
  to {bottom: 0; opacity: 1}
}

@keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}
  
/*------------------------------------------------------*/

.slide-text.slide_style_right.addbank_toppadd {
    top: 12rem !important;
}
span.bussuserbox.addbankiconbox {
    right: 4px;
    padding: 0px 0px 6px 5px !important;
    top: -3px;
    position: relative;
}
/* RESPONSIVE
/*------------------------------------------------------*/

/*=========== start media code ==================*/

@media  (max-width: 767px) {
	div#bootstrap-touch-slider {
        height: 100vh;
        overflow-x: auto;
    }
	.bs-slider {
        overflow-x: auto;
        max-height: 740px;
        position: relative;
        background: #fff !important;
	}
	.ciinersliderheight .carousel-inner {
		min-height: 900px;
			overflow-x: auto;
			height: 995px;
	}
	.bs-slider > .carousel-inner > .item > img, .bs-slider > .carousel-inner > .item > a > img {
		margin: 0px 0px !important;
		width: 100% !important;
		background-color: #fff !important;
		max-width: 100% !important;
	}
	.slide-text {
        left: -6rem !important;
        width: 100%;
        top: 84% !important;
        height: 50rem;
        overflow-x: auto;
        right: 0px;
        padding: 10px 30px;
	}
   
	a.btn.btn-default.wanttoexbtn {
		width: 100%;
		white-space: normal;
		line-height: 16px;
		padding: 3px 3px;
	}
	.slide-text.slide_style_right.athutextsection {
		left: -7rem !important;
		width: 90%;
		top: 17rem !important;
		right: 0px;
	}
	

    .slide-text h1 {
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 5px;
    }
    .slide-text a.btn-default, 
    .slide-text a.btn-primary {
        font-size: 10px;
        line-height: 10px;
        margin-right: 10px;
        text-align: center;
        padding: 10px 15px;
    }
	.gstindashpoup.modal .modal-dialog {
		width: auto;
		margin: 9px auto;
	}
	h1.topcont {
		font-size: 14px;
		width: 100%;
	}
	h1.tablefilingscreen {
		margin: 16px 0px !important;
		font-weight: 600px;
	}
}

@media  (min-width: 426px) and (max-width: 767px) {
	
	.ciinersliderheight .carousel-inner {
		position: relative;
		width: 100%;
		height: 80vh;
	}
	.bs-slider > .carousel-inner > .item > img, .bs-slider > .carousel-inner > .item > a > img {
		margin: 0px 0px !important;
		width: 100% !important;
		background-color: #fff !important;
		max-width: 100% !important;
	}
	.slide-text {
		left: -12rem !important;
		width: 88%;
		top: 20rem !important;
		right: 0px;
	}
	a.btn.btn-default.wanttoexbtn {
		width: 100%;
		white-space: normal;
		line-height: 16px;
		padding: 3px 3px;
	}
	.slide-text.slide_style_right.athutextsection {
		left: -7rem !important;
		width: 90%;
		top: 21rem !important;
		right: 0px;
	}

	
}

@media  (min-width: 768px) and (max-width: 941px) {
    div#bootstrap-touch-slider {
        height: 100vh;
        overflow-x: auto;
    }
	.ciinersliderheight .carousel-inner {
		position: relative;
		width: 100%;
		height: 80vh;
	}
	a.btn.btn-default.wanttoexbtn {
		width: 100%;
		white-space: normal;
		line-height: 16px;
		padding: 3px 3px;
	}
	.slide-text.slide_style_right.athutextsection {
		left: -1rem !important;
		width: 73%;
		top: 0rem !important;
		right: 0px;
	}
	.gstindashpoup.modal .modal-dialog {
		width: auto;
		margin: 55px auto;
	}
    .slide-text {
        top: 32% !important;
        height: 80%;
        overflow-x: auto;
    }
	
	
}
@media  (min-width: 942px) and (max-width: 1024px) {
	.ciinersliderheight .carousel-inner {
		position: relative;
		width: 100%;
		height: 80vh;
	}
	a.btn.btn-default.wanttoexbtn {
		width: 100%;
		white-space: normal;
		line-height: 16px;
		padding: 3px 3px;
	}
	.slide-text.slide_style_right.athutextsection {
		left: -1rem !important;
		width: 73%;
		top: 0rem !important;
		right: 0px;
	}
    .slide-text {
        top: 32% !important;
        height: 80%;
        overflow-x: auto;
    }
}


