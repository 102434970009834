.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.k-grid-header table {
  width: 100% !important;
}

.k-grid table {
  width: 100% !important;
}
body {
  background-color: #fff !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
body, h1, h2, h3, h4, h5 {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.FRbackbtn {
  color: #fc7d72;
  font-size: 12px;
}

.backtoclr {
  color: #fc7d72;
  font-size: 13px;
  padding: 4px 0;
  font-weight: 500;
}

.error_message {
  color: red;
  font-size: 12px;
  padding-left: 5px;
  clear: both;
  display: inline-block;
}

/*==============intro css -=================*/

.kWwJhA {
  z-index: 1038 !important;
  color: rgb(0 0 0 / 100%) !important;
}

.dvlaPW {
  z-index: 1039 !important;
}

.diKbXs {
  justify-content: left !important;
}

.sc-ifAKCX {
  padding: 14px 14px !important;
  font-size: .9rem !important;
  font-weight: 600 !important;
  color: #333 !important;
  max-width: 250px !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  box-shadow: none !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.fyrYpc {
  border: 1px solid #333 !important;
}

.sc-bZQynM {
  position: relative !important;
  margin-top: 30px !important;
}

.fHIrZs {
  position: absolute !important;
  top: -25px !important;
  left: 30% !important;
}

.eTpeTG {
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 4px 8px 5px 10px !important;
  border: 1px solid #31719b !important;
  border-radius: 40px !important;
  background-color: #fff !important;
  bottom: 0.9rem !important;
  color: #333 !important;
  top: auto !important;
  width: 70px !important;
  height: auto !important;
  right: 10px !important;
}

.eTpeTG svg {
  display: none !important;
}

.eTpeTG::before {
  content: 'End Tour' !important;
}

.reactour__dot--is-active {
  background-color: #fc7d72 !important;
}

.kcbIHb {
  color: #fff !important;
  padding: 4px 12px 5px 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-shadow: 0px 0px 0 #31719b !important;
  border-radius: 40px !important;
  border: 1px solid #31719b !important;
  background-color: #31719b !important;
  float: left !important;
  border-color: #31719b !important;
  position: relative !important;
  margin-right: 0px !important;
}

.kcbIHb::before {
  content: url(./images/danglrighttwclr8x8.png) !important;
  position: absolute !important;
  left: 8px !important;
}

.fYzjNt {
  color: #fff !important;
  padding: 4px 20px 5px 12px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-shadow: 0px 0px 0 #31719b !important;
  border-radius: 40px !important;
  border: 1px solid #31719b !important;
  background-color: #31719b !important;
  float: left !important;
  border-color: #31719b !important;
  position: relative !important;
  margin-left: 10px !important;
}

.fYzjNt::after {
  content: url(./images/danglleftwclr8x8.png) !important;
  position: absolute !important;
  right: 8px !important;
}

.sc-ifAKCX {
  transition: transform 0.8s !important;
  z-index: 1039 !important;
}

.introjs-skipbutton {
  font-size: 12px !important;
  font-weight: 100 !important;
  padding: 8px 10px !important;
  background-color: #fdf4e7 !important;
  bottom: 0.6rem !important;
  color: #31719b !important;
  position: relative !important;
  border-radius: 1px !important;
}

.introjs-arrow.top {
  top: -10px;
  left: 6px !important;
  border-bottom-color: #ffffff;
  display: none !important;
}

.introjs-tooltipbuttons .introjs-button:focus {
  box-shadow: 0 0 0 0.1rem rgb(158 158 158 / 39%) !important;
  background-color: #31719b !important;
}

.fyrYpc:hover:before {
  display: none;
}

button.sc-bdVaJa.lfvGpo.sc-htpNat.gnFBec {
  display: none;
}

button.sc-bdVaJa.lfvGpo.sc-htpNat.dobmSV {
  display: none;
}

.introjs-overlay {
  z-index: 1000 !important;
}

.introjs-helperLayer {
  z-index: 1001 !important;
}

.introjs-tooltipReferenceLayer {
  z-index: 1003 !important;
}

.introjs-arrow.right {
  display: none !important;
}

.introjs-arrow.left {
  display: none !important;
}

/*  ---- End Intro css ------ */

span.b2btbtrthtextclr {
  text-align: right;
  color: rgb(0, 0, 255);
  font-size: 9px;
}

.disabled {
  opacity: 0.5;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
  font-size: 12px;
}

.redtextclr {
  color: #ff116c;
}

.greentextclr {
  color: green !important;
}

.greentextclr span {
  color: green !important;
}

.k-multiselect ul li {
  font-size: 12px;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.green {
  color: #578224;
}

.red {
  color: #f6594f;
}

.pink {
  color: #ffcdd2;
}

.yellow {
  color: #c4e1a4;
}

.white {
  color: #fff;
}

.bold {
  font-weight: bold;
}

.bg_grren {
  background-color: #8bc34a;
}

.bg_pink {
  background-color: #ffcdd2;
}

.bg_yellow {
  background-color: #c4e1a4;
}

.bg_red {
  background-color: #e57373;
}

.k-animation-container {
  z-index: 999999999 !important;
}

.k-widget.k-window.gstr9reportspopup .k-window-titlebar.k-dialog-titlebar {
  padding: 8px 11px;
  background-color: #31719b;
}

.k-window-title {
  font-size: 16px !important;
  line-height: 1.4 !important;
}

.k-window-titlebar,.k-window-title{
  cursor: all-scroll !important;
}

.plan_feature_hover_box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 1px;
  right: 6px;
  bottom: 0;
  background-color: rgb(255 255 255 / 95%);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;}

