body{   
    background-color: #ffffff!important;
     font-size: 1rem;
     font-weight: normal ;
     line-height: 1.5;
     color: #212529;
     text-align: left;
     -webkit-font-smoothing: antialiased;
     font-weight: 500 !important;
     font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
 }


 span.ewygsp_needhelp_textfs {
    font-size: 12px;
    font-weight: 500;
    color: #105999;
    /* text-decoration: underline; */
    cursor: pointer;
}

.fade.ewayGSP_knowledgevideo_modal.modal.show {
    background-color: #222222c9;

    & .modal-dialog {
        max-width: 700px;
        margin: 2rem auto auto;

            & .ewayGSP_knowledgevideo_header.modal-header {
                background-color: #31719b;
                padding: 0 15px;
                
                & .ewayGSP_knowledgevideo_titlefs.modal-title.h4 {
                    font-size: 1rem;
                    font-weight: 500;
                    padding: 4px 0;
                    color: #fff;
                }

                & button.close {
                    position: relative;
                    top: 2px;
                    color: #fff;
                }
            }
    }
    .card.ewayGSP_knowledgevideo_cardbox {
        box-shadow: 0px 2px 9px #8c8b8b;
        padding: 5px 5px;
    }
    .ewayGSP_knowledgevideo_iconcardbox {
        padding: 15px 10px 7px 10px;
        bottom: 0px;
        position: relative;
        top: 0px;
        box-shadow: 0px 2px 9px #8c8b8b;
        border-radius: 5px;
       
        

        a.ewayGSP_knowledgevideo_iconboxlink {
            text-decoration: none;

            img.ewayGSP_knowledgevideo_imagwidth {
                width: 250px;
                text-align: center;
                max-width: 100%;
                position: relative;
                padding: 18px 0px 29px 0px;
            }

            p.ewayGSP_knowledgevideo_textfs {
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                position: relative;
                margin-bottom: 0px;
            }
        }
    }

}