.k-widget.k-window.gstr9reportspopup .k-window-content, .k-prompt-container {
  padding: 5px 5px;
  overflow-x: hidden;
}
.modal.gst9repbclpopup.show .modal-dialog {
  max-width: 1270px;
 margin: 10px auto 0px auto;
}

.modal.gstr9reportpoup4Asuppmade.show .modal-dialog {
  margin: 10px auto;
}
.k-widget.k-window.gstr9reportspopup {
  border-color: rgb(0 0 0 / 22%);
  color: #656565;
  background-color: #ffffff;
  box-shadow: 0px 4px 15px 6px rgb(0 0 0 / 18%);
}
.k-widget.k-window.gstr9reportspopup .k-window-titlebar.k-dialog-titlebar {
  padding: 7px 11px;
  background-color: #31719b;
}
.k-widget.k-window.gstr9reportspopup .k-window-title {
  font-size: 14px;
  color: #ffffff;
}
.k-widget.k-window.gstr9reportspopup button.k-button-icon.k-button {
  color: #fff !important;
}
.gstr9reportpopuph.modal-header button.close {
  padding-top: 15px;
}
.gstr9reportpopuph.modal-header button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.k-widget.k-window.gstr9reportspopup .k-icon-button, .k-button.k-button-icon {
  width: calc(1.25em + 10px);
  height: calc(1em + 10px);
  padding: 4px;
}
table.table.table-striped.gst9_reportpopuptb thead tr th {
  font-size: 12px;
  text-align: center;
  border-top: 0px;
  font-weight: 500;
    padding-top: 1px;
  padding-bottom: 1px;
}
table.table.table-striped.gst9_reportpopuptb tr td {
  font-size: 11px !important;
  font-weight: 500;
  padding: .25rem .75rem;
}

td.eyeicononponter {
  cursor: pointer;
}
.textclrlightred {
  color: #f54337;
}
tr.totalasperboldtext td {
  font-weight: 700 !important;
}
.gstrrainnertbselmothwisebox {
  background-color: #eee;
  padding: 5px 12px;
}
span.innertitletextfs {
  font-size: 14px;
  position: relative;
  left: 4rem;
}
span.monthwiaebosfs {
  font-size: 12px;
  color: #979797a1;
  padding-top: 4px;
}
span.redboxalign {
  vertical-align: text-top;
  color: #424548;
}
.bgclrgray {
  background-color: #f1f0f3;
}
tr.secdrowpadd th {
  padding: 0px 0px 0px 0px;
}
tr.toprowborbottomnon th {
  border-bottom: 0px !important;
  padding: 6px 0px 0px 0px;
}
tr.g4atextblod td {
  font-weight: 700 !important;
}
.g4atextblod {
  font-weight: 700 !important;
}
.grepmarginbothside {
  margin: auto 4rem;
}
table.table.table-striped.gst9reportfourBtb thead tr th {
  font-size: 12px;
  text-align: center;
  border-top: 0px;
  font-weight: 500;
  padding-top: 5px;
}
table.table.table-striped.gst9reportfourBtb tr td {
  font-size: 11px !important;
  font-weight: 500;
  padding: .25rem .75rem;
}
td.gstreportfourboverflowtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 150px;
}
.type_input label {cursor: pointer;}
.form-check-inline .form-check-input {
  cursor: pointer;
}

td.gstgraybgclr {
  background-color: #dee2e6;
  border-bottom: 1px solid #f2f2f2;
}
th.paddaddleftmonthcell {
  padding-left: 0.75rem !important;
}
.disable, .disable input {pointer-events: none;
  opacity: 0.4;}

  tr.totalrowgstr5d td {
    font-weight: 700 !important;
}
/* start media code */
@media  (max-width: 767px){
  .k-widget.k-window.gstr9reportspopup .k-window-content {
      padding: 5px 5px;
      overflow-x: scroll;
  }
  .k-widget.k-window.gstr9reportspopup {
    width: 94% !important;
  }
}
@media  (min-width: 768px) and (max-width: 940px){
  .k-widget.k-window.gstr9reportspopup .k-window-content {
      padding: 5px 5px;
      overflow-x: scroll;
  }
  .k-widget.k-window.gstr9reportspopup {
    width: 96% !important;
  }
}
@media  (min-width: 941px) and (max-width: 1024px){
  .k-widget.k-window.gstr9reportspopup .k-window-content {
      padding: 5px 5px;
      overflow-x: scroll;
  }
  .k-widget.k-window.gstr9reportspopup {
    width: 97% !important;
  }
}

table.table.table-striped.gst9_6detailsofitc_tb thead tr th {
  font-size: 12px;
  text-align: center;
  border-top: 0px;
  font-weight: 500;
}
table.table.table-striped.gst9_6detailsofitc_tb tr td {
 font-size: 11px !important;
  font-weight: 500;
  padding: .25rem .75rem;
}
tr.gst9tbfirstrow th {
  padding: 0px 0px;
  border-bottom: 0px !important;
}
tr.secondrowamt th {
  padding: 0px 0px;
  border-top: 0px !important;
}

/*--css Tax gst9_6detailsofitc_fieldset table --*/
fieldset.gst9_6detailsofitc_fieldset {
  border: 1px solid #31719b;
}
legend.gst9_6detailsofitc_legend {
  width: 100%;
}
.gst9_6detailsofitc_innerdiv {
  display: block;
}
.gst9_6detailsofitc_leftside {
  float: left;
  padding: 0 5px;
}
.gst9_6detailsofitc_leftside h4 {
      font-size: 1.2rem;
  padding-top: 2px;

}
.gst9_6detailsofitc_innerheightdiv {
  height: 1px;
  width: 100%;
  position: relative;
}
.gst9_6detailsofitc_rightsidediv {
  float: right;
  padding: 0 5px;
}
h4.gst9_6detailsofitc_rightrestorefs {
  background-color: #31719b;
  color: #fff;
  border: none;
  padding: 4px 18px 5px 18px;
  border-radius: 40px;
  font-size: 12px;
  top: 2px;
  position: relative;
}
.gst9_6detailsofitc_topmidbor {
  border-top: 1px solid #31719b;
  display: flex;
  position: relative;
  margin-top: 14px;
}

span.gstrcheckiconclr {
  color: #689e39;
  background-color: #fff;
  border-radius: 50px;
  font-size: 11px;
  padding: 0px 2px;
}


/*========= collapse tbale tr css =========*/
