.supplierbox {
    display: flex;
    float: right;
}

.suppisereco h4 {
    font-size: 1.2rem;
}

.supplierbox h4 {
    font-size: 1.2rem;
    padding: 0.4rem 7rem 0rem 0rem;
}

span.selectyrbox select.form-control.selyr {
    border: 0rem;
    padding: 0.5rem 0.5rem;
    color: #8bc34a;
}

span.selectyrbox {
    padding-right: 0.5rem;
}

.k-widget.k-grid .k-grid-header {
    padding: 0px 0px 0px 0px !important;
}

span.suppbariconpadd i {
    padding: 0.7rem 0rem;
    color: #e65100;
}

span.recotopbtn button.btn.btn-default.invoicewiserecobtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 16px 6px 16px;
    font-size: 12px;
    float: right;
}

.card.suppborbox {
    border: 1px solid #eee;
    padding: 6px 10px;
    margin-bottom: 1rem;
}


table.table.table-striped.supplierraisereqtb thead tr th span.filteriocnsize.pt-1 img {
    width: 13px;
}

table.table.table-striped.supplierraisereqtb thead tr th {
    border-top: 0px;
    font-size: 12px !important;
    text-align: center;
    padding: 6px 0px;
    font-weight: 500;
}

table.table.table-striped.supplierraisereqtb tr td {
    font-size: 12px;
    padding: .25rem .75rem;
}

.k-widget.k-grid.table-striped.supplierraisereqtb.invoicetb tr td {
    font-size: 11px !important;
}

td.eyeiconclr {
    color: #31719b !important;
}


th.mappingiconlink img {
    width: 16px;
}
h4.invoicetext_leftpadd {
    margin-left: 5rem !important;
}
/*supplier wise reco*/
.supplistofcomp h4 {
    text-align: center;
    margin-left: -5rem;
    font-size: 1.2rem;
}

/*leftside info box box=====*/

.leftsidesummarypopup {
    position: fixed;
    padding: 0.0rem 0.0rem 0.0rem 0.0rem;
    background-color: #31719b;
    color: #fff;
    z-index: 20;
    top: 50%;
    transform: rotate(270deg);
    border-radius: 0 0 5px 5px;
    left: -5.5rem;
}

span.uploaddataicon {
    display: block;
    transform: rotate(88deg);
    transform-box: fill-box;
    margin-top: 1.4rem;
    top: 0;
    left: 6rem;
    display: none;
    position: absolute;
}

.leftsidesummarypopup a.mb-0.btn {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}

/*
.leftsidesummarypopup:hover {
    transition: all .5s ease .5s;
    -webkit-animation: all .5s ease;
    animation: all .5s ease;
    margin-left: 33px;
}*/

a.backtoclrlistofcomp {
    font-size: 12px;
    padding: 4px 0;
    color: #fc7d72;
    text-decoration: none;
    font-weight: 500;
}

a.backtoclrlistofcomp:hover {
    color: #31719b;
    text-decoration: none;
}

.selctboxcotg {
    padding: 0.3rem 0px;
}

button.btn.btn-primary.annualbtnbox {
    border-radius: 0;
    padding: .2rem 1.9rem;
    color: #f0f8ff;
    background-color: #fc7d72 !important;
    border: 0;
    outline-color: #fc7d72 !important;
    border-right: 0px !important;
    font-size: 14px;
    FONT-WEIGHT: 500;
    margin-left: 3.5rem;
    border: 1px solid rgb(206, 212, 218);
}

button.btn.btn-primary.annualbtnbox.invoicepage {
    margin-left: 0rem;
    width: 35%;
}

button.btn.btn-primary.annualbtnbox_disabled.invoicepage {
    margin-left: 0rem;
    width: 35%;
    background-color: #fff;
    border-right: 0px solid rgb(206, 212, 218);
    color: rgb(170, 170, 170);
    border-top: 1px solid rgb(206, 212, 218);
    border-bottom: 1px solid rgb(206, 212, 218);
    border-left: 1px solid rgb(206, 212, 218);
}

.dropdown-heading-value {
    color: #fc7d72 !important;
}

.selctboxcotg.b2bselbox .multi-select {
    width: 35%;
    margin-right: -1px;
}

button.btn.btn-primary.annualbtnbox:hover {
    background-color: #31719b;

}

select.form-control.selectboxquaterly {
    border-radius: 0px;
    border-color: #31719b;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    FONT-WEIGHT: 500;
}

select.form-control.selectboxmonthly {
    border-radius: 0px;
    border-color: #31719b;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    FONT-WEIGHT: 500;
}

span.k-icon.k-i-more-vertical {
    left: 5px;
    /* font-size: 13px; */
}

/* update books 2a data css mobel box ================*/
div#updatebooks2adatam .modal-dialog {
    margin: 62px 0px;
    width: 350px;
    overflow: hidden;
}

.modal-header.updatebooks2adatah {
    padding: 0.5rem 1rem;
    background-color: #eee;
}

.modal-header.updatebooks2adatah h4.modal-title {
    font-size: 1rem;
}

.refheadinnerbox {
    text-align: left;
}

.refheadinnerbox .ref2adata {
    font-size: 12px;
    padding: 0rem 1rem;
    text-decoration: underline;
}

.refheadinnerbox .downloadbookstem {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.refheadinnerbox a.ref2adata:hover {
    text-decoration: none;
}

.refheadinnerbox a.downloadbookstem:hover {
    text-decoration: none;
}

.card.updatebooks2a {
    padding: 0.2rem 0.5rem;
}

table.table.table-striped.updatebooks2adataleftsidepopup thead tr th {
    border-top: 0px;
    font-size: 12px !important;
    text-align: center;
    padding: 0px 0px;
    font-weight: 500;
}


table.table.table-striped.updatebooks2adataleftsidepopup tr td {
    font-size: 11px;
    padding: .25rem .75rem;
}


/* B2b check box css*/

.selctboxcotg.b2bselbox {
    font-size: 13px;
}

.b2bcontent h4 {
    font-size: 1.2rem;
    padding-top: 3px;
}

.card.cardborinnerbox {
    border-radius: 0px;
}

table.table.table-striped.supplierraisereqtb.gstr2atb thead tr th {
    border-top: 0px;
    font-size: 11.5px !important;
    text-align: center;
    padding: 6px 0px;
    font-weight: 600;
}

table.table.table-striped.supplierraisereqtb.gstr2atb tr td {
    font-size: 11px;
}

/*overflow tooltip---------*/
div#viewdetailspopup.modal-dialog {
    float: right;
    margin: 0.5rem 0px;
    width: 400px;
    overflow: hidden;
}

td.overflowtooltipcont {
    max-width: 131px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: inline-block;
}

.showmorebox a {
    font-size: 13px;
    font-weight: 600;
}

.footinfobox span.lefttext {
    float: left;
    margin-top: 0.5rem;
    font-size: 14px;
}

.footinfobox span.righttext {
    float: right;
}

.footinfobox span.righttext a.btn.btn-default.importnowbtn {
    font-size: 14px;
    color: #31719b;
}

.footinfobox span.righttext a.btn.btn-default.dismissbtn {
    font-size: 14px;
    color: #31719b;
}

.inline_display_contents {
    display: contents;
}

.inline_green_color {
    color: rgb(0, 200, 0);
}

.inline_red_color {
    color: rgb(255, 59, 59);
}

.inline_darkgreen_color {
    color: rgb(87, 138, 36);
}

.inline_darkred_color {
    color: rgb(153, 0, 0);
}

.inline_h4_class {
    font-size: 1rem;
    text-align: center;
    padding: 5px 0px 5px 0px;
    background-color: #eee;
    margin-bottom: 1px;
}

.inline_text_center {
    text-align: center
}

.inline_text_right {
    text-align: right
}

.inline_font8_right {
    font-size: 0.8rem;
    float: right;
    top: 5px;
    position: relative;
}

.inline_pointer_color_red {
    color: #fc7d72;
    cursor: pointer;
}

.inline_center_color_green {
    color: #b7b7b7;
    text-align: center;
}

.inline_fontSize8_color_red {
    color: #D84315;
    font-size: 8px;
}

.inline_background_white {
    background-color: #ffefd8;
}

.inline_center_color_red {
    text-align: center;
    color: #fc7d72;
}

.inline_right_color_red {
    text-align: right;
    color: #fc7d72;
}

.inline_width20 {
    width: 20px;
}

.inline_width50 {
    width: 50px;
}

.inline_width60 {
    width: 60px;
}

/*====== col padd css===========*/




/*=======view details ==========*/
.latefeeamtheadbgclr.modal-header {
    background-color: #eee;
    padding: 8px 17px;
}

.titleviewdetails {
    display: contents;
}

.viewdgstno h4 {
    font-size: 14px;
}

.viewddonebtn a.btn.btn-default.viewddbtn {
    background-color: #31719b;
    color: #fff;
    border: 1px solid #31719b;
    border-radius: 40px;
    text-align: center;
    outline-color: #31719b;
    padding: 0.3rem 1.5rem;
    font-size: 15px;
}

.invoicewisereco h4 {
    font-size: 1.2rem;
    text-align: center;
    padding-right: 14rem;
}

button.btn.btn-primary.invoicewiserecobtn {
    border-radius: 0px;
    padding: 0.2rem 1.8rem;
    color: #333;
    background-color: #fff;
    border-color: #31719b;
    outline-color: #31719b;
    font-size: 14px;
    FONT-WEIGHT: 500;
    border: 1px solid #31719b;
}

select.form-control.selectboxmonthly.monthlyactive {
    background-color: #e57373;
    border-color: #e57373;
    border: 0px;
    outline-color: #e57373;
    color: #fff;
}

.supplier_mail.sendemail img {
    width: 36px;
    margin-right: 3rem;
    vertical-align: baseline;
}

.chart_title {
    width: 100%;
}

.chart_title div {
    display: inline-block;
    padding: 0px 10px;
}

.chart {
    display: inline-block;
    width: 25%;
    text-align: center;
    padding: 7px 0px;
}

.green_color_class {
    color: #fc7d72;
}

.orange_color_class {
    color: #c18f8f;
}

.mismtchleft {
    float: right;
    color: #212529;
    font-weight: 500;
}

.mismtchleftred {
    float: right;
    color: #f6594f;
    font-weight: 500;
}

.mismtchleftonlybook {
    float: right;
    color: #33691e;
    font-weight: 500;
}

.mismtchleftonly2A {
    float: right;
    color: #c74d4d;
    font-weight: 500;
}

.mismtchleftgreen {
    float: right;
    color: #8bc34a;
    font-weight: 500;
}

.red_circle {
    color: #D84315;
    font-size: 8px;
}

.green_circle {
    color: #8bc34a;
    font-size: 8px;
}

.marginRight10 {
    margin-right: 2px;
}

span.veiwdetailstooltip {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: pointer;
    display: inline-block;
}

/* .k-sort-order{display: none !important;} */
.k-widget.k-grid.table-striped.supplierraisereqtb.invoicetb.remove_sortclass .k-grid-content.k-virtual-content {
    overflow: auto;
}

/* new changes css */
/*
.headerbgclrth {
    background-color: rgb(244, 242, 251);
}
th.k-header.headerbgclrth {
    background-color: rgb(244, 242, 251) !important;
}
.headergstr2abgclrth {
    background-color: rgb(253, 244, 231) !important;
}
*/
.k-dialog-wrapper.booksdatainnermodel.manualheaderbox .k-window-titlebar.k-dialog-titlebar.k-header {
    padding: 7px 15px;
    border-bottom: 0px;
}

.k-dialog-wrapper.booksdatainnermodel.manualheaderbox .k-window-titlebar.k-dialog-titlebar.k-header .k-window-title.k-dialog-title {
    font-size: 14px;
    font-weight: 600;
    color: #192529;
}

span.gstr2asuqcionbgclrwhite {
    vertical-align: bottom;
}

.paginatrightmanpopup .showmorebox {
    text-align: right;
}

.k-widget.k-grid.table-responsive.manualpopuptb tr td {
    font-size: 11px;
    padding: .25rem .75rem;
    border-top: 1px solid #dee2e6;
    color: #212529;
    font-weight: 500;
}

.table.table-striped.invoicefilctstb th.k-header {
    padding-top: 5px !important;
}

.k-widget.k-grid.table-responsive.manualpopuptb th.k-header {
    padding-top: 0px;
}

.k-widget.k-grid.table-responsive.manualpopuptb span.k-icon.k-i-more-vertical {
    font-size: 12px;
    vertical-align: baseline;
    top: -1px;
}

.k-widget.k-grid.manualpopuptb tr td {
    font-size: 11px;
    padding: .25rem .75rem;
    border-top: 1px solid #dee2e6;
    color: #212529;
    font-weight: 500;
}

.table.table-striped.invoicefilctstb th.k-header {
    padding-top: 5px !important;
}

.k-widget.k-grid.manualpopuptb th.k-header {
    padding-top: 0px;
}

.k-widget.k-grid.manualpopuptb span.k-icon.k-i-more-vertical {
    font-size: 12px;
    vertical-align: baseline;
    top: -1px;
}

/* end new changes css */

span.invoicereco_by2a2b_checkbox {
    font-size: 14px;
    font-weight: 500;
}

span.invoicereco_by2a2b_checkbox input {
    vertical-align: bottom;
    top: -1px;
    position: relative;
}

span.invoicereco_by2a2b_checkbox span.checklabletextfs {
    vertical-align: bottom;
}

/*======= media code ==============*/
@media (max-width: 767px) {
    .supplierbox h4 {
        font-size: 0.8rem;
        padding: 0.9rem 0rem 0rem 0rem;
    }

    span.recotopbtn {
        float: right;
    }

    .supplistofcomp h4 {
        text-align: center;
        margin-left: 0rem;
    }

    div#updatebooks2adatam .modal-dialog {

        width: auto;
    }

    div#viewdetailspopup.modal-dialog {
        float: right;
        margin: 5.5rem 0px;
        width: 100%;
    }



}

@media (min-width: 768px) and (max-width: 940px) {
    .supplierbox h4 {
        font-size: 1.2rem;
        padding: 0.4rem 2rem 0rem 0rem;
    }

    span.recotopbtn {
        float: right;
    }

    .supplistofcomp h4 {
        text-align: center;
        margin-left: 4rem;
        font-size: 1.2rem;
    }

    button.btn.btn-primary.annualbtnbox {
        padding: 0.2rem 1.2rem;

    }


}

@media (min-width: 941px) and (max-width: 1024px) {
    .supplierbox h4 {
        font-size: 1.2rem;
        padding: 0.4rem 2rem 0rem 0rem;
    }

    span.recotopbtn {
        float: right;
    }

    .supplistofcomp h4 {
        text-align: center;
        margin-left: 0rem;
        font-size: 1.2rem;
    }


}


/* ============left footer add gif img ================*/
.bottomleftsidegifimgbox {
    background-color: #c4c4c47a;
    position: fixed;
    width: 5%;
    border-radius: 0px 205px 0px 0px;
    padding: 0px 124px 0px 19px;
    margin-left: -1.1rem;
    transform: rotate(0deg);
    bottom: 0.0rem;
    cursor: pointer;
    overflow: hidden;
    transition: 2s;
}

span.bottomleftsidegifimgbox img {
    position: relative;
    top: 1rem;
    bottom: 0rem;
}

.gifimgmainbox:hover .bottomleftsidegifimgbox {
    transition: all 0.7s ease-in-out;
    transform: scale(1.7);
    bottom: 2.1rem;
    margin-left: 1.1rem;
    box-shadow: 0px 2px 10px #868686;
    overflow: hidden;
    transition: 2s;

}

.gifimgmainbox a.clickheretexclr {
    display: none;
}

.gifimgmainbox:hover a.clickheretexclr {
    display: block;
    position: relative;
    padding: 2rem 0rem 0rem 4rem;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.invoicesconflicts {
    align-items: flex-start;
    justify-content: flex-start;
    top: 6rem;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    z-index: 11;
}

/* invoices conflicts popup css */
.modal.invoicesconflicts.show .modal-dialog {
    margin: 6rem 0rem;
    max-width: 715px;
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

.modal-header.invoiceconfilctheader button.close {
    top: -2px;
    position: relative;
}

.modal-header.invoiceconfilctheader {
    border-bottom: 0px;
    padding: 7px 17px;
    background-color: rgb(244, 242, 251);
}

.invoiceconfilttitle {
    font-size: 1.2rem;
    font-weight: 500;
}

/* conflict RCm tab css */
nav.rcmconflictstab {
    padding-bottom: 7px;
}

nav.rcmconflictstab .nav.nav-tabs {
    border-bottom: 0px solid #dee2e6;
}

a.nav-item.nav-link.conflictrcmb2btitle {
    border-radius: 0px !important;
    border-color: #31719b;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    FONT-WEIGHT: 500;
    color: #333;
    padding: 0.2rem 1rem;
}

a.nav-item.nav-link.conflictitcelig {
    padding: 0.2rem 1rem;
}

a.nav-item.nav-link.conflictrcmb2btitle.conflictitcelig.active {
    border-bottom: 1px solid #31719b !important;
    border-right: 0px solid #31719b !important;
    border-left: 0px solid #31719b !important;
    border-top: 1px solid #31719b !important;
}

a.nav-item.nav-link.conflictrcmb2btitle.active {
    background-color: #fc7d72;
    outline-color: #fc7d72;
    color: rgb(255, 255, 255);
    border: 1px solid #31719b;
    border-top: 1px solid #31719b !important;
    border-bottom: 1px solid #31719b !important;
    border-right: 1px solid #31719b !important;
    border-left: 1px solid #31719b !important;
}

a.nav-item.nav-link.conflictrcmb2btitle.conflictitcelig {
    border-top: 1px solid #31719b !important;
    border-bottom: 1px solid #31719b !important;
    border-right: 1px solid #31719b !important;
    border-left: 0px solid #31719b !important;
}

/* end  conflict RCm tab css */
table.table.table-striped.invoicefilctstb thead tr th {
    border-top: 0px;
    font-size: 12px;
    text-align: center;
    padding: 6px 0px;
    font-weight: 500;
}

table.table.table-striped.invoicefilctstb tr td {
    font-size: 11px;
    padding: .25rem .75rem;
    font-weight: 500;
}

th.inconflicttrbgclr {
    background-color: #eee;
}

th.inconflicttrbgclr {
    font-size: 0.9rem !important;
    padding: 2px 0px !important;
    border-bottom: 0px !important;
}

/* invoices conflicts popup css */

/* ============end left footer add gif img ================*/
.k-widget.k-grid.table-striped.supplierraisereqtb.invoicetb.remove_sortclass.gstr2atb thead tr th span.k-icon.k-i-sort-asc-sm.active_sort {
    position: absolute;
    top: 8px;
    right: 8px;
}

.k-widget.k-grid.table-striped.supplierraisereqtb.invoicetb.remove_sortclass.gstr2atb thead tr th span.k-icon.k-i-sort-desc-sm.active_sort {
    position: absolute;
    top: 8px;
    right: 8px;
}

/*============ end css add css ==================*/

span.conflicts_2A2Btabtogglebox {
    position: absolute;
    width: 100%;
    top: 0px;
    right: -40px;
}

span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 11px;
    top: 2px;
    left: -8px;
}


span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit input {
    opacity: 0;
    width: 0;
    height: 0;
}


span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit .slider {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccbcb;
    ;
    transition: .4s;
}

span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit .slider:before {
    position: absolute;
    content: "";
    height: 7.3px;
    width: 3px;
    top: 2px;
    left: 3px;
    background-color: rgb(242 242 242);
    transition: .4s;
    outline-color: rgb(242 242 242);
}

span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit input:checked+.slider {
    background-color: #33729c;
}

/* label.switch.htoggleswit input:focus + .slider {
    box-shadow: 0 0 1px #31719b;
  } */

span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -9px;
}

/* Rounded sliders */
span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit .slider.round {
    border-radius: 34px;
}

span.conflicts_2A2Btabtogglebox label.switch.conflicts_2A2Bswit .slider.round:before {
    border-radius: 16%;
}

span.conflicts_2A2Btabtogglebox span.conflicts_2A2Btabtoggle_textfs {
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    color: #333;
}

span.invoiceratewsie_reco_nobox {
    width: 75px;
    position: relative;
    bottom: 4px;
}


/*======= media code ==============*/
@media (max-width: 767px) {
    .supplierbox h4 {
        font-size: 0.8rem;
        padding: 0.9rem 0rem 0rem 0rem;
    }

    span.recotopbtn {
        float: right;
    }

    .supplistofcomp h4 {
        text-align: center;
        margin-left: 0rem;
    }

    div#updatebooks2adatam .modal-dialog {

        width: auto;
    }

    div#viewdetailspopup.modal-dialog {
        float: right;
        margin: 5.5rem 0px;
        width: 100%;
    }

    .booksdatainnermodel.k-dialog-wrapper .k-dialog {
        max-width: 99% !important;
    }

}

@media (min-width: 768px) and (max-width: 940px) {
    .supplierbox h4 {
        font-size: 1.2rem;
        padding: 0.4rem 2rem 0rem 0rem;
    }

    span.recotopbtn {
        float: right;
    }

    .supplistofcomp h4 {
        text-align: center;
        margin-left: 4rem;
        font-size: 1.2rem;
    }

    /*bottom  gif img css*/
    .gifimgmainbox {
        margin: 0rem 0rem 0rem 0rem;
        position: relative;
    }

    /* end bottom  gif img css*/


}

@media (min-width: 941px) and (max-width: 1024px) {
    .supplierbox h4 {
        font-size: 1.2rem;
        padding: 0.4rem 2rem 0rem 0rem;
    }

    span.recotopbtn {
        float: right;
    }

    .supplistofcomp h4 {
        text-align: center;
        margin-left: 0rem;
        font-size: 1.2rem;
    }

    /*bottom  gif img css*/
    .gifimgmainbox {
        margin: 0rem 0rem 0rem 0rem;
        position: relative;
    }

    /* end bottom  gif img css*/
}

.invoicesconflicts.booksdetail .k-window-titlebar.k-dialog-titlebar.k-header,
.invoicesconflicts.booksdetail .k-window-title {
    cursor: auto !important;
}

.invoice2a_2b_coflictpopup .k-widget.k-grid .k-grid-header {
    padding: 0px 17px 0px 0px !important;
    background-color: #fff;
}
.sectiocwise_viewdetails_viewdgstno h4 span {
    font-size: 13px !important;
    font-weight: 500;
}

.invoice2a_2b_coflictpopup table thead th {
    border-bottom: 0px;
    border-top: 0px;
}

.invoice2a_2b_coflictpopup .k-icon.k-i-sort-asc-sm.active_sort,
.invoice2a_2b_coflictpopup .k-icon.k-i-sort-desc-sm.active_sort {
    position: absolute;
    right: 8px;
    top: 7px;
}


.tabe2a_2b_coflictpopup .k-icon.k-i-sort-asc-sm.active_sort,
.tabe2a_2b_coflictpopup .k-icon.k-i-sort-desc-sm.active_sort {
    position: absolute;
    right: 8px;
    top: 7px;
}

span.invoiceratewsie_reco_nobox {
    width: 75px;
    position: relative;
    bottom: 4px;
}

span.invoiceratewsie_reco_nobox .input.form-control.invoiceratewsie_reco_inputbox {
    font-size: 12px;
    font-weight: 500;
    height: calc(0.6em + 0.75rem + 2px);
    max-width: 150px;
    min-width: 50px;
    width: 50px;
}

span.invoiceratewsie_reco_textfs {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    /* top: 5px; */
    left: 5px;
    /* color: #31719b; */
    color: #909090;
}

.disabled_sorting .k-link {
    cursor: default;
}

.k-widget.k-window.multilinking-invoice-details-popup .k-window-titlebar.k-dialog-titlebar {
    padding: 7px 11px;
    background-color: #31719b;
}


.k-widget.k-window.multilinking-invoice-details-popup button.k-button-icon.k-button {
    color: #ffffff !important;
}

.cursor-default {
    .k-link {
        cursor: default;
    }
}

.invoice-wise-reco {
    .k-link {
        cursor: default;
    }

    .cell-column-title {
        cursor: pointer;
    }
}

.inline_F6594F_red_color {
    color: #F6594F;
}

.invoice2a_2b_coflictpopup .k-link {
    cursor: pointer;
}

.k-widget.k-window.gstr9reportspopup.windowwrapperfix_heading .k-window-titlebar.k-dialog-titlebar {
    padding: 8px 11px;
    background-color: #eee;
}

.k-widget.k-window.gstr9reportspopup.windowwrapperfix_heading .k-window-titlebar.k-dialog-titlebar .k-window-title {
    color: black;
}

.k-widget.k-window.gstr9reportspopup.windowwrapperfix_heading .k-window-titlebar.k-dialog-titlebar .k-window-actions .k-button:hover {
    background-color: #eee !important;
    border-color: #eee !important;
    color: black !important;
    background-image: none !important;

}

table.table.table-striped.gstr2b_viewdetails_innertb thead tr th {
    border-top: 0;
    font-size: 12px !important;
    text-align: center;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
}

table.table.table-striped.gstr2b_viewdetails_innertb tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    background-color: #f2f2f2 !important;
}

table.table.table-striped.gstr2b_viewdetails_innertb thead tr th {
    border-top: 0;
    font-size: 12px !important;
    text-align: center;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
}

table.table.table-striped.gstr2b_viewdetails_innertb tr td {
    font-size: 11px;
    padding: 0.25rem 0.75rem;
    background-color: #f2f2f2 !important;
}

table.table.table-striped.gstr2b_viewdetails_innertb tr.gtr2b_VDinnertb_thbgclr_gray th {
    background-color: #dee2e6 !important;
    font-weight: 700 !important;
}

table.table.table-striped.gstr2b_viewdetails_innertb span.gstr2b_actionreu_bgclrlightgreen {
    background-color: #fc7d72 !important;
    padding: 3px 3px;
}

table.table.table-striped.gstr2b_viewdetails_innertb .gstr2b_viewdeta_textgreenclr {
    color: #fc7d72;
}

.k-widget.k-window.multilinking-invoice-details-popup .k-window-content {
    padding-top: 7px;
}

.k-widget.k-window.gstr9reportspopup.windowwrapperfix_heading .k-window-titlebar.k-dialog-titlebar {
    padding: 8px 11px;
    background-color: #eee;
}

.k-widget.k-window.gstr9reportspopup.windowwrapperfix_heading .k-window-titlebar.k-dialog-titlebar .k-window-title {
    color: black;
}

.k-widget.k-window.gstr9reportspopup.windowwrapperfix_heading .k-window-titlebar.k-dialog-titlebar .k-window-actions .k-button:hover {
    background-color: #eee !important;
    border-color: #eee !important;
    color: black !important;
    background-image: none !important;
}
.k-overlay{
    z-index: 1001!important
}


.k-widget.k-grid.table-responsive.manualpopuptb .k-grid-content.k-virtual-content{
    max-height: 160px;
    overflow-y: auto;
}

.link1 {cursor: pointer; color: #31719b}
.link1:hover{text-decoration: underline;}



/*--------- invoice wise reco table height screen wise row view css ---------*/
@media (min-device-width: 0px) and (max-device-width: 599px){
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 80px;
    }
}
@media (min-device-width: 600px) and (max-device-width: 767px){
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 80px;
    }
}
@media (min-device-width: 768px) and (max-device-width: 912px){
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 90px;
    }
}

@media (min-device-width: 913px) and (max-device-width: 1024px){
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 240px;
    }
}
@media (min-device-width: 1025px) and (max-device-width: 1170px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 320px;
    }
}
@media (min-device-width: 1171px) and (max-device-width: 1200px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 330px;
    }
}
@media (min-device-width: 1201px) and (max-device-width: 1260px){
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 350px;
    }
}
@media (min-device-width: 1261px) and (max-device-width: 1280px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 500px;
    }
}

@media (min-device-width: 1281px) and (max-device-width: 1360px){
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 300px;
    }
}
@media (min-device-width: 1361px) and (max-device-width: 1366px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 300px;
    }
}
@media (min-device-width: 1367px) and (max-device-width: 1400px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 410px;
    }
}
@media (min-device-width: 1401px) and (max-device-width: 1460px) {
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 410px;
    }
}
@media (min-device-width: 1461px) and (max-device-width: 1500px){
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 410px;
    }
}
@media (min-device-width: 1501px) and (max-device-width: 1600px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 430px;
    }
}
@media (min-device-width: 1601px) and  (max-device-width: 1660px) {
    .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 430px;
    }
}
@media (min-device-width: 1661px) and (max-device-width: 1680px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 580px; 

    }
}

@media (min-device-width: 1681px) and (max-device-width: 1800px){
   .k-widget.k-grid.table-striped.gstr2atb.invoice-wise-reco.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 580px; 
    }
}
@media (min-device-width: 1801px) and (max-device-width: 1920px){
    .k-widget.k-grid.table-striped.supplierraisereqtb.invoicetb.remove_sortclass.Invoicewisereco_tableheight_viewrowscreenwise .k-grid-content.k-virtual-content {
        overflow: auto;
        max-height: 655px;
    }
}
