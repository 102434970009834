body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

a.supperwise_reco_by2ab_backbtn {
    font-size: 12px;
    color: #fc7d72 !important;
    font-weight: 500;
}

.form-check-inline.manytomany_radiobox {

    & label.form-check-label {
        font-size: 12px;
        font-weight: 500;

        & input.form-check-input {
            vertical-align: middle;
            position: relative;
            top: -1px;
        }
    }
}



.fade.by2bproceedopen_windowload.modal:not(.show) {
    opacity: 1;
}

.fade.by2bproceedopen_windowload.modal.in {
    background-color: rgba(34, 34, 34, 0.6509803922);
    animation-duration: 8s;
    animation-timing-function: ease-in;
    animation-direction: normal;
    transform: scale(1.1);
    transition: all 1000ms ease-in-out;



    & .modal-dialog {
        margin: 17rem auto 0rem auto;
        max-width: 370px;

        & .card.by2bproceed_boxbgclr {
            background-color: #f8f8f8;
            padding: 15px 5px;


            & button.btn.btn-default.manytomany_radiobox_probtn {
                background-color: #31719b;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                border: 0px;
                padding: 3px 10px 5px 10px;
                border-radius: 40px;
            }
        }
    }

}

.fade.by2bproceedopen_windowload.modal .modal-content {

    animation-name: slideOut;
    animation-duration: 5s;
    animation-timing-function: ease-in;
    animation-direction: normal;
    transform: scale(1);
    transition: all 0.5s ease-in-out;


}

.fade.by2bproceedopen_windowload.modal.in .modal-content {

    animation-name: slideIn;
    animation-duration: 8s;
    animation-timing-function: ease-in;
    animation-direction: normal;
    transform: scale(1.1);
    transition: all 1000ms ease-in-out;

}


.fade.multilinkingSellerSelection.modal.show .modal-dialog {
    transform: none;
    max-width: 440px;
    margin: 4rem auto auto auto;
    z-index: 19;
    top: 25%;
}

.manytomany_rangedropbox input.form-control {
    height: calc(1.2em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    font-weight: 500 !important;
    position: relative;
    top: 4px;
}

.manytomany_rangedropbox input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
}

.fade.multilinkingSellerSelection.modal.in .modal-content {

    animation-name: slideIn;
    animation-duration: 8s;
    animation-timing-function: ease-in;
    animation-direction: normal;
    transform: scale(1.1);
    transition: all 1000ms ease-in-out;
}

.multilinkingSellerSelection.modal-header {
    padding: 3px 15px;
    background-color: #eee;
}

.multilinkingSellerSelection.modal-header .modal-title.h4 {
    font-size: 1.2rem;
}

.multilinkingSellerSelection.modal-footer .fade.alert.alert-danger.show {
    font-size: 11px;
    padding: .3rem;
}

.dropdown-menu.manytomany_rangedrop_menu.show {
    padding: 0px;
}



/* Add Animation */
@-webkit-keyframes slideIn {
    from {
        top: 50px;
        opacity: 1;
    }

    to {
        top: 50px;
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        top: 50px;
        opacity: 1;
    }

    to {
        top: 50px;
        opacity: 1;
    }
}


/* Add out Animation */
@-webkit-keyframes slideOut {
    from {
        top: 70px;
        opacity: 0.3;
        left: -100px;
    }

    to {
        top: 70px;
        opacity: 0;
        left: -100px;
    }
}

@keyframes slideOut {
    from {
        top: 70px;
        opacity: 0.3;
        left: -100px;
    }

    to {
        top: 70px;
        opacity: 0;
        left: -100px;
    }
}





span.dropdown.manytomany_rangedropinner {
    span.btn.btn-primary.dropdown-toggle {
        background-color: #fff;
        color: #333;
        padding: 2px 5px 2px 5px;
        font-size: 12px;
        font-weight: 500;
        border: 1px solid #ddd;
        vertical-align: bottom;
        border-radius: 0px;


    }

    & span.dropdown-menu.manytomany_rangedrop_menu.show {
        padding: 0px;
        border-radius: 0px;
        width: 100%;
        position: static;

        & span.dropdown-item {
            padding: 2px 5px;
            font-size: 11px;
            font-weight: 500;
            // color: #212529 !important;
            cursor: pointer;
        }

        & span.dropdown-item:hover {
            text-decoration: underline;
        }

        & span.dropdown-item:active {
            color: #222;
            text-decoration: none;
            background-color: #eee;
            cursor: pointer
        }

        & .dropdown-divider {
            padding: 0px 0px;
            margin: 0px;
        }
    }
}

span.dropdown-menu.manytomany_rangedrop_menu.daymenu.show {
    min-width: 55px;
}

.manytomany_datebefore_afterboxlist {
    display: inline-flex;

    span.manytomany_rangedrop_valbox {
        & input.form-control.rangdropval_input {
            height: calc(0.8em + 0.75rem + 2px);
            width: 50px;
            font-size: 12px;
            padding: 0.5rem 0.75rem;
            border-radius: 0px;
        }

        & input.form-control.rangdropval_input:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
        }
    }
}

span.manytomany_rangedrop_supp_textfs {
    padding-right: 1px;
    color: #fc7d72;
    vertical-align: super;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    top: 5px;
}

span.manytomany_rangedrop_titlefs {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    top: 2px;
}

span.btn.btn-default.manytomany_rangedrop_linkbtn {
    font-size: 12px;
    font-weight: 500;
    float: right;
    background-color: #31719b;
    border: 0px;
    border-radius: 40px;
    padding: 3px 15px 5px 15px;
    color: #fff;
}

.manytomany_rangedrop_b2bcheckboxinline {
    display: inline-flex;

    & h4.manytomany_rangedrop_books_fs {
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;
        top: 4px;
    }
}

table.table.table-striped.supperwise_manytomany_by2ab_tb {

    thead tr th {
        border-top: 0px;
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 5px;
        padding-top: 5px;
        text-align: center;
    }

    tr td {
        font-size: 11px;
        padding: 0.25rem 0.75rem;
        font-weight: 500;
    }

    tr.manytomany_rangedrop_tbrow_bgclr_draggreen {
        background-color: #e0e0e0;
    }

    tr.manytomany_rangedrop_tbrow_bgclr_lightblue {
        background-color: #d6eaf8 !important;
    }

}

.manytomany_rangedrop_tableborder_middle {
    border-right: 2px dashed #ddd;
    padding-left: 28px;
}

.manytomany_rangedrop_bordermidd_box {
    display: inline-flex;
}

tr.Many_to_many_linkdoc_bgclr {
    background-color: #fcf3cf !important;
}

tr.manytomany_rangedrop_tbrow_bgclr_purpal {
    background-color: #fae4d3 !important;
}

tr.manytomany_rangedrop_tbrow_bgclr_yell {
    background-color: #fcf3cf !important;
}

span.transwise_linkdocs {
    font-size: 12px;
    font-weight: 500;
}

span.checkboxclr_linkdocs_blueclr {
    color: #d6eaf8;
}

span.checkboxclr_linkdocs_redclr {
    color: #fcf3cf;
}

/*================header toggl btn css========================*/

.card.by2bproceed_boxbgclr {
        background-color: #f8f8f8;
    padding: 5px;
    box-shadow: 0px 2px 23px #222;


    span.dropdown.manytomany_rangedropinner input.form-control {
        height: calc(1.2em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 12px;
        font-weight: 500;
    }
}


button.multiVerifyBtn {
    background-color: #31719b;
    color: #fff;
    border: 1px solid #31719b;
    padding: 2px 15px 5px 15px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
}

button.multiVerifyBtn:hover,
button.multiVerifyBtn:active,
button.multiVerifyBtn:focus {
    background-color: #fc7d72 !important;
    color: #fff !important;
    border: 1px solid #fc7d72 !important;
}


.manytomany_togglebtn {
    top: 8.6px;
    position: relative;
    right: 1.2rem;

    span.manytomany_togglebtn_alldoc_fs {
        font-size: 12px;
        font-weight: 500;
        padding-right: 10px;
    }

    label.switch.manytomany_toggleswit {
        position: absolute;
        display: inline-block;
        width: 23px;
        height: 11px;
        top: 9px;
    }

    label.switch.manytomany_toggleswit input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    label.switch.manytomany_toggleswit .slider {
        position: absolute;
        cursor: pointer;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #4c4c4c;
        transition: .4s;
    }

    label.switch.manytomany_toggleswit .slider:before {
        position: absolute;
        content: "";
        height: 7.3px;
        width: 3px;
        top: 2px;
        left: 3px;
        background-color: rgb(242 242 242);
        transition: .4s;
        outline-color: rgb(242 242 242);
    }

    label.switch.manytomany_toggleswit input:checked+.slider {
        background-color: #8bc34a;
    }


    label.switch.manytomany_toggleswit input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        left: -9px;
    }

    /* Rounded sliders */
    label.switch.manytomany_toggleswit .slider.round {
        border-radius: 34px;
    }

    label.switch.manytomany_toggleswit .slider.round:before {
        border-radius: 16%;
    }
}

//css for input box autocomplete

.autocomplete-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.invoicereco_by2a2b_checkbox_left {
    display: inline-block;
    position: relative;
    top: 4px;
}

.form-check-inline.invoicereco_by2a2b_checkbox {
    display: inline-block;
    margin-right: 5px;
}

.custom-window .k-window-actions {
    display: none;
}

.disabled-overlay {
    pointer-events: none;
    opacity: 0.5;
    /* Optional: to visually indicate that the content is disabled */
}

.inline-block-element {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    /* Optional: add space between the checkboxes and the Books header */
}


.autocomplete-input {
    position: relative;
}

.manytomany_rangedropbox {
    display: flex;
    align-items: center;
    width: 100%;
}

.manytomany_rangedrop_supp_textfs {
    white-space: nowrap;
    /* Prevents the text from breaking */
    margin-right: 8px;
    /* Adds space between the label and the input */
}


/*================end header toggl btn css========================*/

/*================table book css 2B ========================*/
.manytomany_rangedrop_tbrow_bgclr_lightblue {
    background-color: lightblue;
}

.Many_to_many_linkdoc_bgclr {
    background-color: lightgreen;
}

.k-widget.k-grid.table-responsive.multilinking-data-listing .k-grid-content.k-virtual-content {
    overflow-y: auto;
}


.manytomany_rangedropbox .form-group span.invalid-feedback {
    position: relative;
    left: 4.4rem;
}

input.form-control.multilinking_dropinputfs {
    font-size: 11px;
    font-weight: 500;
    padding: 0px 5px;
}

.dropdown-menu.multilinking_droplistbox.show {

    & span.dropdown-item {
        font-size: 11px !important;
        font-weight: 500;
        padding: 2px 5px;
        background-color: #eee;
        cursor: pointer;
        color: #31719B;
    }
    & span.dropdown-item:hover {
        background-color: #f3f3f3 !important;
    }

    & span.dropdown-item.link {
        padding: 3px 4px !important;
        font-size: 11px !important;
        font-weight: 500;
        margin: 0rem;
        color: #007bff !important;
    }

    & span.odd {
        background-color:#fff;
    }
    & span.even {
        background-color:#eee;
    }
    & span.dropdown-item.disabled{
        color: #7c7676;
    }
}