body{   
    background-color: #ffffff!important;
     font-size: 1rem;
     font-weight: normal ;
     line-height: 1.5;
     color: #212529;
     text-align: left;
     -webkit-font-smoothing: antialiased;
     font-weight: 500 !important;
     font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
 }

 
::placeholder{
    color: #9c9c9c !important;
    font-size: 11px;
}

.modal.gsteway_Addinfo_bill_module.show .modal-dialog {
    max-width: 500px;
    margin: 2rem auto auto auto;

    & .modal-header.gsteway_Addinfo_bill_header {
        background-color: #31719b;
        padding: 0px 15px;
        
        & h4.modal-title.gsteway_Addinfo_bill_title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px;
            color: #fff;
        }

        & button.close {
            position: relative;
            top: 2px;
            color: #fff;
        }

        & button:focus {
            outline: 1px dotted;
            outline: 0px auto -webkit-focus-ring-color;
        }

    }

    .form-group.gsteway_Addinfo_bill_grp {
        margin-bottom: 0.5rem;

        & label.gsteway_Addinfo_bill_label {
            font-size: 12px;
            font-weight: 500;

            span.red_text {
                color: red;
            }
        }

        input.form-control.gsteway_Addinfo_bill_grpinput {
            font-size: 12px;
            font-weight: 500;
        }
        input.form-control.gsteway_Addinfo_bill_grpinput:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
        }
    }

    button.gsteway_Addinfo_bill_submitbtn {
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        background-color: #31719b;
        color: #fff;
        padding: 3px 15px 5px 15px;
        border-radius: 40px;
    }

    button.gsteway_Addinfo_bill_submitbtn:focus {
        outline: 1px dotted;
        outline: 0px auto -webkit-focus-ring-color;
    }

    span.gsteway_Addinfo_bill_gpsAPI_textfs {
        font-size: 12px;
        font-weight: 500;
        color: #105999;
        text-decoration: underline;
        cursor: pointer;
    }

    input.form-control.gsteway_Addinfo_bill_passinput {
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 12px;
        font-weight: 500;
    }

    .input-group-prepend.gsteway_Addinfo_bill_prependbox_eyeicon {
        margin-right: -1px;
        padding: 8px 4px 2px 3px;
        border: 1px solid #ced4da;
        border-radius: 0 0.25rem 0.25rem 0;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: #31719b;
        border-left: 0px;
        cursor: pointer;
    }
    input.form-control.gsteway_Addinfo_bill_passinput {
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 12px;
        font-weight: 500;
        border-right: 0px;
    }

    input.form-control.gsteway_Addinfo_bill_passinput:focus {
        color: #495057;
        background-color: #fff;
        border-color: #ced6e0;
        outline: 0;
        box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
    }

    .svg-inline--fa.fa-w-18 {
        width: 1.125em;
        font-size: 13px;
        font-weight: 500;
    }

}


fieldset.gsteway_GSPCredentials_fs {
    border: 1px solid #fc7d72;

    & legend.gsteway_GSPCredentials_leg {

        & .gsteway_GSPCredentials_inndev {
            display: block;

            .gsteway_GSPCredentials_leftside {
                float: left;
                padding: 0 5px;

                h4 {
                    font-size: 1rem;
                    font-weight: 500;
                    position: relative;
                    top: 7px;
                }
            }

            .gsteway_GSPCredentials_innerhieghtdiv {
                height: 1px;
                width: 100%;
                position: relative;
            }
                .gsteway_GSPCredentials_rightsidediv {
                    float: right;
                    padding: 0px 5px;

                    span.gsteway_GSPCredentials_billiconbox {
                        position: relative;
                        top: -4px;
                        cursor: pointer;
                    }

                }

                .gsteway_GSPCredentials_middlebor {
                    border-top: 1px solid #fc7d72;
                    display: flex ;
                    position: relative;
                    margin-top: 19px;
                }

        }
    }
}

fieldset.gsteway_GSPCredentials_fsborderblue {
    border: 1px solid #31719b;
}
.gsteway_GSPCredentials_middleborderblue {
    border-top: 1px solid #31719b !important;
}

fieldset {
    display: block;
    margin: 9px 2px 2px !important;
    background-color: #fff;
    padding: .35em .75em .625em !important;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: -.4rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}