body {
   background-color: #ffffff !important;
   font-size: 1rem;
   font-weight: normal;
   line-height: 1.5;
   color: #212529;
   text-align: left;
   -webkit-font-smoothing: antialiased;
   font-weight: 500 !important;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", ;
}

/* ========== fieldset css =================*/
fieldset {
   display: block;
   margin: 9px 2px 2px 2px !important;
   background-color: #fff;
   padding: .35em .75em .625em !important;
   border: 2px groove (internal value);

}

legend {
   display: block;
   padding: 0 5px;
   width: 100%;
   max-width: 100%;
   padding: 0;
   margin-bottom: -.4rem;
   font-size: 1.5rem;
   line-height: inherit;
   color: inherit;
   white-space: normal;
}

fieldset.fsreturnsummery {
   border: 1px solid #31719a;
}

legend.legentrs {
   width: auto;
}

legend.legentrs h4 {
   font-size: 1.2rem;
   padding: 0px 5px;
}

table.table.table-striped.rstb thead tr th {
   font-size: 12px;
   text-align: center;
   border-top: 0px;
   font-weight: 500;
   color: #31719b;
   padding-top: 0px;
}

table.table.table-striped.rstb tr td {
   font-size: 11px;
   font-weight: 500;
   padding: .25rem .75rem;
   text-align: center;
}

fieldset.fsreconcol {
   border: 1px solid #fc7d72;
}

legend.legentreconcol {
   width: 11%;
}

legend.legentreconcol h4 {
   font-size: 1.2rem;
   padding: 0px 5px;
}

table.table.table-striped.reconcol thead tr th {
   font-size: 12px;
   text-align: center;
   border-top: 0px;
   font-weight: 500;
   color: #31719b;
   padding-top: 0px;
}

table.table.table-striped.reconcol tr td {
   font-size: 11px;
   font-weight: 500;
   padding: .25rem .75rem;
   text-align: center;
}

fieldset {
   display: block;
   margin: 9px 2px 2px 2px !important;
   background-color: #fff;
   padding: 0.35em 0.75em 0.625em !important;
   border: 2px groove (internal value);
}

fieldset.retundash_salebyr1purchase_fs {
   border: 1px solid #fc7d72;
}

fieldset.retundash_nettaxliabilityr12a_fs {
   border: 1px solid #31719b;
}

fieldset.retundash_nettaxliabilityr12a_fs .mborinner {
   border-top: 1px solid #31719b;
}

fieldset.retundash_nettaxliabilityr12a_fs .rightsideinnerbor {
   border-top: 1px solid #31719b;
}

fieldset.fieldsbyr1 {
   border: 0px solid #fc7d72;
}

.sbyr1inndev {
   display: block;
}

.sbyr1leftside {
   float: left;
   padding: 0 5px;
}

.sbyr1leftside h4 {
   font-size: 1.2rem;
   margin-top: 2px;
}

.sbyr1subinnerdiv {
   height: 1px;
   width: 100%;
   position: relative;
}

.sbyr1rightsidediv {
   float: right;
   padding: 0 5px;
}

.sbyr1topmainbor {
   border-top: 1px solid #fc7d72;
   display: flex;
   position: relative;
   margin-top: 16px;
}

.leftsidebox {
   font-size: 13.5px;
   padding-top: 3px;
}

.mborinner {
   border-top: 1px solid #fc7d72;
   display: flex;
   position: relative;
   margin-top: 14px;
   width: 27px;
   float: right;
}

.fliiconborder {
   border-top: 1px solid #fc7d72;
   display: flex;
   position: relative;
   margin-top: 11px;
   margin-left: 3px;
   width: 25px;
   float: right;
}

.mborinner.borblurclr {
   border-top: 1px solid #3e50b4;
}

.rightsideinnerbor.borblurclr {
   border-top: 1px solid #3e50b4;
}

.msidecontbox {
   float: right;
   padding: 0 5px;
}

.mcontent {
   font-size: 13.5px;
   padding-top: 3px;
}

.rightsideinnerbor {
   border-top: 1px solid #fc7d72;
   display: flex;
   position: relative;
   margin-top: 15px;
   top: -1px;
}

.rightsideinnerbor.itcopbor {
   border-top: 1px solid #31719a;
}

.salesbyr1chartbox h4 {
   font-size: 1.2rem;
}

fieldset.fieldsetITC {
   border: 0px solid #31719a;
}

fieldset.fieldsetCCledger {
   border: 1px solid #31719b;
}

.CCledgerinndev {
   display: block;
}

.CCledgerleftside {
   float: left;
   padding: 0 5px;
}

.CCledgerinndev h4 {
   font-size: 1.2rem;
   position: relative;
   top: 1px;
}

.CCledgerinnerhieghtdiv {
   height: 1px;
   width: 100%;
   position: relative;
}

.CCledgerrightsidediv {
   float: right;
   padding: 0 5px;
}

h4.detailsason {
   padding-top: 4px;
   font-size: 0.9rem;
}

span.refreshiconcc {
   font-size: 0.9rem;
   color: #31719b;
   cursor: pointer;
   padding-left: 8px;
   cursor: pointer;
}

.CCledgertpbor {
   border-top: 1px solid #31719b;
   display: flex;
   position: relative;
   margin-top: 14px;
}

table.table.table-striped.CCledgertb thead tr th {
   font-size: 12px;
   text-align: center;
   border-top: 0px;
   font-weight: 500;
   color: #31719b;
   padding-top: 0px;
}

table.table.table-striped.CCledgertb tr td {
   font-size: 11px;
   font-weight: 500;
   padding: .25rem .75rem;
}

fieldset.gstr1MMfieldset {
   border: 1px solid #fc7d72;
}

legend.gst1MMlegent {
   width: 58%;
   padding-bottom: 0px;
}

legend.gst1MMlegent h4 {
   font-size: 1.2rem;
   padding: 0 5px;
}

table.table.table-striped.gstr1mmtb thead tr th {
   font-size: 12px;
   text-align: center;
   border-top: 0px;
   font-weight: 500;
   color: #31719b;
}

table.table.table-striped.gstr1mmtb tr td {
   font-size: 11px;
   font-weight: 500;
   padding: .25rem .75rem;
}

th.gstr1totalbgclr {
   background-color: #fff8dc;
}

td.gstr1totalbgclr {
   background-color: #fff8dc;
}

tr.gstr1totalbgclr {
   background-color: rgb(247 255 201);
}

/* ========== fieldset css =================*/


/*leftside info popup box=====*/

.gstr1leftsidepopupbox a.mb-0.btn {
   color: #fff !important;
   font-size: 15px;
   font-weight: 600;
   padding-right: 30px;
}

.gstr1leftsidem .modal-dialog {
   /* margin: 62px 0; */
   /* width: 477px; */
   margin: 3rem auto auto 0rem;
   width: 520px;
   overflow: hidden;
}

.modal-header.gstr1leftsideh {
   padding: .5rem 1rem;
   background-color: #eee;
}

.modal-header.gstr1leftsideh h4.modal-title {
   font-size: 1rem;
}

table.table.table-striped.gstr1TB thead tr th {
   border-top: 0;
   font-size: 12px !important;
   padding: 0;
   font-weight: 500;
}

table.table.table-striped.gstr1TB tr td {
   font-size: 11px;
   padding: .25rem .75rem;
}

span.browsegstr1 img {
   width: 14px;
   cursor: pointer;
   margin-left: 5px;
}

.impoforsuptext {
   font-size: 10px;
   color: #9c9c9c;
   text-align: right;
}

.card.gstr1card {
   padding: .2rem .5rem;
   margin-bottom: 4px;
}

table.table.table-striped.gstr1TB thead tr.topthbor th {
   border-bottom: 0px solid #dee2e6 !important;
   font-size: 12.5px !important;
   text-align: center;
}

.gstr1donttem {
   font-size: 10px;
   color: #9e9e9e;
   float: left;
}

span.btn.btn-default.gsrt1pupupdonebtn {
   background-color: #31719b;
   border: 1px solid #31719b;
   font-size: 12px;
   font-weight: 500;
   border-radius: 40px;
   padding: 5px 27px;
   color: #fff;
}

.gstr1leftsidepopupbox {
   position: fixed;
   padding: 0rem 0.4rem 0.1rem 0.2rem !important;
   background-color: #31719b;
   color: #fff;
   z-index: 20;
   top: 53%;
   transform: rotate(270deg);
   border-radius: 0 0 5px 5px;
   left: -5.1rem;
   overflow: hidden;
}

span.uploaddataicon {
   display: block;
   transform: rotate(88deg);
   transform-box: fill-box;
   margin-top: 1.4rem;
   top: 0;
   left: 6rem;
   display: none;
   position: absolute;
}

td.dashscrfs {
   color: #3f67a1;
   text-align: left !important;
   font-size: 11px !important;
   cursor: pointer;
}

table.table.table-striped.gstr1TB thead tr th.text-center.borboot {
   border-bottom: 1px solid #dee2e6 !important;
}

.DImpotrightsborgrayclr {
   border-right: 1px solid #dee2e6 !important;
}

span.browdeliconaligright {
   float: right;
}

.downloadtempimpostd {
   font-size: 9px;
   color: #007bff;
   text-decoration: none;
   background-color: transparent;
   cursor: pointer;
}

.downloadtempimpostd:hover {
   text-decoration: underline;
}

span.wesupportgovttemptext {
   font-size: 12px;
   cursor: pointer;
   font-weight: 600;
   color: rgb(27, 27, 27);
}

/*===end leftside info popup box=====*/

/*======================= Habitual popup css ======================= */


table.table.table-striped.habitualtb thead tr th {
   border-top: 0px;
   font-size: 12px;
   font-weight: 500;
   text-align: center;
   padding-top: 4px;
   padding-bottom: 4px;
}

table.table.table-striped.habitualtb tr td {
   font-size: 11px;
   font-weight: 500;
   padding: .25rem .75rem;
}

span.habitdcircleicon {
   color: #689e39;
   font-size: 10px;
}

td.overflowtooltiphabitupop {
   max-width: 127px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   word-wrap: break-word;
   cursor: pointer;
}

span.taxcoltextclr {
   color: #a3a5a7;
}

.modal-header.viewcompdheader {
   padding: .5rem 1rem;
   background-color: #eee;
}

.modal-header.viewcompdheader h4.modal-title {
   font-size: 1.2rem;
}

th.suqiocnw10 {
   width: 10px;
}

/*======================= Habitual popup css ======================= */


.viewdetailscompbox {
   border: 1px solid #fc7d72;
   padding: 18.5px 15px;
   margin: 25px 6px 0px 0px;
}

.rightsideborbox {
   background-image: url(../../images/rightsideshape.png);
   background-size: cover;
   position: absolute;
   display: block;
   right: 20px;
   height: 50px;
   width: 50px;
   top: 22px;
}

.viewdetailscompbox p {
   color: #31719b;
   font-size: 13.5px;
}

span.boldviewtext {
   color: #fc7d72;
   font-size: 13px;
   font-weight: 700;
}

button.btn.btn-default.viwcompdetailsbtn {
   color: #007bff;
   cursor: pointer;
}

td.retsumtdalig {
   text-align: left !important;
   font-size: 12px !important;
}

.rdBlueCenter {
   color: #3f67a1;
   text-align: left;
}

.rdBluePointer {
   color: #31719b;
   cursor: pointer;
}

.rdBlueFont500 {
   color: #3f67a1;
   font-size: 11px;
   font-weight: 500;
}

.rdGreen {
   color: #82ae5c;
}

.rdRed {
   color: red;
}

.rdRedd {
   color: #f56258;
}

.rdOrng {
   color: #f5922e;
}

.rdCommingSoonSpan {
   color: #f5922e;
   font-size: 10px;
}

.rdLightBlue {
   color: #45dccc;
}

.rdDarkBlue {
   color: #3e50b4;
}

.rdLiteBlue {
   color: #3f67a1;
}

.rdLiteBlueFont13 {
   color: #3f67a1;
   font-size: 13.5px;
}

.rdOrenge {
   color: #fc7d72;
}

.rdOrengeTextAlignLeft {
   color: rgb(252, 125, 114);
   text-align: left;
}

.rdTextCenter {
   text-align: center;
}

.rbfontSize12TextCenter {
   font-size: 12px;
   text-align: left;
}

.rbfontSize12BlueCursorPointer {
   font-size: 12px;
   color: rgb(49, 113, 155);
   cursor: pointer;
}

.rdImgSz {
   width: 13px;
   margin-top: 0px;
   padding-left: 1px;
}

.disable {
   color: #ccc !important;
   pointer-events: none;
}

.disable a {
   color: #ccc !important;
   pointer-events: none;
}

.uploadsutopborReconciliation {
   border-top: 1px solid #fc7d72;
   display: flex;
   position: relative;
   margin-top: 14px;
}

.uploadoongstnbtnReconciliation a {
   color: #fff !important;
   text-decoration: none;
}

/*end leftside info popup box=====*/
fieldset {
   display: block;
   margin: 9px 2px 2px 2px !important;
   background-color: #fff;
   padding: .35em .75em .625em !important;
   border: 2px groove (internal value);

}

legend {
   display: block;
   padding: 0 5px;
   width: 100%;
   max-width: 100%;
   padding: 0;
   margin-bottom: -.4rem;
   font-size: 1.5rem;
   line-height: inherit;
   color: inherit;
   white-space: normal;
}

fieldset.r1vssale_reconciliation_fs {
   border: 1px solid #fc7d72;
}

.r1vssale_reconciliation_inndev {
   display: block;
}

.r1vssale_reconciliation_leftside {
   float: left;
   padding: 0 5px;
}

.r1vssale_reconciliation_leftside h4 {
   font-size: 1.2rem;
   padding: 0px 5px;
   top: 5px;
   position: relative;
}

.r1vssale_reconciliation_innerhieghtdiv {
   height: 1px;
   width: 100%;
   position: relative;
}

.r1vssale_reconciliation_rightsidediv {
   float: right;
   padding: 0 5px;
}

table.table.table-striped.r1vssale_reconciliation_tb thead tr th {
   font-size: 12px;
   text-align: center;
   border-top: 0px;
   font-weight: 500;
   color: rgb(49, 113, 155);
   padding-top: 0px;
}

table.table.table-striped.r1vssale_reconciliation_tb tr td {
   font-size: 11px;
   font-weight: 500;
   padding: 0.25rem 0.75rem;
   text-align: center;
}

td.textbold_blueclr {
   color: #3f67a1;
   text-align: left !important;
   font-size: 12px !important;
}

span.R1reconc_shapeimg img {
   position: absolute;
   z-index: 1;
}

span.R1reconc_Lshapebor_line01 {
   position: relative;
   top: -0.9rem;
   left: -2rem;
}

span.R1reconc_Lshapebor_line02 {
   position: relative;
   top: -0.9rem;
   left: -2rem;
}

.r1vssale_reconciliation_middlebor {
   border-top: 1px solid #fc7d72;
   display: flex;
   position: relative;
   margin-top: 14px;
}

button.btn.btn-default.r1vssale_reconciliation_booksvsr1annual {
   background-color: #31719b;
   color: #fff;
   border: none;
   padding: 4px 12px 6px;
   border-radius: 40px;
   font-size: 12px;
   position: relative;
   top: 0px;
   font-weight: 500;
}

button.btn.btn-default.r1vssale_reconciliation_booksvs2a2bannual {
   background-color: #31719b;
   color: #fff;
   border: none;
   padding: 4px 12px 6px;
   border-radius: 40px;
   font-size: 12px;
   position: relative;
   top: 0px;
   font-weight: 500;
}



.uploadsuleftside {
   float: left;
}

fieldset {
   display: block;
   background-color: rgb(255, 255, 255);
   margin-left: 9px;
   margin-right: 9px;
   padding: 0.35em 0.2em 0.625em !important;
   margin-bottom: 8px !important;
}

legend {
   display: block;
   width: 100%;
   max-width: 100%;
   padding: 0px;
   font-size: 1.5rem;
   line-height: inherit;
   color: inherit;
   white-space: normal;

}

span.dark_color_bg2.float-right.uploadoongstnbtn {
   background-color: #31719b;
   color: #fff;
   border: none;
   padding: 6px 12px 8px 12px;
   border-radius: 40px;
   font-size: 12px;
   position: relative;
   top: -1px;
}

fieldset.fieldsetuploadsu {
   border: 1px solid #31719b;
}

.uploadsuinndev {
   display: block;
}

.uploadsuleftside {
   float: left;
   padding: 0 5px;
}

.uploadsuleftside h4 {
   font-size: 1.2rem;
   padding: 0px 5px;
}

.uploadsuinnerhieghtdiv {
   height: 1px;
   width: 100%;
   position: relative;
}

.uploadsurightsidediv {
   float: right;
   padding: 0 5px;
}

.uploadsutopbor {
   border-top: 1px solid #31719b;
   display: flex;
   position: relative;
   margin-top: 14px;
}

h4.uploadsutextright {
   padding: 0px 0px;
}

span.dark_color_bg2.float-right.downjsonbtn {
   background-color: #31719b;
   color: #fff;
   border: none;
   padding: 6px 12px 8px 12px;
   border-radius: 40px;
   font-size: 12px;
}


fieldset.fieldsetAuthS {
   border: 1px solid #31719b;
}

legend.legentAuthS {
   width: 18%;
}

legend.legentAuthS h4 {
   font-size: 1.2rem;
}


/*--css Tax Liability table --*/
fieldset.fieldsetTaxliability {
   border: 1px solid #31719b;
}

legend.Taxliabilitylegent {
   width: 100%;
}

.Taxliabinndev {
   display: block;
}

.Taxliableftside {
   float: left;
   padding: 0 5px;
}

.Taxliableftside h4 {
   font-size: 1.2rem;
}

.Taxliabinnerhieghtdiv {
   height: 1px;
   width: 100%;
   position: relative;
}

.Taxliabrightsidediv {
   float: right;
   padding: 0 5px;
}

h4.Taxliabtext {
   font-size: 1rem;
   background-color: #31719b;
   border-radius: 40px;
   padding: 1px 11px 5px 11px;
   color: #fff;
}

.Taxliabtpbor {
   border-top: 1px solid #31719b;
   display: flex;
   position: relative;
   margin-top: 14px;
}

/*========= collapse tbale tr css =========*/
tr.hide-table-padding td {
   padding: 0;
}

.expand-button {
   position: relative;
   cursor: pointer;
}

.accordion-toggle .expand-button:after {
   position: absolute;
   left: .75rem;
   top: 57%;
   transform: translate(0, -50%);
   content: url(../../images/upicon.png);
}

.accordion-toggle.collapsed .expand-button:after {
   content: url(../../images/donicon.png);
}

/*========= collapse tbale tr css =========*/

tr.innertrbgcolor {
   background-color: rgb(253, 244, 231) !important;
}

td.lightredclr {
   color: #f5922e;
}

td.dargredclr {
   color: #df3427;
}

span.iocncollthiconfs i {
   font-size: 17px;
}

td.overflowtextliablity {
   max-width: 75px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   word-wrap: break-word;
}

td.overflowtextliablitytd {
   max-width: 123px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   word-wrap: break-word;
   width: 104px;
}

table.table.table-striped.gstr1mmtb {
   margin-bottom: 0rem;
}

td.textlib_tdpadd_left {
   padding-left: 20px !important;
}

/*--end css Tax Liability table --*/

td.gstr1_multimonth_overflowtext {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   word-wrap: break-word;
   max-width: 170px;
}

/*========== start Media code ===============*/
@media (max-width: 767px) {

   legend.legentrs h4 {
      font-size: 1rem;
   }

   legend.legentreconcol {
      width: 52%;
   }

   legend.legentreconcol h4 {
      font-size: 1rem;
   }

   .sbyr1leftside h4 {
      font-size: 0.7rem;
   }

   .leftsidebox {
      font-size: 10px;
      padding-top: 1px;
   }

   .minnerbox {
      font-size: 10px;
      padding-top: 1px;
   }

   .mcontent {
      font-size: 10px;
      padding-top: 3px;
   }

   .sbyr1inndev {
      padding-top: 14px;
      margin-top: 8px;
   }

   .CCledgerinndev h4 {
      font-size: 1rem;
   }

   .CCledgerinndev {
      margin-top: 1.5rem;
   }

   legend.gst1MMlegent {
      width: 98%;
   }

   legend.gst1MMlegent h4 {
      font-size: 0.9rem;
      padding: 0 5px;
   }

   h4.detailsason {
      padding-top: 3.4px;
      font-size: 0.9rem;
   }

   .gstr1leftsidem.modal.show .modal-dialog {
      width: auto;
   }

}

/*================== start media code=================*/
@media (min-width: 768px) and (max-width: 940px) {

   legend.legentreconcol {
      width: 21%;
   }

   .sbyr1leftside h4 {
      font-size: 0.9rem;
      margin-top: 5px;
   }
}

@media (min-width: 941px) and (max-width: 1025px) {

   legend.legentreconcol {
      width: 15%;
   }

   .sbyr1leftside h4 {
      font-size: 0.9rem;
      margin-top: 5px;
   }

   .mcontent {
      font-size: 11.5px;
   }

   .leftsidebox {
      font-size: 11.5px;
   }

   .sbyr1leftside h4 {
      font-size: 1rem;
      margin-top: 0px;
   }

   .CCledgerinndev h4 {
      font-size: 1rem;
   }
}

@media (min-width: 1026px) and (max-width: 1281px) {
   .sbyr1leftside h4 {
      font-size: 1rem;
   }

   .CCledgerinndev h4 {
      font-size: 1rem;
      top: 4px;
   }

}

@media (max-width: 767px) {
   span.R1reconc_Lshapebor_line01 {
      left: -1rem;
   }

   span.R1reconc_Lshapebor_line02 {
      left: -1rem;
   }
}

@media (min-width: 768px) and (max-width: 820px) {
   span.R1reconc_Lshapebor_line01 {
      left: -1.3rem;
   }

   span.R1reconc_Lshapebor_line02 {
      left: -1.3rem;
   }
}

@media (min-width: 821px) and (max-width: 913px) {
   span.R1reconc_Lshapebor_line01 {
      left: -1.3rem;
   }

   span.R1reconc_Lshapebor_line02 {
      left: -1.3rem;
   }
}

@media (min-width: 914px) and (max-width: 1025px) {
   span.R1reconc_Lshapebor_line01 {
      left: -1.3rem;
   }

   span.R1reconc_Lshapebor_line02 {
      left: -1.3rem;
   }
}

/*======= Announcements code ==============*/
@-webkit-keyframes fadeInOut {
   0% {
      Opacity: 0;
   }

   100% {
      Opacity: 1;
   }
}

@keyframes fadeInOut {
   0% {
      Opacity: 0;
   }

   100% {
      Opacity: 1;
   }
}

.active_announcement {
   overflow: hidden;
}

.active_announcement a.mb-0.btn,
.active_announcement span.mb-0.btn {
   padding: 0.375rem 1.9rem 0.375rem 0.40rem;
}

.AnnouncementsIcon {
   position: absolute;
   top: 0px;
   right: -15px;
   transform: rotate(90deg);
   cursor: pointer;
}

.Announcements {
   background-color: #fc7d72;
   width: 55px;
   position: relative;
   padding-left: 26px;
   padding-top: 2px;
}

.Announcements-wrapper>.Dropdown-toggle:before {
   display: none;
}

.Dropdown.Announcements-wrapper .Announcements-bubble {
   display: none;
   width: 24px;
   height: 26px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble {
   box-sizing: border-box;
   overflow: hidden;
   transform: rotate(15deg);
   border-radius: unset;
   display: block;
   position: absolute;
   padding: 0px;
   top: -10px;
   background-color: transparent;
   left: 40px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble:before {
   content: '';
   border: 2px solid #fff;
   border-radius: 50%;
   position: absolute;
   width: 10px;
   height: 10px;
   -webkit-animation: fadeInOut 1s infinite .2s;
   animation: fadeInOut 1s infinite .2s;
   left: -4px;
   bottom: -4px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble:after {
   content: '';
   border: 2px solid #fff;
   border-radius: 50%;
   position: absolute;
   width: 16px;
   height: 16px;
   -webkit-animation: fadeInOut 1s infinite .4s;
   animation: fadeInOut 1s infinite .4s;
   left: -6px;
   bottom: -6px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .i-volume {
   color: #fff;
}

.Dropdown.Announcements-wrapper.has-unseen-items .i-volume:before {
   content: "\f103";
}

.selectedItem {
   background-color: #fc7d72;
   color: #fff;
}

.notSelectItem {
   background-color: #fff;
   color: #666666;
}

.notSelectItem:hover {
   background-color: #fc7d72;
   color: #fff;
}

/*======= Announcements code ==============*/


/*--GSTR 1 Multi-Month Summary of Filed Returns table css --*/

fieldset.gstr1multim_img_fieldset {
   border: 1px solid #fc7d72;
}

legend.gstr1multim_img_legent {
   width: 58%;
}

legend.gstr1multim_img_legent h4 {
   font-size: 1.2rem;
   position: relative;
   top: 3px;
   padding: 0px 5px;
}

.gstr1_multimonth_bgimg {
   /* background-image: url(../../images/gstr1multi_bgimg.png); */
   background-position: bottom;
   background-size: cover;
   width: 100%;
   height: 206px;
}

.cornerright_img {
   background-image: url(../../images/corner-bottomimg.png);
   background-position: bottom;
   background-size: cover;
   width: 140px;
   height: 131px;
   background-attachment: inherit;
   position: absolute;
   bottom: 25px;
   right: 30px;
   transition: 2s;
   z-index: 9;
}

.cornerright_img:hover {
   transition: all .7s ease-in-out;
   -webkit-transform: scale(1.3);
   transform: scale(1.3);
   overflow: hidden;
   transition: 2s;
   bottom: 2.6rem;
   right: 3.2rem;
}

a.cliecktextfs {
   bottom: 23px;
   position: absolute;
   right: 8px;
   font-size: 14px;
   font-weight: 600;
   color: #31719b;
}

a.cliecktextfs:hover {
   text-decoration: none;
}


.modal-header.gstr1_multimonth_summ_header {
   padding: 0rem 1rem;
   background-color: #eee;
}

.modal-header.gstr1_multimonth_summ_header h4.modal-title {
   font-size: 1.2rem;
   padding: 0.3rem 0rem;
}

.modal-header.gstr1_multimonth_summ_header button.close {
   position: relative;
   top: 7px;
}

.modal-header.gstr1_multimonth_summ_header button:focus {
   outline: 1px dotted;
   outline: 0px auto -webkit-focus-ring-color;
}

table.table.table-striped.tb_gstr1multisumm thead tr th {
   font-size: 12px;
   text-align: center;
   border-top: 0px;
   font-weight: 500;
   color: #31719b;
   padding-top: 5px;
   padding-bottom: 5px;
}

table.table.table-striped.tb_gstr1multisumm tr td {
   font-size: 11px;
   font-weight: 500;
   padding: .25rem .75rem;
}

tr.rowdraggrayclr {
   background-color: #f2f2f2;
}

tr.rowlightyellbgclr {
   background-color: #fff8dc;
}

tr.rowlightgrayclr {
   background-color: #fdf4e7 !important;
}

.gstr1totalbgclr {
   background-color: #fff2ff;
   font-weight: 700 !important;
}

tr.totalshadowbox {
   box-shadow: rgb(218 220 224) 1px 0px 0px inset, rgb(218 220 224) -1px 0px 0px inset, rgb(60 64 67 / 30%) 0px 0px 6px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 0px;
   border-bottom: 1px inset rgba(60, 64, 67, 0.15);
   position: relative;
}

td.gstgraybgclr {
   background-color: #dee2e6;
   border-bottom: 1px solid #f2f2f2;
}

.gstr1multim_img_inndev {
   display: block;
}

.gstr1multim_img_leftside {
   float: left;
   padding: 0 5px;
}

.gstr1multim_img_leftside h4 {
   font-size: 1.2rem;
   padding: 0px 5px;
   top: 7px;
   position: relative;
}

.gstr1multim_img_innerhieghtdiv {
   height: 1px;
   width: 100%;
   position: relative;
}

.gstr1multim_img_rightsidediv {
   float: right;
   padding: 0 5px;
}

h4.gstr1multim_img_rightbtnbox {
   top: -2px;
   position: relative;
}

.gstr1multim_img_middleborderline {
   border-top: 1px solid #fc7d72;
   display: flex;
   position: relative;
   margin-top: 18px;
}

h4.gstr1multim_img_rightbtnbox span.emailmultimonth_gstr1btnbgclr button.btn.btn-default.emailannualbtnclr {
   background-color: #31719b;
   color: #fff;
   border: none;
   padding: 5px 12px 7px;
   border-radius: 40px;
   font-size: 12px;
   position: relative;
   top: 1px;
   font-weight: 500;
}

h4.gstr1multim_img_rightbtnbox span.dwonall_multimonth_gstr1btnbgclr a {
   background-color: #31719b;
   color: #fff;
   border: none;
   padding: 5px 12px 7px;
   border-radius: 40px;
   font-size: 12px;
   position: relative;
   top: -1px;
}

/*--end GSTR 1 Multi-Month Summary of Filed Returns table --*/



.impbooksdata_tabbox {
   & nav.impbooksdata_tabbox_navtab {
      padding-top: 4px;
      padding-bottom: 5px;

      & .nav-tabs {
         border-bottom: 0px solid #dee2e6;

         & a.nav-item.nav-link.impbooksdata_tabbox_taball {
            color: #31719b;
            background-color: #ffffff;
            border-color: #31719b;
            outline-color: #31719b !important;
            border-radius: 0px;
            padding: 0.3rem 2.5rem 0.3rem 2.5rem;
            font-size: 12px;
            border: 1px solid #31719b;
            border-right: 0px;
            font-weight: 500;
            text-align: center;
         }

         & a.nav-item.nav-link.impbooksdata_tabbox_tally02_tab {
            border-right: 1px solid #31719b;
         }

         & a.nav-item.nav-link.impbooksdata_tabbox_taball.active {
            color: #ffffff;
            background-color: #fc7d72;
         }
      }
   }


}

.card.tally_cardbox {
   background-color: aliceblue;
   padding: 5px 10px 5px 10px;

   span.tally_config_textfs {
      font-size: 14px;
      font-weight: 700;
   }

   .btn.btn-default.tally_mypc_btnbgclr {
      background-color: #31719b;
      border-color: #31719b;
      padding: 2px 15px 4px 15px;
      border-radius: 40px;
      color: #fff;
      font-size: 12px;
      font-weight: 500;

   }

   & a:hover {
      color: #0056b3;
      text-decoration: none;
   }
}

.btn.btn-default.tally_mypc_btnbgclr {
   background-color: #31719b;
   border-color: #31719b;
   padding: 2px 15px 4px 15px;
   border-radius: 40px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;

}


.card.tallyconfg_card {
   background-color: #efefef;
   padding: 7px 10px;
}

.tally_config_textfs {
   font-size: 12px;
   font-weight: 500;
}

ul.importbooksdt_tally_listbox li {
   line-height: 16px;
   font-size: 12px;
}

span.tally_listbox_checkicon_clrblue {
   color: #31719b;
   font-size: 12px;
   font-weight: 500;
}

p.tally_config_Ginfofs {
   font-size: 14px;
   font-weight: 500;
   font-style: italic;
}

p.seltally_boxbgclr {
   padding: 5px 10px;
   font-weight: 500;
   font-size: 12px;
   background-color: #f6f6f6;
}


/* table.table.table-striped.tally_selmypc_tb {

  & thead tr th {
       font-size: 12px;
       text-align: center;
       border-top: 0;
       font-weight: 500;
       padding-top: 5px;
       padding-bottom: 5px;
   }
   & tr td {
       font-size: 11px;
       font-weight: 500;
       padding: 0.25rem 0.75rem;

       & input.tally_mypc_rediovert {
           vertical-align: middle;
       }
       & td.textclr_red {
           color: red;
       }


       
   }
   span.egcomp_textfs {
       display: none;
   }
   td.onhoverdipaly_egcomp:hover span.egcomp_textfs {
       font-size: 9px;
       display: block;
       text-align: end;
   }

   & tr.tallymypc_row_bgclr_lightyel {
       background-color: #fff9c5;
   }
   & tr.textclr_lightgrey {
       color: #b7b7b7;
   }
   & tr.tallymypc_row_bgclr_grey {
       background-color: #ffe3e6;
   }


} */

button.btn.btn-default.tally_mapbtnbgclr {
   background-color: #31719b;
   color: #fff;
   padding: 0px 15px 1px 15px;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
}

.modal.fade.booksdatamypc_modalwidth.show .modal-dialog {
   max-width: 565px;
}

form.form-row.tallysendreq_form {

   & label {
      font-size: 12px;
      color: #777171;


      & span.red_text {
         color: red;
      }
   }

   & input.tallyinput_fs {
      height: calc(1.2em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 12px;


   }

   & input.tallyinput_fs:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
   }
}

/*============ switch btn toggle css ===========*/

label.switch.autosync_togglebtn {
   position: relative;
   top: 0.21rem;
   width: 23px;
   height: 11px;

   .slider.round {
      border-radius: 7px;
      background-color: #ccc;
   }
}

.switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

.slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #31719b;
   -webkit-transition: .4s;
   transition: .4s;
}

label.switch.autosync_togglebtn {
   .slider:before {
      position: absolute;
      content: "";
      background-color: #fc7d72;
      -webkit-transition: .4s;
      transition: .4s;
      left: 4px;
      height: 7.3px;
      width: 4px;
      top: 2px;
      bottom: 2.1px;
   }

   input:checked+.slider:before {
      left: -10px;
   }

   input {
      &:checked+.slider {
         background-color: #fff;
      }

      &:focus+.slider {
         box-shadow: 0 0 1px #2196F3;
      }

      &:checked+.slider:before {
         -webkit-transform: translateX(26px);
         -ms-transform: translateX(26px);
         transform: translateX(26px);
      }
   }

}





/* Rounded sliders */

.slider.round {
   border-radius: 34px;

   &:before {
      border-radius: 34px;
   }
}

td.textclrblue {
   color: #31719b;
}

/*============ end switch btn toggle css ===========*/


button.btn.btn-default.resend_mailtextfs {
   color: #fff;
   font-size: 12px;
   font-weight: 500;
   background-color: #31719b;
   text-align: center;
   padding: 2px 10px 5px 10px;
   border-radius: 40px;
}

.modal.fade.importbooksdata_popup.booksdatamypc_modalwidth.sendreq_modalbgclr.show {
   background-color: #222222ab;
}

a.unmaptallycomp_textfs {
   color: #31719b;
   font-size: 12px;
   font-weight: 500;
   text-decoration: underline;
}

button.btn.btn-default.askclientupdatedata_bgclr {
   background-color: #31719b;
   border-color: #31719b;
   padding: 0px 10px 0px 10px;
   border-radius: 40px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
}

span.tallyauto_sync_textfs.tallyauto_sync_border {
   background-color: #31719b;
   border-color: #31719b;
   padding: 4px 10px 3px 10px;
   border-radius: 40px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
   top: -1px;
   position: relative;
   cursor: pointer;
}

button.btn.btn-default.unmaptally_btnbgclr {
   font-size: 12px;
   font-weight: 500;
   color: #31719b;
   position: relative;
   top: 4px;
   background-color: #fdd9d7;
   padding: 2px 10px 4px 10px;
   border: 1px solid #a29292;
   border-radius: 40px;
}

span.tallyauto_sync_textfs {
   font-size: 12px;
   font-weight: 500;
   top: 0px;
   position: relative;
   vertical-align: bottom;
}

p.tally_autosync_lastsyn_textfs {
   font-size: 12px;
   font-weight: 500;
}


.modal.fade.clientpc_modalwidth.show {
   background-color: #22222296;
}

.modal.fade.clientpc_modalwidth.show .modal-dialog {
   max-width: 400px;
}

button.btn.btn-default.askclientforupdate_btn {
   background-color: #31719b;
   border-color: #31719b;
   padding: 2px 15px 4px 15px;
   border-radius: 40px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
}


.impbooksdata_tabbox {
   & nav.impbooksdata_tabbox_navtab {
      padding-top: 4px;
      padding-bottom: 5px;

      & .nav-tabs {
         border-bottom: 0px solid #dee2e6;

         & a.nav-item.nav-link.impbooksdata_tabbox_taball {
            color: #31719b;
            background-color: #ffffff;
            border-color: #31719b;
            outline-color: #31719b !important;
            border-radius: 0px;
            padding: 0.3rem 2.5rem 0.3rem 2.5rem;
            font-size: 12px;
            border: 1px solid #31719b;
            border-right: 0px;
            font-weight: 500;
            text-align: center;
         }

         & a.nav-item.nav-link.impbooksdata_tabbox_tally02_tab {
            border-right: 1px solid #31719b;
         }

         & a.nav-item.nav-link.impbooksdata_tabbox_taball.active {
            color: #ffffff;
            background-color: #fc7d72;
         }
      }
   }


}

.card.tally_cardbox {
   background-color: aliceblue;
   padding: 5px 10px 5px 10px;

   span.tally_config_textfs {
      font-size: 14px;
      font-weight: 700;
   }

   .btn.btn-default.tally_mypc_btnbgclr {
      background-color: #31719b;
      border-color: #31719b;
      padding: 2px 12px 2px 12px;
      border-radius: 40px;
      color: #fff;
      font-size: 11px;
      font-weight: 500;

   }

   & a:hover {
      color: #0056b3;
      text-decoration: none;
   }
}


.card.tallyconfg_card {
   background-color: #efefef;
   padding: 7px 10px;
}

.tally_config_textfs {
   font-size: 12px;
   font-weight: 500;
   margin-bottom: 0;
}

ul.importbooksdt_tally_listbox li {
   line-height: 16px;
   font-size: 12px;
}

span.tally_listbox_checkicon_clrblue {
   color: #31719b;
   font-size: 12px;
   font-weight: 500;
}

p.tally_config_Ginfofs {
   font-size: 14px;
   font-weight: 500;
   font-style: italic;
}

p.seltally_boxbgclr {
   padding: 5px 10px;
   font-weight: 500;
   font-size: 12px;
   background-color: #f6f6f6;
}

.fade.mkdmdbox.updatebooks2adatam.book.modal.show {
   max-width: 447px;
   width: 447px !important;
   margin: 5rem auto;
}

.fade.mkdmdbox.updatebooks2adatam.book.modal.show .modal-dialog {
   max-width: 447px;
   width: 447px !important;
   margin: 0px 0px;
}

.fade.mkdmdbox.updatebooks2adatam.book.modal.show .updatebooks2adatah.modal-header {
   padding: 0rem 1rem;
   background-color: #eee;
}

.fade.mkdmdbox.updatebooks2adatam.book.modal.show .modal-header .modal-title.h4 {
   padding: 4px 0px 4px 0px;
   background-color: #eee;
   font-size: 1rem;
   font-weight: 500;

}

table.table.table-striped.tally_selmypc_tb {

   & thead tr th {
      font-size: 12px;
      text-align: center;
      border-top: 0;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
      position: sticky;
      top: 0;
      background: white;
   }

   & tr td {
      font-size: 11px;
      font-weight: 500;
      padding: 0.25rem 0.25rem;

      & input.tally_mypc_rediovert {
         vertical-align: middle;
      }

      & td.textclr_red {
         color: red;
      }
   }

   span.egcomp_textfs {
      display: none;
   }

   td.onhoverdipaly_egcomp:hover span.egcomp_textfs {
      font-size: 9px;
      display: block;
      text-align: end;
   }

   & tr.tallymypc_row_bgclr_lightyel {
      background-color: #fff9c5;
   }

   & tr.textclr_lightgrey {
      color: #b7b7b7;
   }

   & tr.tallymypc_row_bgclr_grey {
      background-color: #ffe3e6;
   }

}

.tallymypc_row_bgclr_lightyel {
   background-color: #fff9c5;
}

.tallymypc_row_bgclr_grey {
   background-color: #ffe3e6;
}

span.egcomp_textfs {
   display: none;
}

td.onhoverdipaly_egcomp:hover span.egcomp_textfs {
   font-size: 9px;
   display: block;
   text-align: end;
}

button.btn.btn-default.tally_mapbtnbgclr {
   background-color: #31719b;
   color: #fff;
   padding: 0px 15px 2px 15px;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
}

.modal.fade.booksdatamypc_modalwidth.show .modal-dialog {
   max-width: 565px;
}

form.form-row.tallysendreq_form {

   & label {
      font-size: 12px;
      color: #777171;


      & span.red_text {
         color: red;
      }
   }

   & input.tallyinput_fs {
      height: calc(1.2em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 12px;


   }

   & input.tallyinput_fs:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
   }
}

body {
   background-color: #ffffff !important;
   font-size: 1rem;
   font-weight: normal;
   line-height: 1.5;
   color: #212529;
   text-align: left;
   -webkit-font-smoothing: antialiased;
   font-weight: 500 !important;
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


p.downloadconnector_textfs {
   font-size: 12px;
   font-weight: 500;
}

.btn.btn-default.downloadconnector_btnbgclr {
   background-color: #31719b;
   color: #fff;
   border: none;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
   padding: 3px 15px 5px 15px;
}

.reloadimgbox {
   text-align: start;
   position: relative;
   top: 0.3rem;
   cursor: pointer;
}

p.step1connect_success_fs {
   font-size: 12px;
   font-weight: 500;
   color: #878787;
}

table.table.table-striped.step1connect_success_tb {

   & thead tr th {
      font-size: 12px;
      text-align: center;
      border-top: 0;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
   }

   & tr td {
      font-size: 11px;
      font-weight: 500;
      padding: 0.25rem 0.75rem;
   }

}

.btn.btn-default.successfully_confbtnbgclr {
   background-color: #31719b;
   color: #fff;
   padding: 2px 15px 2px 15px;
   border-radius: 40px;
   font-size: 11px;
   font-weight: 500;
}

.modal.fade.Configurecomp_popup.show .modal-dialog {
   margin: 3rem auto 0rem auto;

   & .modal-header {
      background-color: #eee;
      padding: 0px 15px;

      & h4.modal-title {
         font-size: 1rem;
         font-weight: 500;
         padding: 4px 0px;
      }

      & button.close {
         top: 2px;
         position: relative;
      }
   }
}

p.Configurecomp_textfs {
   font-size: 12px;
   font-weight: 500;
}

button.btn.btn-default.Configurecomp_probtn {
   background-color: #31719b;
   color: #fff;
   padding: 3px 15px 4px 15px;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
}

button.btn.btn-default.confi_cancelbtn {
   color: #31719b;
   cursor: pointer;
   background-color: #31719b;
   color: #fff;
   padding: 3px 20px 5px 20px;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
}

.btn.btn-default.successfully_confbtnbgclrbtndis {
   background-color: #e0e0e0;
   color: #fff;
   padding: 3px 15px 4px 15px;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
}

.textclr_lightgrey {
   color: #b7b7b7;
}

td.text-red {
   color: red;
}

/*============ switch btn toggle css ===========*/

label.switch.autosync_togglebtn {
   position: relative;
   top: 0.21rem;
   width: 23px;
   height: 11px;

   .slider.round {
      border-radius: 7px;
      background-color: #e6d6d6;
   }
}

.switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

.slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #31719b;
   -webkit-transition: .4s;
   transition: .4s;
}

label.switch.autosync_togglebtn {
   & .slider:before {
      position: absolute;
      content: "";
      background-color: #fc7d72;
      -webkit-transition: .4s;
      transition: .4s;
      left: 2px;
      height: 7.3px;
      width: 4px;
      top: 2px;
      bottom: 2.1px;
   }

   & input:checked+.slider:before {
      left: -10px;
      background-color: green;
   }
}

input {
   &:checked+.slider {
      background-color: #fff;
   }

   &:focus+.slider {
      box-shadow: 0 0 1px #2196F3;
   }

   &:checked+.slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
   }
}

/* Rounded sliders */

.slider.round {
   border-radius: 34px;

   &:before {
      border-radius: 34px;
   }
}

td.textclrblue {
   color: #31719b;
}

/*============ end switch btn toggle css ===========*/


button.btn.btn-default.resend_mailtextfs {
   color: #fff;
   font-size: 12px;
   font-weight: 500;
   background-color: #31719b;
   text-align: center;
   padding: 1px 10px 2px 10px;
   border-radius: 40px;
}

.modal.fade.importbooksdata_popup.booksdatamypc_modalwidth.sendreq_modalbgclr.show {
   background-color: #222222ab;
}

a.unmaptallycomp_textfs {
   color: #31719b;
   font-size: 12px;
   font-weight: 500;
   text-decoration: underline;
}

button.btn.btn-default.askclientupdatedata_bgclr {
   background-color: #31719b;
   border-color: #31719b;
   padding: 1px 10px 2px 10px;
   border-radius: 40px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
}

span.tallyauto_sync_textfs.tallyauto_sync_border {
   background-color: #31719b;
   border-color: #31719b;
   padding: 4px 10px 3px 10px;
   border-radius: 40px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
   top: -1px;
   position: relative;
   cursor: pointer;
}

button.btn.btn-default.unmaptally_btnbgclr {
   font-size: 12px;
   font-weight: 500;
   color: #31719b;
   position: relative;
   top: 4px;
   background-color: #fdd9d7;
   padding: 2px 10px 4px 10px;
   border: 1px solid #a29292;
   border-radius: 40px;
}

span.tallyauto_sync_textfs {
   font-size: 12px;
   font-weight: 500;
   top: 0px;
   position: relative;
   vertical-align: bottom;
}

p.tally_autosync_lastsyn_textfs {
   font-size: 12px;
   font-weight: 500;
}


.modal.fade.clientpc_modalwidth.show {
   background-color: #22222296;
}

.modal.fade.clientpc_modalwidth.show .modal-dialog {
   max-width: 400px;
}

button.btn.btn-default.askclientforupdate_btn {
   background-color: #31719b;
   border-color: #31719b;
   padding: 1px 12px 2px 12px;
   border-radius: 40px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
}

/* start media code -----*/
@media (min-width: 320px) and (max-width: 767px) {
   .gstr1multim_img_leftside h4 {
      font-size: 0.9rem;
   }

   h4.gstr1multim_img_rightbtnbox span.emailmultimonth_gstr1btnbgclr a {
      font-size: 9px;
   }

   h4.gstr1multim_img_rightbtnbox span.dwonall_multimonth_gstr1btnbgclr a {
      font-size: 9px;
   }
}

@media (min-width: 768px) and (max-width: 819px) {
   .gstr1multim_img_leftside h4 {
      font-size: 0.9rem;
   }
}

@media (min-width: 820px) and (max-width: 911px) {
   .gstr1multim_img_leftside h4 {
      font-size: 0.9rem;
   }

   h4.gstr1multim_img_rightbtnbox span.emailmultimonth_gstr1btnbgclr a {
      font-size: 9px;
   }

   h4.gstr1multim_img_rightbtnbox span.dwonall_multimonth_gstr1btnbgclr a {
      font-size: 9px;
   }
}

@media (min-width: 912px) and (max-width: 1024px) {
   .gstr1multim_img_leftside h4 {
      font-size: 0.9rem;
   }

   h4.gstr1multim_img_rightbtnbox span.emailmultimonth_gstr1btnbgclr a {
      font-size: 9px;
   }

   h4.gstr1multim_img_rightbtnbox span.dwonall_multimonth_gstr1btnbgclr a {
      font-size: 9px;
   }

}

.processSync {
   z-index: 999;
   background: white;
   width: 100%;
   padding: 0 20px;
}


.client-status-show {
   display: flex;
   justify-content: flex-start;
   vertical-align: middle;
   line-height: 2rem;
   font-size: 0.7rem;

   span {
      color: #31719b;
      font-size: 0.8rem;
      margin-left: 0.5rem;
   }

}

.progress-sync-popup {
   .progress-bar {
      overflow: visible !important;
   }


   .show-progress-count {
      float: right;
      position: absolute;
      right: 0;
      margin-right: 20px;
   }
}

.tally-alert-box {
   .alert {
      padding: 0.25rem 1.5rem;
   }

   .alert-dismissible .close {
      padding: 0.05rem 0.5rem;
   }
}

.tally_text {
   font-size: 12px;
   margin-bottom: 0;
}

.refreshbtn {
   display: inline-block;
   background: #31719b;
   border-radius: 3px;
   padding: 0 5px;
   color: white;
   cursor: pointer;
   font-size: 11px;

}
.public-page-container{
   border: 1px solid #31719b;
   border-radius: 5px;
   padding-top: 1rem;
   padding-bottom: 1rem;
   margin-top: 1rem;
   max-width: 750px;
}
.tally-port-input{
   height: calc(1.2em + 0.75rem + 2px) !important;
   padding: 0.375rem 0.75rem;
   font-size: 12px !important;
   width: 50% !important;
}
.tally-port-btn{
   background-color: #31719b;
    border-color: #31719b;
    padding: 3px 12px 3px 12px;
    border-radius: 40px;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    box-shadow: none;
    border: none;
}
.port-tooltip {
   position: relative;
   display: inline-block;
   font-size: 13px;
   margin-left: 2px;
 }
 
 .port-tooltip .tooltiptext {
   visibility: hidden;
   width: 350px;
   background-color: black;
   color: #fff;
   text-align: center;
   border-radius: 6px;
   padding: 5px;
   position: absolute;
   z-index: 1;

 }
 
 .port-tooltip:hover .tooltiptext {
   visibility: visible;
 }

 .fade.bookverify.modal.show .modal-dialog{
   transform: none;
   max-width: 580px !important;
   margin: 4rem auto 0rem auto;
}
.bookverify.modal-header {
   padding: 3px 15px;
   background-color: #eee;
}
.bookverify.modal-header .modal-title.h4 {
   font-size: 1.2rem;
}
.bookverifyfooter.fade.alert.alert-danger.show {
   font-size: 11px;
   padding: .3rem;
}

.bookVerifyCard {
   background-color: #fff !important;
   border-radius: 3px !important;
   width: 100% !important;
   padding: 15px !important;
}

button.bookVerifyBtn:hover, button.bookVerifyBtn:active, button.bookVerifyBtn:focus{
   background-color: #fc7d72 !important;
   color: #fff !important;
   border: 1px solid #fc7d72 !important;
}    
.bookverifyfooter {
   padding: 0px 12px;
   border-top: 0px;
}
.bookverifyfooter.button.cancel-btn{
   background-color: #f2f2f2;
   color: #636363;
   border: 1px solid #ddd;
   padding: 5px 15px;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
}
.bookVerifyBtn{
   background-color: #31719b !important;
   color: #fff !important;
   border: 1px solid #31719b !important;
   padding: 5px 22px !important;
   border-radius: 40px !important;
   font-size: 12px !important;
   font-weight: 500 !important;
}
.tolerance-setting button.bookVerifyBtn{
   background-color: #31719b;
   color: #fff;
   border: 1px solid #31719b;
   padding: 4px 20px 6px 20px;
   border-radius: 40px;
   font-size: 12px;
   font-weight: 500;
}

button.cancel-btn{
background-color: #f2f2f2;
color: #636363;
border: 1px solid #ddd;
padding: 5px 22px;
border-radius: 40px;
font-size: 12px;
font-weight: 500;
}

button.cancel-btn:hover, button.cancel-btn:active, button.cancel-btn:focus{
background-color: #f2f2f2 !important;
color: #636363 !important;
border: 1px solid #ddd !important;
}  

.alert-danger-popup {
   color: #721c24;
   background-color: #f8d7da;
   border: 1px solid #f5c6cb;
   padding: 3px 5px 3px 5px !important;
   border-radius: 6px;
   display: inline-block;
   font-size: 11px;
 }