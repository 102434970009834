/* telerik grid css start  */
table{ background-color: #fff;}
.k-grid tr.k-alt {background-color: #fff;}
.k-grid{border-width: 0px;}
.k-grid th {border-width: 0px;}
.k-grid td {border-width: 0px;}
.k-grid-pager {border-width: 0px;}
.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer, .k-pager-wrap{background-color: 'rgb(37, 16, 16)'}
col.k-sorted, th.k-sorted { background-color: transparent;}

/* telerik grid css end  */ 

.supplierbox {
    display: flex;
    float: right;
}
.suppisereco h4 {
    font-size: 1.2rem;
}
.supplierbox h4 {
    font-size: 1.2rem;
   padding: 0.4rem 7rem 0rem 0rem;
}
span.selectyrbox select.form-control.selyr {
    border: 0rem;
    padding: 0.5rem 0.5rem;
	    color: #8bc34a;
}
span.selectyrbox {
    padding-right: 0.5rem;
}
span.suppbariconpadd i {
    padding: 0.7rem 0rem;
    color: #e65100;
}
.suppisereco h4 {
    font-size: 1.2rem;
}
span.recotopbtn button.btn.btn-default.invoicewiserecobtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 16px 6px 16px;
    font-size: 12px;
    float: right;
}
.card.suppborbox {
    border: 1px solid #eee;
    padding: 6px 10px;
	    margin-bottom: 1rem;
}


.table-striped.supplierraisereqtb thead tr th span.filteriocnsize.pt-1 img {
    width: 13px;
}
.table-striped.supplierraisereqtb thead tr th {
    border-top: 0px;
    font-size: 12px!important;
    text-align: center;
    padding: 6px 0px;
    font-weight: 500;
    background-color: #fff;
    vertical-align: baseline;
}
.table-striped.supplierraisereqtb tr td {
    font-size: 12px;
    padding: .25rem .75rem;
    border-top: 1px solid #dee2e6;
    color: #212529;
    font-weight: 500;
}
th.mappingiconlink img {
    width: 16px;
}

/*supplier wise reco*/
.supplistofcomp h4 {
    text-align: center;
    margin-left: -5rem;
    font-size: 1.2rem;
}
/*leftside info box box=====*/

.leftsidesummarypopup {
   position: fixed;
    padding: 0.0rem 0.0rem 0.0rem 0.0rem;
    background-color: #31719b;
    color: #fff;
    z-index: 20;
    top: 50%;
    transform: rotate(270deg);
    border-radius: 0 0 5px 5px;
    left: -5.5rem;
}

span.uploaddataicon {
    display: block;
    transform: rotate(88deg);
    transform-box: fill-box;
    margin-top: 1.4rem;
    top: 0;
    left: 6rem;
    display: none;
    position: absolute;
}
.leftsidesummarypopup a.mb-0.btn, .leftsidesummarypopup span.mb-0.btn {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}
/*
.leftsidesummarypopup:hover {
    transition: all .5s ease .5s;
    -webkit-animation: all .5s ease;
    animation: all .5s ease;
    margin-left: 33px;
}*/

a.backtoclrlistofcomp {
    font-size: 12px;
    padding: 4px 0;
	color: #fc7d72;
	text-decoration:none;
    font-weight: 500;
}

a.backtoclrlistofcomp:hover {
    color:#31719b;
	    text-decoration: none;
}
.selctboxcotg {
    padding: 0.3rem 0px;
}
button.btn.btn-primary.annualbtnbox {
    border-radius: 0;
    padding: .2rem 1.9rem;
    color: #f0f8ff;
    background-color: #fc7d72;
    border: 0;
    outline-color: #fc7d72;
    font-size: 14px;
    FONT-WEIGHT: 500;
    width: 30%;
    margin-left: 3.5rem;
    top: 0px;
    border: 1px solid rgb(206, 212, 218);
}

.btn-group.annualbtngp {
    left: -3.4rem;
}
button.btn.btn-primary.annualbtnbox:hover {
	background-color: #31719b;

}

button.btn.btn-primary.annualbtnbox_disabled {
    border-radius: 0px;
        padding: 0.2rem 1.9rem;
        color: #212529;
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        border: 0px;
        outline-color: #f2f2f2;
        font-size: 14px;
        FONT-WEIGHT: 500;
        width: 30%;
        top: 0px;
        margin-left: 3.5rem;
    }
    button.btn.btn-primary.annualbtnbox_disabled:hover {
        background-color: #31719b;
        color: #fff;
    
    }

    button.btn.btn-primary.annualbtnbox_disabled {
        background-color: #fff;
        border-right: 0px solid rgb(206, 212, 218);
        color: rgb(170, 170, 170);
        border-top: 1px solid rgb(206, 212, 218);
        border-bottom: 1px solid rgb(206, 212, 218);
        border-left: 1px solid rgb(206, 212, 218);
    }
    .dropdown-heading-value {color: #fc7d72 !important;}
        
/*multi drowdown sel */
.selctboxcotg .multi-select .dropdown div {
    border-radius: 0px !important;
    font-size: 14px;
    font-weight: 500;
    
}
    .selctboxcotg .multi-select {
        width: 30%;
        margin-right: -1px;
    }
    .selctboxcotg .multi-select .dropdown div ul.select-list li label.select-item {
        padding: 0px 5px 2px 7px!important;
        margin: 0px 0px;
    }
    .selctboxcotg .multi-select .dropdown div ul.select-list li label.select-item span.item-renderer {
        vertical-align: sub;
    }
    .selctboxcotg .multi-select .dropdown div ul.select-list li label.select-item span.item-renderer input[type="checkbox"] {
        vertical-align: middle;
    }
    .selctboxcotg .multi-select .dropdown div span.dropdown-heading-value {
        text-align: center;
    }
 
/* end multi drowdown sel */


.form-control.selectboxquaterly {
    border-radius: 0px;
    border-color: #31719b;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    FONT-WEIGHT: 500;
    width: 275px;
}
.form-control.selectboxmonthly {
    border-radius: 0px;
    border-color: #31719b;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    FONT-WEIGHT: 500;
}

/* update books 2a data css mobel box ================*/
.updatebooks2adatam.book {
    margin: 54px 0px;
    width: 350px;
}
#updatebooks2adatam {
    margin: 54px 0px;
    width: 350px;
}
.modal-header.updatebooks2adatah {
    padding: 0.5rem 1rem;
    background-color: #eee;
}
.modal-header.updatebooks2adatah h4.modal-title {
    font-size: 1rem;
}
.refheadinnerbox {
    text-align: left;
}
.refheadinnerbox .ref2adata {
    font-size: 12px;
    padding: 0rem 1rem;
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
}
.refheadinnerbox .downloadbookstem {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
}
.refheadinnerbox .ref2adata:hover {
	text-decoration: none;
}
.refheadinnerbox .downloadbookstem:hover {
	text-decoration: none;
}
.card.updatebooks2a {
    padding: 0.2rem 0.5rem;
}
table.table.table-striped.updatebooks2adataleftsidepopup thead tr th {
    border-top: 0px;
    font-size: 12px!important;
    text-align: center;
    padding: 0px 0px;
    font-weight: 600;
}
.k-grid-header .k-grid-header-wrap {
    background-color: #fff;
}

table.table.table-striped.updatebooks2adataleftsidepopup tr td {
    font-size: 11px;
    padding: .25rem .75rem;
}

.donebtnfoot span.ref2adata.btn.btn-default.btnrefreshdata {
    text-align: center;
    background-color: #fc7d72;
    color: #fff;
    border: 1px solid #fc7d72;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 5px 15px;
}
span.ref2adata.btn.btn-default.btnrefreshdatadone {
    background-color: #31719b;
    border: 1px solid #31719b;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
    padding: 5px 38px;
    color: #fff;
}
.donebtnfoot {
    text-align: center;
}

/* B2b check box css*/

.selctboxcotg.b2bselbox {
    font-size: 13px;
}

.b2bcontent h4 {
    font-size: 1.2rem;
    padding-top: 3px;
}

.card.cardborinnerbox {
    border-radius: 0px;
}
.table-striped.supplierraisereqtb.gstr2atb thead tr th {
    border-top: 0px;
    font-size: 11.5px!important;
    text-align: center;
    padding: 6px 0px;
    font-weight: 600;
}

.table-striped.supplierraisereqtb.gstr2atb tr td {
    font-size: 11px;
}

/*overflow tooltip---------*/
div#viewdetailspopup .modal-dialog {
    float: right;
    margin: auto 0px;
}
.table-striped.supplierraisereqtb tr td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: revert;
    
}

.showmorebox a, .showmorebox span {
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    color: #31719b;
}

.footinfobox span.lefttext {
    float: left;
    margin-top: 0.5rem;
    font-size: 14px;
}
.footinfobox span.righttext {
    float: right;
}
.footinfobox span.righttext a.btn.btn-default.importnowbtn {
    font-size: 14px;
    color: #31719b;
}
.footinfobox span.righttext a.btn.btn-default.dismissbtn {
    font-size: 14px;
	 color: #31719b;
}
/*====== col padd css===========*/




/*=======view details ==========*/
.titleviewdetails {
    display: contents;
}
.viewdgstno h4 {
    font-size: 1rem;
}
.viewddonebtn a.btn.btn-default.viewddbtn {
    background-color: #31719b;
    color: #fff;
    border: 1px solid #31719b;
    border-radius: 3px;
    text-align: center;
    outline-color: #31719b;
    padding: 0.3rem 1.5rem;
    font-size: 15px;
}

.invoicewisereco h4 {
    font-size: 1.2rem;
    text-align: center;
    padding-right: 14rem;
}
button.btn.btn-primary.invoicewiserecobtn {
    border-radius: 0px;
    padding: 0.2rem 1.8rem;
    color: #333;
    background-color: #fff;
    border-color: #31719b;
    outline-color: #31719b;
    font-size: 14px;
    FONT-WEIGHT: 500;
    border: 1px solid #31719b;
}

.form-control.selectboxmonthly.monthlyactive {
    background-color: #e57373;
    border-color: #e57373;
    border: 0px;
    outline-color: #e57373;
    color: #fff;
}

.overflowsuppname {cursor: pointer;}
.chart{margin-left: 1%; cursor: pointer;}
.chart_title {width: 100%;}
.chart_title div{display: inline-block; padding: 0px 10px;}
.chart {display: inline-block; width: 25%; text-align: center; padding: 7px 0px;}
.k-grid-header .k-header > .k-link {
    color: #212529;
    font-weight: 500;
    font-size: 12px;
}
.k-grid td {
   font-size: 11px;
}
.k-pager-wrap.k-pager.k-widget.k-grid-pager {
    background-color: #fff;
}
.link {cursor: pointer; color: #31719b !important;}
.link:hover{text-decoration: underline;}

.link1 {cursor: pointer; color: #31719b}
.link1:hover{text-decoration: underline;}

.matched {color: #8bc34a !important;}
.mis_matched {color: #f6594f !important;}
.in_only {color: #33691e !important;}
.in_books_only {color: #c74d4d !important;}

.browse_file {width: 14px; cursor: pointer; margin-left: 5px;}

.fade.bookVerify.modal.show .modal-dialog{
    transform: none;
    /* width: 35%; */
    max-width: 460px;
    margin: 4rem auto 0rem auto;
}
.bookVerify.modal-header {
    padding: 3px 15px;
    background-color: #eee;
}
.bookVerify.modal-header .modal-title.h4 {
    font-size: 1.2rem;
}
.bookVerify.modal-footer .fade.alert.alert-danger.show {
    font-size: 11px;
    padding: .3rem;
}

.fade.tolerance-setting.modal.show .modal-dialog{
    transform: none;
    width: 22%;
    max-width: 700px;
    margin: 4rem auto 0rem auto;
}

.negative_amt {color: #f6594f !important;}


button.bookVerifyBtn {
        background-color: #fc7d72;
        color: #fff;
        border: 1px solid #fc7d72;
        padding: 5px 25px;
        font-size: 15px;
        font-weight: 500;
        border-radius: 40px;
    }
button.bookVerifyBtn:hover, button.bookVerifyBtn:active, button.bookVerifyBtn:focus{
        background-color: #fc7d72 !important;
        color: #fff !important;
        border: 1px solid #fc7d72 !important;
    }    
    .bookVerify.modal-footer {
        padding: 0px 12px;
        border-top: 0px;
    }
    .bookVerify.modal-footer button.light-cancel-btn{
        background-color: #f2f2f2;
        color: #636363;
        border: 1px solid #ddd;
        padding: 5px 15px;
        border-radius: 40px;
        font-size: 12px;
        font-weight: 500;
    }
    .bookVerify.modal-footer button.bookVerifyBtn{
        background-color: #31719b;
        color: #fff;
        border: 1px solid #31719b;
        padding: 4px 20px 6px 20px;
        border-radius: 40px;
        font-size: 12px;
        font-weight: 500;
    }
    .tolerance-setting button.bookVerifyBtn{
        background-color: #31719b;
        color: #fff;
        border: 1px solid #31719b;
        padding: 4px 20px 6px 20px;
        border-radius: 40px;
        font-size: 12px;
        font-weight: 500;
    }

button.light-cancel-btn{
    background-color: #f2f2f2;
    color: #636363;
    border: 1px solid #ddd;
    padding: 5px 25px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 500;
}

button.light-cancel-btn:hover, button.light-cancel-btn:active, button.light-cancel-btn:focus{
    background-color: #f2f2f2 !important;
    color: #636363 !important;
    border: 1px solid #ddd !important;
}  

.bookVerifyCard {
    background-color: #fff;
    border-radius: 3px;
    width: 100%;
    padding: 15px;
}
  .importforbox .innerimp {
    font-size: 10px;
    color: #9e9e9e;
    float: left;
}
  .innerimp {
    font-size: 10px;
    color: #9c9c9c;
    text-align: right;
}
.supplier_mail {
    width: 22px;
    text-align: right;
    padding-top: 2px;
    font-size: 25px;
    color: #fc7d72;
    float: right;
}
.k-widget.k-grid.table-striped.supplierraisereqtb.remove_sortclass.table-responsive .k-grid-header {
    background-color: #fff;
}
.remove_sortclass .k-i-sort-asc-sm {display: none;}
.remove_sortclass .k-i-sort-desc-sm {display: none; }
.remove_sortclass .active_sort {display: inline;}

span.cleckhereref {
    font-size: 12px;
    color: rgb(49, 113, 155);
    cursor: pointer;
}
button.btn.btn-primary.by2abtn.by2abors {
    border-top: 1px solid #31719b !important;
    border-bottom: 1px solid #31719b !important;
    border-right: 0px solid #31719b !important;
    border-left: 1px solid #31719b !important;
}
button.btn.btn-primary.by2abtn.by2bbtnbor {
    border-top: 1px solid #31719b !important;
    border-bottom: 1px solid #31719b !important;
    border-right: 1px solid #31719b !important;
    border-left: 0px solid #31719b !important;
}
button.btn.btn-primary.by2abtn {
    border-radius: 0px;
    padding: 0.1rem 1rem 0.2rem 1rem;
    color: #fff;
    background-color: #fc7d72;
    border-color: #f2f2f2;
    border: 1px solid #31719b;
    outline-color: #fc7d72;
    font-size: 14px;
    FONT-WEIGHT: 500;
}
button.btn.btn-primary.by2bbtn {
    border-radius: 0px;
    padding: 0.1rem 1rem 0.2rem 1rem;
    color: #31719b;
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    font-weight: 500;
}

.k-widget.k-grid.table-striped.supplierraisereqtb.remove_sortclass.table-responsive thead tr th span.k-icon.k-i-sort-asc-sm.active_sort {
    position: absolute;
    top: 8px;
    right: 8px;
}
.k-widget.k-grid.table-striped.supplierraisereqtb.remove_sortclass.table-responsive thead tr th span.k-icon.k-i-sort-desc-sm.active_sort {
    position: absolute;
    top: 8px;
    right: 8px;
}
.k-widget.k-grid.table-striped.supplierraisereqtb .k-grid-content.k-virtual-content {
  overflow-y: scroll;
  max-height: 360px;
}


.party-wise-detail .k-widget.k-grid.table-striped.supplierraisereqtb .k-grid-content.k-virtual-content {
    max-height: 515px !important;
  }

label {
    display: inline-block;
    margin-bottom: .5rem;
    font-size: 12px;
}
.selctboxcotg .multi-select .dropdown div {
    border-radius: 0!important;
    font-size: 14px;
    font-weight: 500;
}

/* maildropwdown css */
.btn-group.mailiconalignright {
    display: inline-block;
}
.supplier_mail.sendemail.mailicondrop {
    width: 22px;
    text-align: right;
    padding-top: 2px;
    font-size: 25px;
    color: #fc7d72;
    float:right;
}
ul.sendmaildrop li {
    list-style: none;
}
ul.sendmaildrop li a.nav-link.dropdown-toggle::after {
    display: none;
}
ul.sendmaildrop li .dropdown-menu.show {
    margin-top: 1.8rem;
    transform: translate3d(582px, 18px, 0px) !important;
    padding: 0px !important;
}
ul.sendmaildrop li a.dropdown-item {
font-size: 11px;
    padding: 0.3rem 0.5rem;
    line-height: 19px;
    border-bottom: 1px solid #eee !important;
    font-weight: 500;
}
ul.sendmaildrop li a.dropdown-item:hover {
        background-color: #eaeaea;
        color: #222;
}
ul.sendmaildrop li a.dropdown-item:last-child {
    border-bottom: 0px solid #eee;
}
ul.sendmaildrop li a.dropdown-item.overflowsendmail {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: pointer;
}


.dropdown-menu.emaildropdlist.dropdown-content {
   
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    padding: 12px 0px;
    z-index: 1;
}

/* end maildropwdown css */

.card.cardsuppsendtosupp .k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer {
    background-color: #ffffff;
}
span.k-icon.k-i-more-vertical {
    left: 5px;
    font-size: 13px;
}

button.btn.btn-default.supplwisereco_mailbtn {
    top: 9px;
    position: relative;
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 2px 16px 2px 16px;
    font-size: 12px;
    font-weight: 500;
}
button.btn.btn-default.supplwisereco_tpsupp_btn {
    top: 9px;
    position: relative;
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 2px 16px 2px 16px;
    font-size: 12px;
    font-weight: 500;
}
span.compmailicon_clr {
    color: #fff !important;
    font-size: 16px;
    vertical-align: middle;
    top: -1px;
    position: relative;
}

/*======= media code ==============*/
@media (max-width: 767px){
	.supplierbox h4 {
		font-size: 0.8rem;
		padding: 0.9rem 0rem 0rem 0rem;
	}
	span.recotopbtn {
		float: right;
	}
	.supplistofcomp h4 {
		text-align: center;
		margin-left: 0rem;
	}
    div#updatebooks2adatam .modal-dialog {
        
        width: auto;
    }
    .updatebooks2adatam.book{
        
        width: auto;
    }



}

@media (max-width: 421px){
    .selctboxcotg .multi-select {
        margin-right: -1px;
        min-width: 30%;
    }
}
@media (min-width: 422px) and (max-width: 767px){
    .selctboxcotg .multi-select {
        margin-right: -1px;
        min-width: 35%;
    }
}
@media (min-width: 768px) and (max-width: 940px){
	.supplierbox h4 {
		font-size: 1.2rem;
		padding: 0.4rem 2rem 0rem 0rem;
	}
	span.recotopbtn {
		float: right;
	}
	.supplistofcomp h4 {
		text-align: center;
		margin-left: 4rem;
		font-size: 1.2rem;
	}
	

}
@media (min-width: 941px) and (max-width: 1024px){
	.supplierbox h4 {
		font-size: 1.2rem;
		padding: 0.4rem 2rem 0rem 0rem;
	}
	span.recotopbtn {
		float: right;
	}
	.supplistofcomp h4 {
		text-align: center;
		margin-left: 0rem;
		font-size: 1.2rem;
	}

}

/*======= Announcements code ==============*/
@-webkit-keyframes fadeInOut {
    0% {
        Opacity: 0;
    }

    100% {
        Opacity: 1;
    }
}

@keyframes fadeInOut {
    0% {
        Opacity: 0;
    }

    100% {
        Opacity: 1;
    }
}

.updatebooks2adatah.modal-header .modal-title.h4 {
    font-size: 1.2rem;
}
.active_announcement {
    overflow: hidden;
}
.active_announcement a.mb-0.btn, .active_announcement span.mb-0.btn {
    padding: 0.375rem 2.5rem 0.375rem 1.2rem !important;
}

.AnnouncementsIcon{
    position: absolute;
    top: 0px;
    right: -16px;
    transform: rotate(90deg);
    cursor: pointer;
}

.Announcements {background-color: #ee7b70;
    width: 55px;
    position: relative;
    padding-left: 26px;
    padding-top: 2px;}

.Announcements-wrapper>.Dropdown-toggle:before {
    display: none;
}

.Dropdown.Announcements-wrapper .Announcements-bubble {
    display: none;
    width: 24px;
    height: 26px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble {
    box-sizing: border-box;
    overflow: hidden;
    transform: rotate(15deg);
    border-radius: unset;
    display: block;
    position: absolute;
    padding: 0px;
    top: -10px;
    background-color: transparent;
    left: 40px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble:before {
    content: '';
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    width: 10px;
    height: 10px;
    -webkit-animation: fadeInOut 1s infinite .2s;
    animation: fadeInOut 1s infinite .2s;
    left: -4px;
    bottom: -4px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble:after {
    content: '';
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    width: 16px;
    height: 16px;
    -webkit-animation: fadeInOut 1s infinite .4s;
    animation: fadeInOut 1s infinite .4s;
    left: -6px;
    bottom: -6px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .i-volume {
    color: #fff;
}

.Dropdown.Announcements-wrapper.has-unseen-items .i-volume:before {
    content: "\f103";
}

.selectedItem{
    background-color: #fc7d72; color: #fff; 
}

.notSelectItem{
    background-color: #fff; color: #666666; 
}
.notSelectItem:hover{
    background-color: #fc7d72; color: #fff; 
}
/*======= Announcements code ==============*/





.modal.messageimportmod.show .modal-dialog {
    margin: 4rem auto 1rem;
    width: 27rem;
}

.modal-header.messgimportheader button.close:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.sadicon svg {
    font-size: 16px;
    color: red;
    margin-left: 5px;
}

.modal-header.messgimportheader.case2 {
    padding: 0px 11px 4px 11px;
    background-color: #eee0;
    border-bottom: 0px solid #dee2e6;
}

td.importstatus_tboverflowtextbox {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 120px;
}

table.importst_innertbheigtfixed {
    width: 100%;
}

table.importst_innertbheigtfixed tbody {
    display: block;
    max-height: 193px;
    overflow-x: auto;
}

table.importst_innertbheigtfixed tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

table.table.table-striped.messageafterimporttb thead tr th {
    border-top: 0;
    font-size: 12px !important;
    text-align: center;
    padding: 6px 0;
    font-weight: 500;

}

table.table.table-striped.messageafterimporttb tr td {
    font-size: 11px;
    padding: .25rem .75rem;
    border-top: 1px solid #dee2e6;
    color: #212529;
    font-weight: 500;
}
table.table.table-striped.filingfrq tr td {
    font-size: 12px !important;
}

.modal-header.messgimportheader {
    padding: 1px 16px 5px 16px;
    background-color: #eee;
}

.modal-header.messgimportheader button.close {
    top: 1px;
    position: relative;
}

.messgimportheader.modal-header h4.modal-title {
    font-size: 1.2rem;
}

h4.importsatfs {
    font-size: 1rem;
    padding-top: 2px;
}

td.succicongreenclr svg {
    color: green;
    font-size: 16px;
    padding: 0px 2px;
    margin-left: 5px;
}

p.ctareimptext {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
}

.card.smspopupcard {
    border: 1px solid rgba(0, 0, 0, .125);
}


.impbooksdata_tabbox {
    & nav.impbooksdata_tabbox_navtab {
        padding-top: 4px;
        padding-bottom: 5px;
 
        & .nav-tabs {
            border-bottom: 0px solid #dee2e6;
 
            & a.nav-item.nav-link.impbooksdata_tabbox_taball {
                color: #31719b;
                background-color: #ffffff;
                border-color: #31719b;
                outline-color: #31719b !important;
                border-radius: 0px;
                padding: 0.3rem 2.5rem 0.3rem 2.5rem;
                font-size: 12px;
                border: 1px solid #31719b;
                border-right: 0px;
                font-weight: 500;
                text-align: center;
            }
            & a.nav-item.nav-link.impbooksdata_tabbox_tally02_tab {
                border-right: 1px solid #31719b;
            }
 
            & a.nav-item.nav-link.impbooksdata_tabbox_taball.active {
                color: #ffffff;
                background-color: #fc7d72;
            }
        }
    }
    
    
 }

 /*================header toggl btn css========================*/
label.switch.htoggleswit1 {
    margin-bottom: -0.4rem;
  }
  
  /*================header toggl btn css========================*/
  label.switch.htoggleswit1 {
    margin-bottom: -0.4rem;
  }
  
  span.headertogglebtn {
    top: 4.6px;
    position: relative;
    right: 1.2rem;
  }
  
  .switch.htoggleswit1 {
    position: absolute;
    display: inline-block;
    width: 23px;
    /* 60*/
    height: 11px;
    /*34 */
  }
  
  .switch.htoggleswit1 input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  
  label.switch.htoggleswit1 .slider {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #28a745;
    transition: .4s;
  }
  
  label.switch.htoggleswit1 .slider:before {
    position: absolute;
    content: "";
    height: 7.3px;
    width: 3px;
    top: 2px;
    left: 3px;
    background-color: rgb(242 242 242);
    transition: .4s;
    outline-color: rgb(242 242 242);
  }
  
  label.switch.htoggleswit1 input:checked+.slider {
    background-color: #cccbcb;
  }
  
  /* label.switch.htoggleswit1 input:focus + .slider {
    box-shadow: 0 0 1px #31719b;
  } */
  
  label.switch.htoggleswit1 input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -9px;
  }
  
  /* Rounded sliders */
  label.switch.htoggleswit1 .slider.round {
    border-radius: 34px;
  }
  
  label.switch.htoggleswit1 .slider.round:before {
    border-radius: 16%;
  }
  
  /*================end header toggl btn css========================*/


  /*----- REco books Vs 2b status modal css  -----*/
  .card.supprecobook_vs_2ast_cardbox {
    padding: 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    top: -8px;
    margin-top: 0.2rem;
    }
    h4.supprecobook_vs_2ast_titlefs {
        font-size: 16px;
        font-weight: 500;
        color: #31719b;
        text-align: center;
        margin-bottom: 1px;
    }
    p.supprecobook_vs_2ast_bookstitlefs {
        font-size: 12px;
        font-weight: 500;
        color: #1170c3;
        margin-bottom: 5px;
    }
    span.supprecobook_vs_2ast_booksnoclr {
        font-weight: 700;
        padding-left: 6px;
        color: #fc7d72;
    }


    table.table.table-striped.supprecobook_vs_2ast_tb thead tr th {
        font-size: 12px;
        text-align: center;
        border-top: 0px;
        font-weight: 500;
        padding-top: 3px;
        padding-bottom: 3px;
        color: #fff;
    }

    table.table.table-striped.supprecobook_vs_2ast_tb tr td {
        font-size: 11px;
        font-weight: 500;
        padding: .25rem .75rem;
        
    }
    tr.supprecobook_vs_2ast_bookheaderbgclr {
        background-color: #31719b;
    }
    table.table.table-striped.supprecobook_vs_2ast_tb tr td.textclrcoral {
        color: #fc7d72;
    }



/* .gstr_supprecobooks_rightsidedrawerbtn {
    position: fixed;
    padding: 0 .4rem .1rem .2rem !important;
    background-color: #31719b;
    color: #fff;
    z-index: 20;
    top: 45%;
    transform: rotate(270deg);
    border-radius: 5px 5px 0 0;
    overflow: visible;
    right: -6.5rem;
    transition: all 1.25s ease-in-out;
    cursor: pointer;
    min-width: 250px;
    width: auto;
    max-width: 200px;
    margin-right: 0;
    text-align: center;
} */

 button.btn.btn-default.gstr_supprecobooks_rightsidedrawerbtn_bgclr {
    color: #fff !important;
    font-size: 15px;
    font-weight: 600;
    padding: 5px 10px 10px 10px
}

button.btn.btn-default.gstr_supprecobooks_rightsidedrawerbtn_bgclr:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0,123,255,.25)
}

 span.show_summary_rs_textfs {
    font-size: 12px;
    color: rgb(59, 113, 134);
    font-weight: 600 !important;   
}

 span.gstr_supprecobooks_rightsidedrawerbtn_handcursor_icon img {
    transform: rotate(90deg);
    max-width: 40px;    
    padding: 0px 15px 0 8px;
    cursor: pointer;
}

hr.line-add {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
 }


/* --------- right view sumamry drawer box ---------*/

.suppinvoice_reco2a2bst_rightdrawerbox {
    position: fixed;
    padding: 0.1rem 0.5rem 0.5rem 0.3rem !important;
    background-color: #31719b;
    color: #fff;
    z-index: 20;
    top: 50%;
    transform: rotate(270deg);
    border-radius: 5px 5px 0px 0px;
    right: -7.0rem;
    overflow: hidden;
    -webkit-transition: all 1.25s ease-in-out;
    -moz-transition: all 1.25s ease-in-out;
    -o-transition: all 1.25s ease-in-out;
    transition: all 1.25s ease-in-out;
    cursor: pointer;
    animation: rightrecostaimat;
    animation-duration: 3s;
    animation-iteration-count:infinite;
    animation-timing-function: ease-in;
    animation-direction: alternate-reverse;
}

.suppinvoice_reco2a2bst_rightdrawerbox .Announcements {
    background-color: #ee7b70;
    width: 55px;
    position: relative;
    padding-left: 18px;
    padding-top: 2px;
}
    .suppinvoice_reco2a2bst_rightdrawerbox .Announcements-bubble {
        box-sizing: border-box;
        overflow: hidden;
        transform: rotate(15deg);
        border-radius: unset;
        display: block;
        position: absolute;
        padding: 0px;
        top: -11px !important;
        background-color: transparent;
        left: 33px !important;
    }

    .suppinvoice_reco2a2bst_rightdrawerbox span.mb-0.btn {
        color: #fff !important;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 24px 3px 10px;

        
    }
    span.uploaddataicons {
        display: block;
        transform: rotate(88deg);
        transform-box: fill-box;
        margin-top: 1.4rem;
        top: 0;
        left: 6rem;
        display: none;
        position: absolute;
    }


    
.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble:before {
    content: '';
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    width: 10px;
    height: 10px;
    -webkit-animation: fadeInOut 1s infinite .2s;
    animation: fadeInOut 1s infinite .2s;
    left: -4px;
    bottom: -4px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .Announcements-bubble:after {
    content: '';
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    width: 16px;
    height: 16px;
    -webkit-animation: fadeInOut 1s infinite .4s;
    animation: fadeInOut 1s infinite .4s;
    left: -6px;
    bottom: -6px;
}

.Dropdown.Announcements-wrapper.has-unseen-items .i-volume {
    color: #fff;
}




@keyframes rightrecostaimat {
    0%   {right: -5.7rem; right: -5.7rem;}
    100%  {right: -7.1rem; right: -7.1rem;}
  }

  .suppinvoice_reco2a2bst_rightdrawerbox:hover {
    right: -5.7rem !important;
    -webkit-transition: all 1.25s ease-in-out;
    -moz-transition: all 1.25s ease-in-out;
    -o-transition: all 1.25s ease-in-out;
    transition: all 1.25s ease-in-out;
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
    cursor: pointer;
}
