body{   
    background-color: #ffffff!important;
     font-size: 1rem;
     font-weight: normal ;
     line-height: 1.5;
     color: #212529;
     text-align: left;
     -webkit-font-smoothing: antialiased;
     font-weight: 500 !important;
     font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
 }

 .fade.searchGstin_linkedmodal.modal.show {
    background-color: #2222229c;
}
.fade.searchGstin_linkedmodal.modal.show .modal-dialog {
    margin: 5rem auto 0rem auto;
    max-width: 750px;
}
.searchGstin_linked_header.modal-header {
    background-color: #eee;
    padding: 0px 15px;
}
.searchGstin_linked_title.modal-title.h4 {
    width: 100%;
}
.searchGstin_linked_title h4 {
    padding: 0px 0px 0px 0px;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0px;
    color: #000000;
}
.k-widget.k-grid.table.table-striped.searchGstin_linked_table thead tr th {
    border-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}
td.findgstin_overflowtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 250px;
}

.searchGstin_linked_header.modal-header button.close {
    position: relative;
    top: 3px;
}
table.table.table-striped.searchGstin_linked_table thead tr th {
    font-size: 12px;
    text-align: center;
    border-top: 0;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
}
table.table.table-striped.searchGstin_linked_table tr td {
    font-size: 11px;
    font-weight: 500;
    padding: .25rem .75rem;
}
.searchGstin_linked_tbtextcle_coral {
    color: #fc7d72 !important;
}

.searchGstin_linked_head_textclr {
    color: rgb(49, 113, 155);
}

.k-widget.k-window.searchGstin_linkedmodal .k-window-titlebar.k-dialog-titlebar {
    padding: 7px 11px;
    background-color: #eee !important;
}

/* .k-widget.k-window.searchGstin_linkedmodal .k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order {
    position: absolute;
    bottom: 26px;
    right: 75px;
} */

.k-widget.k-window.searchGstin_linkedmodal .k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-grid-header .k-sort-order {
    position: absolute;
    bottom: 26px;
    right: 75px;
}

.k-widget.k-window.searchGstin_linkedmodal .k-button:hover,
.k-button.k-state-hover {
  background-color: #eee !important;
  border-color: #eee !important;
  color: #000000 !important;
  background-image: none !important;
}