
table.importst_innertbheigtfixed {
  width: 100%;
}
table.importst_innertbheigtfixed tbody {
  display: block;
  max-height: 193px;
  overflow-x: auto;
}
table.importst_innertbheigtfixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.table.table-striped.messageafterimporttb thead tr th {
  border-top: 0;
  font-size: 12px!important;
  text-align: center;
  padding: 6px 0;
  font-weight: 500;

}

table.table.table-striped.messageafterimporttb tr td {
   font-size: 11px;
  padding: .25rem .75rem;
  border-top: 1px solid #dee2e6;
  color: #212529;
  font-weight: 500;
}
.modal-header.messgimportheader {
  padding: 1px 16px 5px 16px;
  background-color: #eee;
}
.modal-header.messgimportheader button.close {
  top: 1px;
  position: relative;
}
.messgimportheader.modal-header h4.modal-title {
  font-size: 1.2rem;
}
h4.importsatfs {
  font-size: 1rem;
  padding-top: 2px;
}
td.succicongreenclr svg {
  color: green;
  font-size: 16px;
    padding: 0px 2px;
    margin-left: 5px;
}
p.ctareimptext {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}
.card.smspopupcard {
  border: 1px solid rgba(0,0,0,.125);
}
.modal.messageimportmod.show .modal-dialog{
  margin: 4rem auto 1rem;
    width: 27rem;
}
.modal-header.messgimportheader button.close:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.sadicon svg {
  font-size: 16px;
  color: red;
  margin-left: 5px;
}
.modal-header.messgimportheader.case2 {
  padding: 0px 11px 4px 11px;
  background-color: #eee0;
  border-bottom: 0px solid #dee2e6;
}
button.btn.btn-default.reimportbtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 28px;
  color: #fff;
  padding: 4px 15px 5px 15px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
}
button.btn.btn-default.okaybtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 28px;
  color: #fff;
  padding: 4px 22px 5px 22px;
  font-size: 12px;
  font-weight: 500;
}
td.importstatus_tboverflowtextbox {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 120px;
}


/*============start media code=============*/

@media (min-width: 768px) and (max-width: 940px){
.modal.messageimportmod.show .modal-dialog {
  margin: 8rem auto 1rem;
}
}
@media (min-width: 942px) and (max-width: 1024px){
.modal.messageimportmod.show .modal-dialog {
  margin: 8rem auto 1rem;
}
}
