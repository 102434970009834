body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.modal.updatecreaditpopup.show .modal-dialog {
  margin: 4rem auto 0rem;
  max-width: 400px;
}
.modal-header.updatecreaditpopuph {
  padding: 3px 7px;
  background-color: #eee;
}
h4.updatecreadittile {
  font-size: 14px;
  padding: 5px 5px 0px 5px;
  color: #656565;
  font-weight: 600;
}
button.btn.btn-default.updatecredsubbtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 28px;
  color: #fff;
  padding: 4px 15px 6px 15px;
  font-size: 12px;
  font-weight: 500;
}
.input-group-prepend.updatecredisysicon {
  margin-right: -1px;
  padding: 7px 5px 6px 5px;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #31719b;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
::placeholder {
  color: #ced4da;
  font-size: 12px;
}
.form-group label {
  font-size: 12px;
  color: #777171;
}
span.red_star {
  color: #ff2c39;
}
p.providcredenttextfs {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
}
span.credcompnameo.cmapnameoverflowtext {
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}
span.gstnnotexfs {
  position: relative;
  top: -3px;
}
span.gstnunamefs {
  font-size: 11px;
  font-weight: 400;
}
span.gunametextblod {
  font-weight: 600;
}
.modal-header.updatecreaditpopuph button.close {
  position: relative;
  top: 3px;
}
.modal-header.updatecreaditpopuph button.close {
  position: relative;
  top: 3px;
}
.modal-header.updatecreaditpopuph button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
input.form-control.credinputtextfs {
  font-style: italic;
  padding: 0px 11px 5px 11px;
  height: calc(1em + 1rem + 0px) !important;
}
.modltitlecls {
  color: #b1abab;
  font-size: 13px;
  top: -2px;
  position: relative;
}
p.readcaptcha_textfs {
  font-size: 12px;
  font-weight: 500;
}
.reloadcaptcha {
  cursor: pointer;
  color: #31719b;
}
.uPdcursorPointer {
  cursor: pointer;
}
.input-group-prepend.eyeicon_toppadd {
  padding: 8px 7px 2px 7px !important;
}
input.form-control.inputheight_padd {
  padding: 0px 11px 5px 11px;
  height: calc(1em + 1rem + 0px) !important;
  font-style: italic;
}
.captcha_img.captcha_imgwidth img {
  /* height: 60px; */
  width: 100%;
}

.searchGstin_linked_head_textclr {
  color: rgb(49, 113, 155);
}

label.gstinsearch_lablefsclr.label-orange {
  color: #fc7d72;
}

.label-orange {
  color: #fc7d72;
}

.label-skyblue {
  color: #3f6c9d;
}

.label-skyblue-bold {
  color: #3f6c9d;
  font-weight: bold;
}

.gstinsearch_titlefs {
  font-size: 1rem;
  font-weight: 500;
  color: black;
}
