/*======= confirm popup box css======*/

.fade.areusuredelmbox.modal.show .modal-dialog {
    width: 35%;
}
.areusuredelmboxF.modal-footer {
    padding: 5px 15px;
}
button.confirpopupcancelbtn.btn.btn-secondary {
    background-color: #f2f2f2;
    color: #636363;
    border: 1px solid #ddd;
    padding: 3px 15px 4px 15px;
    border-radius: 18px;
    font-size: 12px;
    font-weight: 500;
}
button.confirpopupdelbtn.btn.btn-danger {
    background-color: #dc3545;
    color: #fff;
    border: 1px solid #dc3545;
    padding: 3px 15px 4px 15px;
    border-radius: 18px;
    font-size: 12px;
    font-weight: 500;
}

@media only screen and (max-width: 767px){
    .fade.areusuredelmbox.modal.show .modal-dialog {
        width: 95%;
    }
}
