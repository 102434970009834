/* ::-webkit-scrollbar {
  width: 0.8em;
  height: 0.5em;
}
::-webkit-scrollbar-button {
  background: #ccc;
}
::-webkit-scrollbar-track-piece {
  background: #ddd;
}
::-webkit-scrollbar-thumb {
  background: #eee;
}​ */
header {
  position: sticky;
  top: 0px;
  z-index: 10;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28) !important;
  background-color: #fff;
}

li.nav-item.dropdown.userprodropdlist.show .dropdown-menu.dropdown-menu-right.show button.dropdown-item {
  border-radius: 0px !important;
  padding: 5px 9px 5px 9px !important;
}

.headerlogodispinle {
  display: inline-flex;
}

ul.compboxtoppadd {
  /* min-height: 47px; */
  top: 0.5rem;
  position: relative;
  padding-left: 0px;
}

main header nav.navbar {
  padding: 0 3px !important;
}

header nav {
  margin-bottom: 0px !important;
  border: 0px !important;
  background-color: #fff;
}

a.navbar-brand.mainexplogo img {
  width: 100px;
}

header ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 0px;
}


li.headullipaddreight {
  padding-right: 0;
  position: relative;
  left: 0.8rem;
  z-index: 9;
}

span.boxalign {
  right: 0rem;
  left: 0.5rem;
  position: relative;
}

.taxtoppadd {
  padding-bottom: 0.2rem;
}

header ul li.dropdmenufr span {
  border-radius: 50%;
  padding: 10px 7px 6px 11px;
  /* margin-top: 15px; */
}

.header-sticky {
  position: fixed;
  top: 78px;
  left: 25%;
  z-Index: 100;
  width: 50%;
  text-align: center;
}

span.header_searchbox_closeicon {
  position: relative;
  top: 0px;
  padding: 7px 4px 1px 4px !important;
}
span.header_searchbox_closeicon .closeicon_fs {
  vertical-align: baseline;
  font-size: 14px;
  padding: 0px 0px;
  margin: 0px 0px;
  cursor: pointer;
  color: #31719b;
}

.userprodropd .dropdown-menu.show {

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, .15), 0 5px 20px 0 rgba(21, 27, 38, .08);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 1px;
  font-size: 14px;
  margin-top: 6px;
  border-radius: 0px;
  max-width: 100%;

}

.profile_img_bg {
  background-color: #f2f2f2;
}

.dark_color_text {
  color: #31719b;
}

span.profile_img_bg.dark_color_text {
  padding: 2.8px 9px 10px !important;
  font-size: 17px;
}

span.profile_img_bg.dark_color_text svg {
  top: 1px;
  position: relative;
}

.userprodropd ul {
  position: relative;
  top: -1px;
  display: table-cell;
}

.userprodropd ul li {
  margin-right: 0px;
}

li.nav-item.dropdown.userprodropdlist a#navbardrop {
  padding: 0.5rem 0.1rem !important;
}

span.checkiconusr {
  font-size: 12px !important;
  padding-left: 0px;
  color: #333;
}


span.logicntopm {
  margin-top: -9px;
  max-width: 100%;
}

span.logicntopm img {
  width: 15px;
  height: 15px;
  padding: 0px;
  float: right;
  top: 3px;
  position: relative;
}

.dropdown-menu.dropdown-menu-right.show .dropdown-divider {
  height: 0;
  margin: .3rem 0 !important;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

a.dropdown-item.profileusr span {
  font-size: 0.8rem;
}

.userprodropd a.dropdown-item.profileusr {
  padding: 0.1rem 0.6rem !important;
}

.userprodropd a.dropdown-item {
  font-size: 0.8rem;
  padding: .1rem 0rem !important;
}

.lipaddmanage span {
  padding: 10px 7px 6px 3px;
}

span.float-right.rightagliconmore img {
  width: 15px;
  height: 15px;
  padding: 0px;
  margin: 0px;
  color: #333;
}

span.float-right.rightagliconmore {
  margin-top: -12px;
}


.dropdown-item.profileusr {
  padding: 0.2rem 0.5rem;
}

span.black_color_text.usertextoverflow {
  max-width: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: pointer;
  display: inline-block;
}

span.activeusrp {
  color: #4caf50 !important;
  font-size: 9px !important;
  float: right;
  margin-top: -6px !important;

}

button.dropdown-item.logdisinline.btn.btn-primary {
  padding: 0.2rem 0.5rem;
}

span.k-widget.k-dropdown.header_select.headerselect {
  width: 68px !important;
  padding: 0px 0px;
  font-size: 12px;

}

span.k-widget.k-dropdown.header_select.headerselect.monthelbox {
  width: 47px !important;
}

span.k-widget.k-dropdown.header_select.headerselect span.k-dropdown-wrap {
  margin-top: -2px;
  border-color: #f2f2f2;
  color: #656565;
  background-color: #f2f2f2 !important;
  float: right;
  padding: 0;
  background-image: linear-gradient(#f2f2f2, #f2f2f2) !important;
}

span.k-widget.k-dropdown.header_select.headerselect span.k-select {
  padding: 0px 0px;
  width: calc(0.9em + 5px);
}

.refrsh_sync {
  margin: 0px 8px;
  cursor: pointer;
  color: #31719b;
}

.headerselectoption .k-list>.k-item {
  color: #656565;
  font-size: 12px;
  font-weight: bold;
  padding-top: 1px;
  padding-bottom: 1px;
  border-bottom: 1px solid #f2f2f2;
}

.headerselectoption .k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  color: #fff !important;
}

.infoicongstin {
  display: inline-block;
  cursor: pointer;
}

span.infoicongstin {
  position: absolute;
  top: -1.8px;
  right: -13px;
  font-size: 10px;
  cursor: pointer;
}

span.gstinfoiconheader {
  position: relative;
  top: -7px;
  font-size: 10px;
}

.rotate_icon {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.disabledoption {
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}

a.navbar-brand.mainexplogo {
  border-color: #66afe900 !important;
  outline: 0;
  box-shadow: inset 0 2px 2px rgb(0 0 0 / 0%), 0 0 8px rgb(102 175 233 / 0%) !important;
}

/* Start call me */
li.nav-item.dropdown.callmemainbox {
  top: 0.3rem;
}

span.callicon img {
  width: 15px;
}

.dispinlinerightnav {
  float: right;
  display: inline-flex;
}

.callmebgclr {
  background-color: #31719b;
  color: #fff;
  padding: 1px 4px 3px 4px !important;
  position: relative;
  border-radius: 4px;
  font-size: 13px;
  top: 3px;
  cursor: pointer;
}

.callmebgclr span {
  padding: 2px;
}

.callmebgclr:hover {
  color: #fff;
}

.nav-link.dropdown-toggle.callmebgclr::after {
  display: none;
}

.dropdown-menu.cllmeinnerdown.show {
  top: 3.1rem;
  margin: 0px -5rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%);
  box-sizing: border-box;
  font-size: 14px;
  max-width: 100%;
  padding: 0px 1px;
  min-width: 14rem;
}

span.closeiconright {
  float: right;
  color: #31719b;
}

.dropdown-item.headerscetbborder {
  font-size: 12px;
  padding: 0.4rem 0.5rem;
  border-bottom: 1px solid #e8e3e3;
  font-weight: 500;
  border-radius: 0px;
}

.textboxinnersect {
  padding: 0.5rem 0.5rem;
}

p.contentarea {
  font-size: 10.5px;
  color: #909090;
  font-weight: 500;
}

.inputboxarea input.form-control.inputcallme {
  font-size: 12px;
  padding: .37rem .75rem;
  height: calc(1.2em + .75rem + 2px);
  border: 1px solid #c6c2c2;
  outline-color: transparent;
}

.callmebtnbox {
  padding: 0.5rem 0.1rem 0rem 0.1rem;
  text-align: center;
}

.callmebtnbox .callmedonebtn {
  background-color: #33729c;
  color: #fff;
  outline-color: transparent;
  padding: 0.2rem 0.9rem;
  font-size: 12px;
  font-weight: 500;
  border-radius: 40px;
}

.callmebtnbox .callmedonebtn:hover {
  color: #fff;
}

input.form-control.inputcallme:focus {
  color: #495057;
  background-color: #fff;
  border-color: #33729c;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

a.btn.btn-default.callmedonebtn:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.dropdown-item.headerscetbborder:active {
  color: #16181b;
  background-color: #cccccc00;
}

.disableRefrshSync {
  color: #93979b;
}

/* end  call me */

span.gstrdatebox {
  color: #31719b;
}

ul.headernavdropmd {
  position: relative;
  right: 3.3rem;
}

ul.callmerightbox {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 0px;
}

.userprodropd {
  right: 1rem;
  position: relative;
}

.captcha_img img {
  /* height: 60px; */
  width: 100%;
}

/*================header toggl btn css========================*/
label.switch.htoggleswit {
  margin-bottom: -0.4rem;
}

/*================header toggl btn css========================*/
label.switch.htoggleswit {
  margin-bottom: -0.4rem;
}

span.headertogglebtn {
  top: 4.6px;
  position: relative;
  right: 1.2rem;
}

.switch.htoggleswit {
  position: absolute;
  display: inline-block;
  width: 23px;
  /* 60*/
  height: 11px;
  /*34 */
}

.switch.htoggleswit input {
  opacity: 0;
  width: 0;
  height: 0;
}


label.switch.htoggleswit .slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #33729c;
  transition: .4s;
}

label.switch.htoggleswit .slider:before {
  position: absolute;
  content: "";
  height: 7.3px;
  width: 3px;
  top: 2px;
  left: 3px;
  background-color: rgb(242 242 242);
  transition: .4s;
  outline-color: rgb(242 242 242);
}

label.switch.htoggleswit input:checked+.slider {
  background-color: #cccbcb;
}

/* label.switch.htoggleswit input:focus + .slider {
  box-shadow: 0 0 1px #31719b;
} */

label.switch.htoggleswit input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -9px;
}

/* Rounded sliders */
label.switch.htoggleswit .slider.round {
  border-radius: 34px;
}

label.switch.htoggleswit .slider.round:before {
  border-radius: 16%;
}

/*================end header toggl btn css========================*/

.header-hidden {
  position: absolute;
  top: -64px;
  width: 100%;
  animation: all 0.2s ease;
  transition-duration: 0.2s;
}

.header-show {
  position: sticky;
  top: 0px;
  width: 100%;
  animation: all 0.2s ease;
  transition-duration: 0.2s;
}

.dropdown-menu.dropdown-menu-right.show .dropdown-divider {
  height: 0;
  margin: .3rem 0 !important;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

li.nav-item.dropdown.userprodropdlist.show .dropdown-menu.dropdown-menu-right.show button.dropdown-item {
  border-radius: 0 !important;
  padding: 5px 9px !important;
}

li.nav-item.dropdown.userprodropdlist.show .dropdown-menu.dropdown-menu-right.show button.dropdown-item:active {
  background-color: #e9ecef !important;
  color: #16181b;
}

li.nav-item.dropdown.userprodropdlist.show .dropdown-menu.dropdown-menu-right.show button:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0rem rgb(38 143 255 / 50%);
}

a.headerdropd_helpv_fs {
  color: #16181b;
}

a.headerdropd_helpv_fs:hover {
  color: #16181b;
  text-decoration: none;
}

span.logicntopm img {
  width: 15px;
  height: 15px;
  padding: 0;
  float: right;
  top: 3px;
  position: relative;
}

.callme_tour {
  height: 210px;
  width: 230px;
  position: absolute;
  left: -145px;
  background-color: #fff;
  display: none;
}

.callme_tour.show {
  display: block;
}


span.k-widget.k-dropdown.header_select.headerselect span.k-dropdown-wrap span.k-input {
  height: calc(0.9em + 5px);
  padding: 0px 0px 0px 5px;
}

/* start media code */
@media (max-width: 319px) {
  a.navbar-brand.mainexplogo img {
    width: 76px !important;
    left: -21px;
    position: relative;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  a.navbar-brand.mainexplogo img {
    width: 92px !important;
  }
}

@media only screen and (max-width: 767px) {

  .col-8.col-md-8.col-sm-8.col-lg-8.col-xl-8.mt-2.mb-0.pb-0.headerrightcontent {
    padding: 0px;
  }

  ul.float-right.headernavdropmd.mb-0.pb-0 li {
    margin-right: 0px;
    float: right;
  }

  .navbar-header.col-4.col-md-4.col-sm-4.col-lg-4.col-xl-4.smpaddhead {
    padding: 0px 1px;
  }

  .profilesmfsize {
    font-size: 11px !important;
  }

  li.nav-item.dropdown.userprodropdlist a#navbardrop {
    float: right;
  }

  span.infoicongstin {
    top: 31px;
  }

  .dropdown-menu.cllmeinnerdown.show {
    top: 2.1rem;
    margin: 0px -5rem;

  }

  .dispinlinerightnav {
    top: 0px;
    padding: 0px;
  }

  ul.headernavdropmd {
    top: 0px;
    min-height: 47px;
    width: 100%;
    margin: 0px 0px;
    padding: 0px;
    right: 0rem;
    float: left;
  }

  .userprodropd {
    top: 0rem;
    position: relative;
    text-align: right;
    right: -1rem;
  }

  header ul.headernavdropmd li {
    list-style-type: none;
    display: inline-grid;
    margin-right: 0px;
  }

  nav.navbar.comp_header_mobile_width ul.callmerightbox {
    top: 60.5%;
  }

  nav.navbar.comp_header_mobile_width .userprodropd {
    top: 1px;
  }

  ul.callmerightbox {
    right: 0px;
    top: 47.5%;
    padding: 0px;
  }

  .navbar-header.mobileheader_width {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  li.headullipaddreight {
    float: right;
  }

  li.dropdmenufr {
    right: 5rem;
    float: right;
    position: relative;
    top: 4px;
  }

  /* header in mobile view css */

  nav.navbar.header_mobile_width .navbar-header.logo_width_mobile30 {
    width: 30%;
  }

  nav.navbar.header_mobile_width .urs_width_mobile70 {
    width: 70%;
  }

  nav.navbar.header_mobile_width ul.headernavdropmd {
    float: left;
    left: 0rem;
    position: relative;
    top: -0.2rem;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 6px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 {
    padding: 0px;
  }

  nav.navbar.comp_header_mobile_width span.headerfy_overflowtext {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  /* end header in mobile view css */

}

span.gst_leftcomp_overflowtet {
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-block;
}


@media (max-width: 767px) {
  button.btn.btn-default.download-btnbgclr {
    top: 0px !important;
  }

  span.gst_leftcomp_overflowtet {
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: inline-block;
}
  .userprodropd ul {
    top: -7px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {

  span.gst_leftcomp_overflowtet {
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: inline-block;
}


  .userprodropd ul {
    display: inline-block;
  }

  .userprodropd li.nav-item.dropdown.userprodropdlist {
    top: 0rem !important;
    right: 0rem;
    left: -0.3rem;
  }

  span.infoicongstin {
    top: 12px;
  }

  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: -0.2rem;
    left: -1rem;
  }

  nav.navbar.comp_header_mobile_width ul.headernavdropmd li.dropdmenufr .userprodropd {
    top: -0.8rem;
  }

  .dropdown-menu.cllmeinnerdown.show {
    margin: 0px -5rem;
  }

  nav.navbar.comp_header_mobile_width li.headullipaddreight {
    display: inline-block;
    right: 0px;
    font-size: 11px !important;
  }



  ul.headernavdropmd {
    position: relative;
    display: inline-block;
  }

  li.nav-item.dropdown.bellaltlist {
    top: 0px !important;
    position: relative;
  }

  .userprodropd ul li {
    margin-right: 6px;
  }

  nav.navbar.comp_header_mobile_width span.headerfy_overflowtext {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 {
    padding: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 {
    padding: 0px;
  }

  .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd .profilesmfsize {
    font-size: 10px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle a.navbar-brand.mainexplogo img {
    width: 88px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd {
    padding-left: 0px;
    padding-right: 3rem;
  }

  nav.navbar.comp_header_mobile_width ul.callmerightbox span.nav-link-btn.callmebgclr {
    font-size: 11px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 a.navbar-brand.mainexplogo {
    margin: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 ul.headernavdropmd {
    padding: 0px;
  }

  ul.headernavdropmd {
      position: relative;
      right: 0.3rem;
  }

}

@media only screen and (min-width: 825px) and (max-width: 911px) {
  span.dropdown.partywisereco_headerdroplist_yrwidth span.btn.btn-primary.dropdown-toggle {
    width: 87px !important;
  }
    span.dropdown.partywisereco_headerdroplist_monthwidth span.btn.btn-primary.dropdown-toggle {
      width: 113px !important;
  }
}
@media only screen and (min-width: 821px) and (max-width: 912px) {

  span.gst_leftcomp_overflowtet {
    white-space: nowrap;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: inline-block;
}

  .userprodropd ul {
    display: inline-block;
  }

  .userprodropd li.nav-item.dropdown.userprodropdlist {
    top: 0rem !important;
    right: 0rem;
    left: -0.3rem;
  }

  span.infoicongstin {
    top: 12px;
  }

  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: -0.2rem;
    left: -1rem;
  }

  nav.navbar.comp_header_mobile_width ul.headernavdropmd li.dropdmenufr .userprodropd {
    top: -0.8rem;
  }

  .dropdown-menu.cllmeinnerdown.show {
    margin: 0px -5rem;
  }

  nav.navbar.comp_header_mobile_width li.headullipaddreight {
    display: inline-block;
    right: 0px;
    font-size: 11px !important;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 5px;
  }

  ul.headernavdropmd {
    position: relative;
    display: inline-block;
  }

  li.nav-item.dropdown.bellaltlist {
    top: 0px !important;
    position: relative;
  }

  .userprodropd ul li {
    margin-right: 5px;
  }

  nav.navbar.comp_header_mobile_width span.headerfy_overflowtext {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 {
    padding: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 {
    padding: 0px;
  }

  .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd .profilesmfsize {
    font-size: 11px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle a.navbar-brand.mainexplogo img {
    width: 88px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd {
    padding-left: 0px;
  }

  nav.navbar.comp_header_mobile_width ul.callmerightbox span.nav-link-btn.callmebgclr {
    font-size: 11px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 a.navbar-brand.mainexplogo {
    margin: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 ul.headernavdropmd {
    padding: 0px;
  }


}

@media only screen and (min-width: 913px) and (max-width: 1017px) {
  .userprodropd ul {
    display: inline-block;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 0.4rem;
  }

  .userprodropd li.nav-item.dropdown.userprodropdlist {
    top: 0rem !important;
    right: 0rem;
    left: -0.3rem;
  }

  span.infoicongstin {
    top: 12px;
  }

  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: -0.2rem;
    left: -1rem;
  }

  nav.navbar.comp_header_mobile_width ul.headernavdropmd li.dropdmenufr .userprodropd {
    top: -0.8rem;
  }

  .dropdown-menu.cllmeinnerdown.show {
    margin: 0px -5rem;
  }


  nav.navbar.comp_header_mobile_width li.headullipaddreight {
    display: inline-block;
    right: 0px;
    font-size: 11px !important;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 0px;
  }

  ul.headernavdropmd {
    position: relative;
    display: inline-block;
  }

  li.nav-item.dropdown.bellaltlist {
    top: 0px !important;
    position: relative;
  }

  .userprodropd ul li {
    margin-right: -1px;
  }
  .animtrans_box {
    position: relative;
    padding-right: 4px;
}

  nav.navbar.comp_header_mobile_width span.headerfy_overflowtext {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 {
    padding: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 {
    padding: 0px;
  }

  .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle a.navbar-brand.mainexplogo img {
    width: 88px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd {
    padding-left: 0px;
  }

  nav.navbar.comp_header_mobile_width ul.callmerightbox span.nav-link-btn.callmebgclr {
    font-size: 11px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 a.navbar-brand.mainexplogo {
    margin: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 ul.headernavdropmd {
    padding: 0px;
  }

}

@media only screen and (min-width: 1018px) and (max-width: 1025px) {
  .userprodropd ul {
    display: inline-block;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 0.4rem;
  }

  .userprodropd li.nav-item.dropdown.userprodropdlist {
    top: 0rem !important;
    right: 0rem;
    left: -0.3rem;
  }

  span.infoicongstin {
    top: 12px;
  }

  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: -0.2rem;
    left: -1rem;
  }

  nav.navbar.comp_header_mobile_width ul.headernavdropmd li.dropdmenufr .userprodropd {
    top: -0.8rem;
  }

  .dropdown-menu.cllmeinnerdown.show {
    margin: 0px -5rem;
  }


  nav.navbar.comp_header_mobile_width li.headullipaddreight {
    display: inline-block;
    right: 0px;
    font-size: 11px !important;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 0px;
  }

  ul.headernavdropmd {
    position: relative;
    display: inline-block;
  }

  li.nav-item.dropdown.bellaltlist {
    top: 0px !important;
    position: relative;
  }

  .userprodropd ul li {
    margin-right: 1px;
  }
  .animtrans_box {
    position: relative;
    padding-right: 4px;
}

  nav.navbar.comp_header_mobile_width span.headerfy_overflowtext {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 {
    padding: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 {
    padding: 0px;
  }

  .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle a.navbar-brand.mainexplogo img {
    width: 88px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd {
    padding-left: 0px;
  }

  nav.navbar.comp_header_mobile_width ul.callmerightbox span.nav-link-btn.callmebgclr {
    font-size: 11px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 a.navbar-brand.mainexplogo {
    margin: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 ul.headernavdropmd {
    padding: 0px;
  }

}

@media only screen and (min-width: 1026px) and (max-width: 1171px) {
  .userprodropd ul {
    display: inline-block;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 0.4rem;
  }

  .userprodropd li.nav-item.dropdown.userprodropdlist {
    top: 0rem !important;
    right: 0rem;
    left: -0.3rem;
  }

  span.infoicongstin {
    top: 12px;
  }

  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: -0.2rem;
    left: -1rem;
  }

  nav.navbar.comp_header_mobile_width ul.headernavdropmd li.dropdmenufr .userprodropd {
    top: -0.8rem;
  }

  .dropdown-menu.cllmeinnerdown.show {
    margin: 0px -5rem;
  }


  nav.navbar.comp_header_mobile_width li.headullipaddreight {
    display: inline-block;
    right: 0px;
    font-size: 11px !important;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 5px;
  }

  ul.headernavdropmd {
    position: relative;
    display: inline-block;
  }

  li.nav-item.dropdown.bellaltlist {
    top: 0px !important;
    position: relative;
  }

  .userprodropd ul li {
    margin-right: 6px;
  }

  nav.navbar.comp_header_mobile_width span.headerfy_overflowtext {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 {
    padding: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 {
    padding: 0px;
  }

  .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle a.navbar-brand.mainexplogo img {
    width: 88px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd {
    padding-left: 0px;
  }

  nav.navbar.comp_header_mobile_width ul.callmerightbox span.nav-link-btn.callmebgclr {
    font-size: 11px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 a.navbar-brand.mainexplogo {
    margin: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 ul.headernavdropmd {
    padding: 0px;
  }

}

@media only screen and (min-width: 1172px) and (max-width: 1359px) {
  .userprodropd ul {
    display: inline-block;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 0.4rem;
  }

  .userprodropd li.nav-item.dropdown.userprodropdlist {
    top: 0rem !important;
    right: 0rem;
    left: -0.3rem;
  }

  span.infoicongstin {
    top: 12px;
  }

  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: -0.2rem;
    left: -1rem;
  }

  nav.navbar.comp_header_mobile_width ul.headernavdropmd li.dropdmenufr .userprodropd {
    top: -0.8rem;
  }

  .dropdown-menu.cllmeinnerdown.show {
    margin: 0px -5rem;
  }


  nav.navbar.comp_header_mobile_width li.headullipaddreight {
    display: inline-block;
    right: 0px;
    font-size: 11px !important;
  }

  nav.navbar.header_mobile_width ul.callmerightbox {
    top: 5px;
  }

  ul.headernavdropmd {
    position: relative;
    display: inline-block;
  }

  li.nav-item.dropdown.bellaltlist {
    top: 0px !important;
    position: relative;
  }

  .userprodropd ul li {
    margin-right: 6px;
  }

  nav.navbar.comp_header_mobile_width span.headerfy_overflowtext {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 {
    padding: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 {
    padding: 0px;
  }

  .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd .profilesmfsize {
    font-size: 12px !important;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle a.navbar-brand.mainexplogo img {
    width: 88px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 .headerlogodispinle ul.compboxtoppadd {
    padding-left: 0px;
  }

  nav.navbar.comp_header_mobile_width ul.callmerightbox span.nav-link-btn.callmebgclr {
    font-size: 11px;
  }

  nav.navbar.comp_header_mobile_width .navbar-header.logo_width_mobile30 a.navbar-brand.mainexplogo {
    margin: 0px;
  }

  nav.navbar.comp_header_mobile_width .urs_width_mobile70 ul.headernavdropmd {
    padding: 0px;
  }

}

/* ================= end media code ============  */



.k-grid-header .k-header {
  text-align: center !important;
}

.k-grid-header-wrap span.k-icon.k-i-more-vertical {
  padding-left: 13px !important;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
  padding: 0px !important;
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-dropdowntree .k-multiselect-wrap .k-button {
  padding: 4px !important;
}

.k-button {
  background-color: #e57373 !important;
  border-color: #e57373 !important;
  color: #212529 !important;
  background-image: none !important;
}

.k-button:hover,
.k-button.k-state-hover {
  background-color: #31719b !important;
  border-color: #31719b !important;
  color: #fff !important;
  background-image: none !important;
}

.k-grid-header-wrap span.k-icon.k-i-more-vertical {
  padding-left: 0px !important;
}

th.active .k-icon {
  color: #fc7d72 !important;
}

.k-i-more-vertical::before {
  content: "\e129" !important;
}

/*.k-i-more-vertical::before {
       content: "\e12a";
   }*/

span.k-widget.k-dropdown.header_select.headerselect span.k-dropdown-wrap span.k-input {
  height: calc(0.9em + 5px) !important;
  padding: 0px 0px 0px 5px !important;
}

span.k-widget.k-dropdown.header_select.headerselect span.k-select {
  padding: 0px 0px !important;
  width: calc(0.9em + 5px) !important;
}

.header_select {
  width: 9.4em !important;
}

/*======= gstr1 timeline css =============*/
.timelintopbtnbox {
  display: inline-flex;
}

.FRbackbtn {
  color: #fc7d72;
  font-size: 12px;
}

span.iconbox {
  border: 1px solid #cccbcb;
  width: 28px;
  border-radius: 50%;
  display: flex;
  position: relative;
  left: 29px;
  color: #fff;
  padding: 0 8px;
  top: -2px;
  background-color: #cccbcb;
}

span.innercontent {
  padding: 0 5px 0 40px;
  margin-top: 0;
  font-size: 14px;
  color: #31719b;
  font-weight: 500;
  z-index: 5;
}

span.timelinebor {
  display: inline-block;
  position: absolute;
  z-index: 0;
  width: 266px;
  border-bottom: solid #f5f5f5;
  border-width: 2px;
  left: 14.6rem;
  top: 12px;
}

.btn-group.btn-group-md.innerbox01 {
  left: 4rem;
}

.btn-group.btn-group-md.innerbox02 {
  left: 18.8rem;
}

span.iconbox.activetext {
  background-color: #33729c;
  outline-color: #33729c;
  border-color: #33729c;
}

span.innercontent.activetext {
  color: #33729c;
  font-weight: 800;
}

span.timelinebor.timelinebor02 {
  left: 12.1rem;
}

.btn-group.btn-group-md.innerbox03 {
  left: 33.5rem;
}

/*======= end gstr1 timeline css =============*/
.fade.modal.cashcreditledgerpoup.modal.show {
  background-color: #222222a8;
}
.fade.modal.cashcreditledgerpoup.modal.show .modal-dialog {
  max-width: 800px !important;
}

.modal-header.cashhead.modal-header {
  background-color: #eee;
  padding: 0px 15px;
}

.modal-header.cashhead.modal-header .modal-title.cashledgertext {
  font-size: 1.2rem;
  padding: 3px 0px 4px 0px;
  font-weight: 500;
}

.modal-header.cashhead.modal-header button.close {
  top: 3px;
  position: relative;
}

span.connector-infoicon-clr svg.svg-inline--fa.fa-info-circle.fa-w-16 {
  width: 32px;
}

span.connector-trying-textfsbgclr {
  background-color: #f9f3d7;
  padding: 3px 40px 5px 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

span.connector-mandatory-textfs {
  font-size: 1rem;
  color: #31719b;
  font-weight: 500;
}

span.connector-infoicon-clr {
  color: #fc7d72;
  font-size: 27px;
  vertical-align: text-top;
  padding-right: 6px;
  position: relative;
  top: 3px;
}

button.btn.btn-default.download-btnbgclr {
  background-color: #ffffff;
  padding: 0px 5px 5px 5px;
  border-color: #31719b;
  font-size: 12px;
  border-radius: 40px;
  color: #31719b;
  font-weight: 500;
  top: 10px;
  position: relative;
}

.leftside-border {
  border-left: 1px solid #ddd;
  border-width: 2.2px;
  padding: 0px 1px 0px 18px;
}

p.down-alraedytextfs {
  font-size: 12px;
  font-weight: 500;
}

a.clickhererefer-fs {
  color: #31719b;
}

h4.infoicon-left {
  position: relative;
  left: -1rem;
}

fieldset {
  display: block;
  margin: 9px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;
  border: 2px groove (internal value);
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}



/* ============= search icon add css in header ==================*/
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ============= gstin searchmodal css box ============*/
span.searchbox_icon {
  background-color: #f2f2f2;
  padding: 10px !important;
  color: #31719b;
  font-size: 11px;
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  display: block;
}

span.searchbox_icon img {
  top: -3px;
  position: relative;
  right: 1.5px;
}

span.searchbox_icon svg.svg-inline--fa.fa-search.fa-w-16 {
  width: 16px;
  padding: 0px;
}

li.nav-item.searchboxdrop a.nav-link.dropdown-toggle {
  margin: 0px;
  padding: 0px;
}

li.nav-item.searchboxdrop a.nav-link.dropdown-toggle::after {
  display: none;
}

span.searchiconlistofcomp {
  font-size: 14px;
  padding: 3px 5px 1px 5px !important;
  cursor: pointer;
  color: #31719b;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.dropdown-menu.dropdown-menu-right.gstrsearchboxinner.show {
  padding: 0px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 1px;
  margin-top: 18px;
  max-width: initial;
  transition: all 1s ease-in-out;
  animation-name: fadeInDown !important;
  animation: fadeIn 1s ease-in both;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15), 0 5px 20px 0 rgba(21, 27, 38, 0.08);
  border-width: 0.2px;
  border: 1px;
}

.dropdown-menu.dropdown-menu-right.gstrsearchboxinner.show .dropdown-item {
  width: 100%;
  padding: 0rem 0.1rem;
}

.dropdown-menu.dropdown-menu-right.gstrsearchboxinner.show .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #ffffff !important;
}

input.form-control.headersearchbox {
  font-size: 11px;
  padding: 2px 5px;
  font-weight: 500;
  border: 0px;
}

input.form-control.headersearchbox:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

.form-group.headewrhas-search {
  margin-bottom: 0px;
  display: inline-flex;
}

.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #ffffff !important;
}

.modal.gstin_headersearchbox_modal.show .modal-dialog {
  margin: 4rem auto auto auto;
}

.modal-header.gstin_headersearchbox_header {
  padding: 0px 15px;
  background-color: #eee;
}

.modal-header.gstin_headersearchbox_header h4.modal-title {
  font-size: 1rem;
  padding: 5px 0px 5px 0px;
}

.modal-header.gstin_headersearchbox_header button.close {
  top: 3px;
  position: relative;
}

.modal-header.gstin_headersearchbox_header button.close:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.modal-header.gstin_headersearchbox_header .gst_verifyyremailid_title.modal-title.h4 {
  font-size: 1rem;
  padding: 5px 0px 5px 0px;
}

h4.gstinsearch_basicdeatils_titlefs {
  font-size: 1rem;
  font-weight: 500;
}

label.gstinsearch_lablefsclr {
  color: rgb(119, 113, 113);
  font-size: 11px;
  font-weight: 500;
}

label.gstinsearch_lablefsoutclr {
  font-size: 11px;
  font-weight: 500;
}

label.gstinsearch_lablefsoutclr.gstin_searchboxbasic_overflowtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 250px;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

table.table.table-striped.gstinsearchbox_efillingdetails_tb thead tr th {
  font-size: 12px;
  text-align: center;
  border-top: 0;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
}

table.table.table-striped.gstinsearchbox_efillingdetails_tb tr td {
  font-size: 11px;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
}

/*# sourceMappingURL=header.css.map */


.dropdown-menu.dropdown-menu-right.gstrsearchboxinner.show {
  padding: 0px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 20px;
  max-width: initial;
  transition: all 0.1s ease-in-out;
  animation-name: fadeInDown !important;
  animation: fadeIn 0.1s ease-in both;
  box-shadow: 0 0 0 1px rgb(111 119 130 / 28%), 0 2px 6px 3px rgb(100 100 100 / 40%);
  border-width: 0.2px;
  border: 1px;
}

.dropdown-menu.dropdown-menu-right.gstrsearchboxinner.show .dropdown-item {
  width: 100%;
  padding: 0rem 0.1rem;
}

.form-group.headewrhas-search {
  margin-bottom: 0px;
  display: inline-flex;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

input.form-control.headersearchbox {
  font-size: 11px;
  padding: 2px 5px;
  font-weight: 500;
  border: 0px;
}

.dropdown-item.active,
.dropdown-item:active {
  background: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;

}

.form-control.headersearchbox:focus {
  box-shadow: none;
}

.gstin_detail_popup .modal-dialog {
  max-width: 800px;
}


.fade.gstinfo_search_model.modal.show .modal-dialog {
  width: 100%;
  max-width: 1000px;
}

.gstinfo_search_model .label-orange {
  color: #fc7d72;
}

.gstinfo_search_model .label-orange-bold {
  color: #fc7d72;
  font-weight: bold;
}

.gstinfo_search_model .label-skyblue {
  color: #3f6c9d;
}

.gstinfo_search_model .label-skyblue-bold {
  color: #3f6c9d;
  font-weight: bold;
}

.gstinfo_search_model .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fffdf663;
}

.gstinfo_search_model .table-striped tbody tr:nth-of-type(even) {
  background-color: #f8fcff61;
}

.gstinfo_search_model .form-row {
  line-height: 1;
}

.gstinfo_search_model tr {
  line-height: 1.2;

}

label.gstinsearch_lablefsoutclr.gstin_priPOB_overflowtext.label-skyblue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 340px;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

label.gstinsearch_lablefsoutclr.label-skyblue.gstin_status_oveflowtext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 50px;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

.leftside_navbar_toggletimeline {
  position: fixed;
  bottom: 6rem;
  left: -13px;
  line-height: 30px;
  height: 30px;
  width: 50px;
  background: #fc7d72;
  float: right;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  z-index: 999999;
  padding: 3px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.leftside_navbar_toggletimeline:hover {

  left: 0px;
  box-shadow: 1px 0px 4px 1px #2e729c;
  transition: 0.5s all ease-in-out;
  transform: scale(1);
}

.leftside_navbar_toggletimeline .toggleColum {
  width: 24px;
  height: 24px;
  background: white;
  float: right;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.leftside_navbar_toggletimeline .timelineimg {
  width: 25px;
}

.leftside_navbar_toggletimeline .closebutton {
  color: black;
}

.leftside_navbar_toggletimeline .rotate_img {
  animation: spin 4s linear infinite;
}

.gst_verifyyremailid_title.gstinheader_searchmodal.modal-title.h4 {
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 0px 5px 0px !important;
}

button.btn.btn-default.gstinheader_findgstinbtn {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 28px;
  color: #fff;
  padding: 2px 10px 4px;
  font-size: 12px;
  font-weight: 500;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* span.sync_status.gstheader_synccardhovershow:hover .gstheader_synccardhoverbox {
  display: block;
  transition: 1.5s all ease;
} */

.gstheader_synccardhoverbox {
  border: #f0f8ff00;
  max-width: 30em;
  padding: 0.5em 1em;
  font-size: 1.25em;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgb(0 0 0 / 63%), 0 0.0625rem 0.125rem rgb(0 0 0 / 31%);
  position: absolute;
  background: linear-gradient(175deg, #fffe8f -80%, #fff);
  margin-top: 1rem;
  margin-right: -1rem;
  transition: 1.5s all ease;
  display: block;
}

.gstheader_synccardhoverbox_success {
  border: #f0f8ff00;
  max-width: 30em;
  width: 18em;
  padding: 0.5em 1em;
  font-size: 1.25em;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgb(0 0 0 / 63%), 0 0.0625rem 0.125rem rgb(0 0 0 / 31%);
  position: absolute;
  background: linear-gradient(175deg, #fffe8f -80%, #fff);
  margin-top: 1.5rem;
  margin-right: 1rem;
  margin-left:8.5rem;
  transition: 1.5s all ease;
  display: block;
    p.gstheader_synccard_contentbox {
      text-align: center;
      margin-top: 0.5rem;
    }
}

.gstheader_synccardhoverbox_success::before{
  bottom: 97%;
  border-top: none;
  transform: rotate(270deg);
  content: "";
  position: absolute;
  /* top: 0%; */
  right: 30px;
  border-width: 0px 24px 28px 0;
  border-style: solid;
  border-color: #ffffff #89878700 #fffecb #89878700;
 
}

.gstheader_synccardhoverbox::before{
  bottom: 98%;
  border-top: none;
  transform: rotate(270deg);
  content: "";
  position: absolute;
  /* top: 0%; */
  right: 30px;
  border-width: 0px 24px 28px 0;
  border-style: solid;
  border-color: #ffffff #89878700 #fffecb #89878700;
 
}

p.gstheader_synccard_contentbox {
  font-size: 12px;
  text-align: justify;
  font-weight: 500;
  line-height: 18px;
  color: #222;
  margin-bottom: 0.5rem;
}
p.gstheader_synccard_gstinportal_textfs {
  font-size: 14px;
  font-weight: 500;
  color: #31719b;
  text-align: left;
  margin-bottom: 0.5rem;
}
button.btn.btn-default.gstheader_synccard_goit_btn {
  font-size: 12px;
  font-weight: 500;
  border: 0px;
  border-radius: 40px;
  padding: 3px 15px 5px 15px;
  background-color: #31719b;
  color: #fff;
}

span.sync_status.header_syncbubble_clickshow:hover .header_syncbubble_clickhidebox {
  display: block;
  transition: 1.5s all ease;
}

.header_syncbubble_clickhidebox {
  border: #f0f8ff00;
  max-width: 100%;
  min-width: 20em;
  width: 27em;
  padding: 0.5em 1em;
  font-size: 1.25em;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgb(0 0 0 / 63%), 0 0.0625rem 0.125rem rgb(0 0 0 / 31%);
  position: absolute;
  background: linear-gradient(175deg, #fffe8f -80%, #fff);
  margin-top: 1rem;
  margin-right: 0.5rem;
  transition: 1.5s all ease;

      p.gstheader_synccard_contentbox {
          font-size: 12px;
          text-align: left;
          font-weight: 500;
          line-height: 18px;
          color: #222;
          margin-bottom: 0.5rem;
      }
      p.gstheader_synccard_gstinportal_textfs {
          font-size: 13px;
          font-weight: 500;
          color: #31719b;
          text-align: left;
          margin-bottom: 0.5rem;
      }
      p.gstheader_synccard_captchatextfs {
          font-size: 12px;
          font-weight: 500;
          color: #222;
          margin-bottom: 2px;

          span.reloadcaptchaclick {
              color: #31719b;
              cursor: pointer;
          }
      }
      .gstheader_synccard_inputbox input.form-control.gstheader_synccard_inputboxpadd {
          font-size: 12px;
          font-weight: 500;
          padding: 0px 5px;
      }
      .gstheader_synccard_inputbox input.form-control.gstheader_synccard_inputboxpadd:focus {
          color: #495057;
          background-color: #fff;
          border-color: #80bdff;
          outline: 0;
          box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
      }
      p.gstheader_synccard_progresstextfs {
          font-size: 12px;
          font-weight: 500;
          color: #222;
      }

      .card.gstheader_synccardboxcetcha {
          padding: 5px 7px;
          background-color: #fff;
          box-shadow: 0px 1px 5px #ddd;
          border-radius: 10px;
      }
      button.btn.btn-default.gstheader_synccard_goit_btn {
          font-size: 12px;
          font-weight: 500;
          border: 0px;
          border-radius: 40px;
          padding: 3px 10px 5px 10px;
          background-color: #31719b;
          color: #fff;
      }
      .gstheader_synccard_closeboxicon {
          cursor: pointer;
          padding-left: 9px;
      }
}

.header_syncbubble_clickhidebox::before{
  bottom: 98%;
  border-top: none;
  transform: rotate(270deg);
  content: "";
  position: absolute;
  /* top: 0%; */
  right: 30px;
  border-width: 0px 24px 28px 0;
  border-style: solid;
  border-color: #ffffff #89878700 #fffecb #89878700;
 
}