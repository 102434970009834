

.recodash_importbooksdata_popup{
    // margin: 3rem auto auto 0rem;
    // max-width: 520px;
  

    .recodash_importbooksdata_tabbox {
        & nav.recodash_importbooksdata_tabbox_navtab {
            padding-top: 4px;
            padding-bottom: 5px;

            & .nav.nav-tabs {
                border-bottom: 0px solid #dee2e6;

                & a.nav-item.nav-link.recodash_importbooksdata_taball {
                    color: #31719b;
                    background-color: #ffffff;
                    border-color: #31719b;
                    outline-color: #31719b !important;
                    border-radius: 0px;
                    padding: 0.3rem 2.5rem 0.3rem 2.5rem;
                    font-size: 12px;
                    border: 1px solid #31719b;
                    border-right: 0px;
                    font-weight: 500;
                    text-align: center;
                }

                & a.nav-item.nav-link.recodash_importbooksdata_taball.active {
                    color: #ffffff !important;
                    background-color: #fc7d72;
                }

                & a.nav-item.nav-link.recodash_importbooksdata_tab_r2 {
                    border-right: 1px solid #31719b;
                }
            }

        }
    }


    button.btn.btn-default.recodash_Blank_template_btn {
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 3px 10px 5px 10px;
        background-color: #eeeeee;
        color: #000;
    }
    button.btn.btn-default.recodash_gstr1blanktemp_btn {
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 5px 10px 7px 10px;
        background-color: #eeeeee;
        color: #000;
        width: 100%;
    }

    span.recodash_Blank_template_orbox {
        padding: 0px 5px;
    }

    h4.importtextortextfs {
        position: relative;
        line-height: 5;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }
    span.textdate_bold {
        font-weight: 700;
    }


    table.table.table-striped.recodash_gstr1_table {
        thead tr th {
            font-size: 12px;
            text-align: center;
            border-top: 0px;
            font-weight: 500;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        & tr td{
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.75rem;
            text-align: center;

            & img{
                cursor:pointer;
            }
        }

        td.recodash_gsr1tb_textclr_coral {
            color: #fc7d72;
        }
        span.recodash_gsr1tb_textclr_coral {
            color: #fc7d72;
        }

        button.btn.btn-default.recodash_gstr1_importbtn {
            font-size: 11px;
            font-weight: 500;
            border: 0px;
            border-radius: 40px;
            padding: 2px 10px 4px 10px;
            background-color: #31719b;
            color: #fff;
        }
        button.btn.btn-default.recodash_gstr1_importcancelbtn {
            font-size: 12px;
            font-weight: 500;
            border: 0px;
            border-radius: 40px;
            padding: 3px 10px 5px 10px;
            background-color: #dddd;
            color: #222;
        }
    }

  
    p.importformat_textfs {
        font-size: 12px;
        font-weight: 500;
        padding: 6px 0px 0px 5px;
        margin-bottom: 2px ;
    }
    p.recodash_notetextfs {
        font-size: 12px;
        font-weight: 500;
        padding: 0px 0px 0px 5px;
        color: #939393;
        font-style: italic;
    }


    & .card.recodash_tally_cardbox {
        background-color: aliceblue;
        padding: 5px 10px 5px 10px;

        & .card.tally_cardbox span.tally_config_textfs {
            font-size: 14px;
            font-weight: 700;
        }

        & .btn.btn-default.tally_sync_btnbgclr {
            background-color: #31719b;
            border-color: #31719b;
            padding: 2px 15px 4px 15px;
            border-radius: 40px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
        }

        & span.tallyauto_sync_textfs.tallyauto_sync_border {
            background-color: #31719b;
            border-color: #31719b;
            padding: 4px 10px 6px 10px;
            border-radius: 40px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            top: 0px;
            position: relative;
            cursor: pointer;

            
                /*============ switch btn toggle css ===========*/

                label.switch.autosync_togglebtn {
                    position: relative;
                    top: 0.21rem;
                    width: 23px;
                    height: 11px;
                
                    .slider.round {
                    border-radius: 7px;
                    background-color: #ccc;
                    }
                }
                
                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
                
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #31719b;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
                
                label.switch.autosync_togglebtn {
                    .slider:before {
                    position: absolute;
                    content: "";
                    background-color: #fc7d72;
                    -webkit-transition: .4s;
                    transition: .4s;
                    left: 4px;
                    height: 7.3px;
                    width: 4px;
                    top: 2px;
                    bottom: 2.1px;
                    }
                
                    input:checked + .slider:before {
                    left: -10px;
                    }
                }
                
                input {
                    &:checked + .slider {
                    background-color: #fff !important;
                    }
                
                    &:focus + .slider {
                    box-shadow: 0 0 1px #2196F3;
                    }
                
                    &:checked + .slider:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                    }
                }
                
                /* Rounded sliders */
                
                .slider.round {
                    border-radius: 34px;
                
                    &:before {
                    border-radius: 34px;
                    }
                }
                
                td.textclrblue {
                    color: #31719b;
                }
                
                /*============ end switch btn toggle css ===========*/
        }
    }


    .card.recodash_tallyconfg_card {
        background-color: #efefef;
        padding: 7px 10px;

        & p.tally_config_Ginfofs {
            font-size: 14px;
            font-weight: 500;
            font-style: italic;
        }

        & .tally_config_textfs {
            font-size: 12px;
            font-weight: 500;
        }

        & ul.importbooksdt_tally_listbox li {
            line-height: 22px;
            list-style: none;
            font-size: 12px;

            & span.tally_listbox_checkicon_clrblue {
                color: #31719b;
                font-size: 12px;
                font-weight: 500;
            }
        }

        button.btn.btn-default.Unmapbtnbgclr {
            background-color: #31719b;
            border-color: #31719b;
            padding: 2px 15px 4px 15px;
            border-radius: 40px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
        }
    }

    .card.recodash_importbooksdata_excelbox {
        text-align: center;
        font-weight: 700;
        padding: 18px 15px;
        background: linear-gradient(181deg, #8eb6b3 5%, #fff1d7);
        margin: auto;
    }
    .card.recodash_importbooksdata_tallybox {
        text-align: center;
        font-weight: 700;
        padding: 10px 15px;
        background: linear-gradient(182deg, #fdb6ae -100%, #ffe2e4);
        margin: auto auto;
        cursor: pointer;

        & h4.recodash_tallywithconfig_titlefs {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0.8rem;
        }
    }

    button.btn.btn-default.resendmail_btnbgclr {
        background-color: #31719b;
        border-color: #31719b;
        padding: 2px 15px 4px 15px;
        border-radius: 40px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
    }

    button.btn.btn-default.recodash_import_browse_btn {
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        padding: 3px 20px 5px 20px;
        background-color: #eeeeee;
        color: #000;
    }
    button.btn.btn-default.recodash_filename_btn {
        font-size: 12px;
        font-weight: 500;
        border: 1px solid #ddd;
        width: 100%;
        text-align: left;
    }
    span.btn.btn-default.recodash_filenameremove_btn {
        font-size: 12px;
        font-weight: 500;
        background-color: red;
        color: #fff;
        border: 0px;
        border-radius: 40px;
        padding: 2px 10px 4px 10px;
    }


}

.modal.recodash_importbooksdata_popup.show {
    background-color: #222222b3;
}

button.otherfieldset_importbtn {
    background-color: #31719b;
    border-color: #31719b;
    padding: 2px 15px 4px 15px;
    border-radius: 40px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

.modal.importhsnoutwordsup_modal.show .modal-dialog {
    margin: 3rem auto auto auto;
}

button.btn.btn-default.recodash_gstrdropfile_btn {
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    border: 2px dashed #eee;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    width: 100%;
    border-radius: 40px;
}

