#background{
    display:block;
    position:fixed;
    width:100%;
    height:100%;
    background-color: #ffffff00;
    
  }

  #overlay {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999999;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
  }
   
  #logocontainer{
    display:block;
    position:fixed;
    top:50%;
    left:50%;
    width:26vh;
    height:22vh;
    margin-left:-17vh;
    margin-top:-17vh;
    overflow:hidden;
    transition:background-color 500ms;
    cursor:pointer;
    z-index: 3;
  }
  #pelogo {
    display: block;
    position: absolute;
    left: 2vh;
    top: 2vh;
    width: 22vh;
    height: 18vh;
    background-size: contain;
    font-size: 10vh;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 24vh;
    background-color: #ffffff;
    animation: animationbox 2s ease-out infinite alternate;
    background-size: 100% 200%;
    border: 1px solid #eaeaea42;
  }
  
  .loader{
    display:block;
    position:absolute;
    background-color:#31719b;
    
  }
  
  /*Pretty complex animation, so each side needs its own @keyframes.*/
  
  @keyframes slide0{
    50%{ background-color:#31719b;}
    100%{background-color:#fc7d72;}
  }
  
  
  @keyframes slide1{
  0%{ background-color:#fc7d72; }
    50%{width:24vh; margin-left:0;background-color:#31719b;}
    100%{margin-left:24vh;background-color:#31719b;}
  }
  
  @keyframes slide2{
    50%{height:24vh; margin-top:0;background-color:#31719b; }
    100%{margin-top:24vh;background-color:#31719b;}
  }
  
  @keyframes slide3{
    0%{ background-color:#31719b; }
    50%{width:24vh; margin-right:0;background-color:#fc7d72;}
    100%{margin-right:24vh;background-color:#fc7d72;}
  }
  
  @keyframes slide4{
    50%{height:32vh; margin-bottom:0;background-color:#fc7d72;}
    100%{margin-bottom:32vh;background-color:#31719b;}
  }
  
    
  @keyframes animationbox {
    0%   {background-color:#ffffff;   }
    50%  {background-color:#ffffff;    }
    
  }