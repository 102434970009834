.bellicon_panelbox_innerbody {
  text-align: left;
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 6rem;
  top: 3px;
  position: relative;
  border-top: 1px solid #eee;
}

button.bellicon_innerbox {
  border: 0;
  padding: 7px 12px 8px!important;
  border-radius: 50%;
  font-size: 14px;
  color: #31719b;
  top: 2px;
  position: relative;
  -webkit-animation: animate 2s linear;
  animation: animate 2s linear;
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
  line-height: inherit;
}
span.header_rightside_bellicon {
  padding: 0px !important;
}

@keyframes animate {
  0% {
      box-shadow: 0 0 0 0 rgb(213 213 213 / 30%), 0 0 0 0 rgb(241 232 234 / 70%);
  }
  40% {
      box-shadow: 0 0 0 0 rgb(213 213 213 / 30%), 0 0 0 0 rgb(241 232 234 / 70%);
  }
  80% {
      box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
  }
}



button.bellicon_innerbox:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
span.header_rightside_bellicon_dotredclr_icon {
  position: absolute;
  top: -0.7rem !important;
  left: 0.5rem;
  z-index: 9;
}
span.header_rightside_bellicon_dotredclr_icon svg {
  font-size: 8px;
  color: #fc7d72;
}

.bellicon_panelbox {
  position: fixed;
  border: 1px solid #ddd;
  width: 0px;
  padding: 0px 0px;
  background-color: #fff;
  height: 100%;
  right: -2px;
  top: 58px;
  border-top: 0px;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%) !important;
  transition: 0.5s;
  animation-duration: 5s;
}

.gstr_notifict__box {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  width: 100%;
  position: relative;
}
span.gstr_notifict_titletextfs {
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 10px 5px 10px !important;
}
.headervelliconbox_closebtn {
  padding: 0px 5px 0px 0px;
  margin: 0px;
  font-size: 18px;
  position: absolute;
  right: 0px;
  top: -9px !important;
  font-weight: 700;
  cursor: pointer;
}

.bellicon_panelbox .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

li.ulli_bootombor_grayclr {
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 0px 0px 15px #f1f1f1;
  cursor: pointer;
}
.bellicon_contentbox ul li.libgclr_white {
background-color: #fff;
}
.bellicon_contentbox ul li.libgclr_lightyellow {
  background-color: #fffbd7;
}
.bellicon_contentbox ul li{
  font-size: 11.5px;
  font-weight: 500;
  color: #31719b !important;
  padding: 6px 10px !important;
}

p.vellicontextfs {
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0px;
  padding-left: 11px !important;
  color: #333;
}

p.uploadstatustext {
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0px;
  padding-left: 11px !important;
  color: black;
}
p.rightside_contentst {
  margin-bottom: 3px;
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  font-style: italic;
  color: #b9b0b0;
}
.belliconpanel_textoverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 100px;
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: middle;
  padding: 0px !important;
}
p.bootmtext_belliconpanel_textoverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 360px;
  display: inline-block;
  vertical-align: bottom;
}
p.rightside_cont_showmore {
  margin-bottom: 0px;
  font-size: 11px;
  font-weight: 500;
  text-decoration: underline;
  color: #31719b;
  cursor: pointer;
  font-style: italic;
}
span.monthtextblod {
  font-weight: 700 !important;
  color: #212c32;
  padding: 0px 0px !important;
}

span.clend_iconfsclr {
  cursor: pointer;
  font-size: 10px;
  color: #31719b;
}

span.errortextfsclr {
  font-weight: 700;
  color: red;
}
span.clickviewgstins {
  color: #31719b;
  cursor: pointer;
  text-decoration: underline;
}
span.inprogresstextcle {
  color: #f78a01;
  font-weight: 500;
}
span.succtxetclr {
  color: #689e39;
  font-weight: 500;
}
.nonotification_imgbox img {
  padding: 25px 0px 5px 0px;
}
p.nonotificat_boldtext {
  font-size: 12px;
  font-weight: 700;
  padding: 15px 0px 1px 0px;
}
p.nonotificat_textgrayclrfs {
  font-size: 12px;
  color: #bdbdbd;
  font-weight: 500;
}

/* Center the vellnotification_boxload *
#vellnotification_boxload {
position: absolute;
  left: 70%;
  top: 50%;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin: -76px 0 0 -76px;
 border: 1px solid #f3f3f3;
border-radius: 50%;
border-top: 1px solid #3498db;
animation-name: zoomIn;
  animation-iteration-count: infinite;
  transition: all 900ms ease;
  transform: scale(1.5) rotate( 360deg );
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
background-color:#eee;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

/* Add animation to "page content" *
.animate-bottom {
position: relative;
-webkit-animation-name: animatebottom;
-webkit-animation-duration: 1s;
animation-name: animatebottom;
animation-duration: 1s
}

@-webkit-keyframes animatebottom {
from { bottom:-100px; opacity:0 } 
to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
from{ bottom:-100px; opacity:0 } 
to{ bottom:0; opacity:1 }
}

#myDiv {
display: none;
text-align: center;
}
*/
/* end vellnotification_boxload box*/



/* ================= start media code =========== */
@media (min-width: 320px) and (max-width: 767px){
  .bellicon_panelbox {
    max-width: 310px;
  }
  .nav.navbar.header_mobile_width .bellicon_panelbox {
    top: 9.4rem;
    right: 3px;
    max-width: 287px;
  }
  nav.navbar.comp_header_mobile_width .bellicon_panelbox {
    top: 9.4rem;
    right: 3px;
  }
  .bellicon_contentbox {
    position: relative;
    white-space: break-spaces;
    text-align: left;
  }
  .bellicon_contentbox ul li.libgclr_lightyellow {
    padding: 0px 124px 0px 14px !important;
  }
  span.gstr_notifict_titletextfs {
    float: left;
    text-align: left;
    left: 0px;
    padding: 0px 7px 0px 11px !important;
  }
  .gstr_notifict__box {
    padding: 0px 10px 0px 10px;
  }
}
@media (min-width: 768px) and (max-width: 820px){
  .bellicon_panelbox {
    top: 3.6rem;
}
nav.navbar.comp_header_mobile_width .bellicon_panelbox {
  top: 3.6rem;
}
span.gstr_notifict_titletextfs {
  left: 0rem;
  display: flex !important;
}
.headervelliconbox_closebtn {
  top: -5px!important;
  z-index: 0;
  left: 23rem;
}
.gstr_notifict__box {
  display: contents;
}


}

@media (min-width: 821px) and (max-width: 912px){
  .bellicon_panelbox {
    top: 3.6rem;
}
  span.gstr_notifict_titletextfs {
    left: 0rem;
    display: flex !important;
  }
  .headervelliconbox_closebtn {
    top: -5px!important;
  }

}


.isOpen{
  width: 400px;
  display: block;
}
.isClose{
  width: 0px;
  display: none;
  right: 0px!important;
}


