body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", !important;
}

h4.reco_dashboard_maintitlefs {
    font-size: 1.2rem;
    font-weight: 500;
}

.sort-desc {
    .k-grid-header .k-i-sort-desc-sm {
        position: relative;
        bottom: 0px;
        right: 0px;
    }
}

th.overflowsuppname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.disabled-month {
    color: #ccc;
    pointer-events: none;
}

/* ========== fieldset css =================*/
fieldset {
    display: block;
    margin: 9px 2px 2px 2px !important;
    background-color: #fff;
    padding: .35em .75em .625em !important;
    border: 2px groove (internal value);

}

legend {
    display: block;
    padding: 0 5px;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0rem !important;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

.reco_dashboard_invoicesbox_inline {
    display: flex;

    & span.card.reco_dashboard_invoicesbox_card {
        border: 1px solid #141414bf;
        box-shadow: 0px 1px 5px #141414bf;
        margin: 0px 5px 0px 5px;
        min-width: 19%;
        border-radius: 0px !important;

        & h5.reco_dashboard_invoicesbox_titlefs {
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            margin: 0px;
            padding: 8px 3px;
            background-color: #ffdfb1;
        }

        table.table.table-bordered.reco_dashboard_invoicesbox_tb {
            tr td {
                padding: 0.25rem 0.2rem;
                font-size: 12px;
                font-weight: 500;
            }

            & td.books_bgclr_blue {
                background-color: #31719b;
                color: #fff;
            }

            & td.nobox_bgclr_white {
                background-color: #fff;

                
            }
        }

    }

    & span.card.reco_dashboard_invoicesbox_card:hover {
        border: 1px solid rgb(173 173 173 / 75%);
        box-shadow: 0px 6px 15px rgb(173 173 173 / 75%);
        margin: 0px 5px 0px 5px;
        min-width: 19%;
        border-radius: 0px !important;
    }
}

.reco_dashboard_btnbox {
    position: relative;
    vertical-align: middle;

    button.btn.btn-default.reco_dashboard_invoicesbox_btnbox {
        background-color: #31719b;
        color: #fff;
        padding: 3px 10px 5px 10px;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        vertical-align: -webkit-baseline-middle;
        width: 130px;
        text-align: center;

        & span.recodash_iconalign {
            vertical-align: text-bottom;
            float: left;
        }
    }

    button.btn.btn-default.reco_dashboard_multilink_btnbox {
        background-color: #31719b;
        color: #fff;
        padding: 3px 22px 5px 22px;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        vertical-align: -webkit-baseline-middle;
    }

    button.btn.btn-default.reco_dashboard_tosupp_btnbox {
        background-color: #31719b;
        color: #fff;
        padding: 3px 18px 5px 18px;
        font-size: 12px;
        font-weight: 500;
        border: 0px;
        border-radius: 40px;
        vertical-align: -webkit-baseline-middle;
    }
}

button.btn.btn-default.reco_dashboard_manyinvoicelink_btnbox {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    border: 0px;
    padding: 4px 5px 6px;
    font-size: 10px;
    font-weight: 500;

    & span.manyinvoice_iconbgclr {
        background-color: #fff;
        padding: 3px 4px;
        border-radius: 40px;
        position: relative;
        top: 1px;
    }
}
button.btn.btn-default.reco_dashboard_emaildata_btnbox {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    border: 0px;
    padding: 3px 7px 5px;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: 6px;
}

.card.reco_dashboard_supp_comm_box {
    border: 1px solid #fc7d72;
    padding: 3px 3px 6px 3px;
    border-radius: 0px;
    cursor: pointer;

    & h4.reco_dashboard_supp_comm_titlefs {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        color: #31719b;
    }
    & p.reco_dashboard_supp_comm_textfs {
        font-size: 12px;
        font-weight: 500;
        color: #fc7d72;
        text-align: center;
    }
    .rightimb {
        cursor: pointer;
    }

    .recodash_bordertdscert {
        background-image: url('../../images/rightsideshape.png');
        background-size: cover;
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        top: auto;
        margin-top: -5px;
        right: -1px;
    }

}

td.text_cursorpointer {
    cursor: pointer;
}

.showmor_textclrfs {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #31719b;
}

fieldset.reco_dashboard_top5supp_fs {
    border: 1px solid #31719b;
    padding-bottom: 0px !important;

    & legend.reco_dashboard_top5supp_leg {
        width: auto;

        & h4 {
            font-size: 1.2rem;
            font-weight: 500;
            position: relative;
            top: -2px;
            padding: 0px 5px;
            margin-bottom: 0px;
        }
    }

    & table.table.table-striped.reco_dashboard_top5supp_tb {
        & thead tr th {
            font-size: 12px;
            font-weight: 500;
            border-top: 0px;
            padding-top: 0px;
            padding-bottom: 5px;
            text-align: center;
        }

        & tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.75rem;
        }
    }



    & p.reco_dashboard_vend_textfs {
        font-size: 12px;
        font-weight: 500;
        color: #616161;
        margin-bottom: 4px !important;
    }
}

fieldset.reco_dashboard_Vendoranalysis_fsborder {
    border: 1px solid #fc7d72;

}

fieldset.reco_dashboard_Conflict_sumtop_fs {
    border: 1px solid #fc7d72;


    & legend.reco_dashboard_Conflict_Summary_leg {
        width: auto;

        & h4 {
            font-size: 1.2rem;
            font-weight: 500;
            position: relative;
            top: -1px;
            padding: 0px 5px;
            margin-bottom: 0px;
        }
    }
}


fieldset.reco_dashboard_Conflict_Summary_fs {
    border: 1px solid #fc7d72;

    & legend.reco_dashboard_Conflict_Summary_leg {
        & .reco_dashboard_Conflict_Summary_innerdiv {
            display: block;

            & .reco_dashboard_Conflict_Summary_leftside {
                float: left;
                padding: 0 5px;

                & h4 {
                    font-size: 1.2rem;
                    font-weight: 500;
                    position: relative;
                    top: 5px;
                    padding: 0px 5px;
                    margin-bottom: 0px;
                }
            }

            & .reco_dashboard_Conflict_Summary_innerhightdiv {
                height: 1px;
                width: 100%;
                position: relative;

            }

            & .reco_dashboard_Conflict_Summary_rightsiddiv {
                float: right;
                padding: 0 5px;

                button.reco_dashboard_Conflict_Summary_ratewiserecobtn {
                    background-color: #31719b;
                    border-color: #31719b;
                    border-radius: 40px;
                    color: #fff;
                    border: 0px;
                    padding: 3px 10px 5px;
                    font-size: 12px;
                    font-weight: 500;
                }
            }

        }

        .reco_dashboard_Conflict_Summary_topMidbor {
            border-top: 1px solid #fc7d72;
            display: flex;
            position: relative;
            margin-top: 18px;
        }

    }


}

table.table.table-striped.reco_dashboard_Conflict_Summary_tb {

    & thead tr th {
        font-size: 12px;
        text-align: center;
        border-top: 0;
        font-weight: 500;
        color: #31719b;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    & tr td {
        font-size: 11px;
        font-weight: 500;
        padding: 0.25rem 0.75rem;
    }

    & td.textclr_blueclick {
        color: #007bff;
        cursor: pointer;
    }

    td.textclr_blueclick:hover {
        text-decoration: underline;
    }

    .recodash_totlebgclr_yell {
        background-color: #fff8dc;
    }

    & tr.recodash_Summary_rowtextclr_grey {
        background-color: #f2f2f2;
    }

    & td.recodash_Summary_textclr_blue {
        color: #31719b;
    }
}

fieldset.reco_dashboard_top5taxrate_fs {
    border: 1px solid #31719b;

    & legend.reco_dashboard_top5taxrate_leg {

        & .reco_dashboard_top5taxrate_innerdiv {
            display: block;

            & .reco_dashboard_top5taxrate_leftside {
                float: left;
                padding: 0 5px;

                & h4 {
                    font-size: 1.2rem;
                    font-weight: 500;
                    position: relative;
                    top: 5px;
                    padding: 0px 5px;
                    margin-bottom: 0px;
                }
            }

            & .reco_dashboard_top5taxrate_innerhightdiv {
                height: 1px;
                width: 100%;
                position: relative;

            }

            & .reco_dashboard_top5taxrate_rightsiddiv {
                float: right;
                padding: 0 5px;

                h4.reco_dashboard_top5taxrate_rotabox {
                    cursor: pointer;
                }
            }

        }

        .reco_dashboard_top5taxrate_topMidbor {
            border-top: 1px solid #31719b;
            display: flex;
            position: relative;
            margin-top: 18px;
        }

        .reco_dashboard_top5taxrate_topMidbor_without_btn {
            border-top: 1px solid #31719b;
            display: flex;
            position: relative;
            margin-top: 10px;
        }
    }

    .hide {
        display: none;
    }

    .reco_dashboard_flipbox {
        min-height: 250px;
        position: relative;
        width: 100%;
        text-align: center;
        transition: transform 2.5s;
        transform-style: preserve-3d;
        animation: 0.5s elase;

    }

    .roate_div {
        transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .flip-card-back {
        transform: rotateY(180deg);
    }


    .suppwithmajordiff_midborder {
        border-top: 1px solid #fc7d72 !important;
    }

    .k-widget.k-grid.table.table-striped.reco_dashboard_top5taxrate_tb {

        & thead tr th {
            font-size: 12px;
            text-align: center;
            border-top: 0;
            font-weight: 500;
            color: #31719b;
            padding-top: 5px;
            padding-bottom: 5px;
            vertical-align: baseline;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }

        & tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.75rem;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }
    }

    & table.table.table-striped.reco_dashboard_top5taxrate_tb {

        & thead tr th {
            font-size: 12px;
            text-align: center;
            border-top: 0;
            font-weight: 500;
            color: #31719b;
            padding-top: 5px;
            padding-bottom: 5px;
            vertical-align: baseline;
        }

        & tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.75rem;
        }

        & tr.reco_dashboard_top5taxrate_rowbgclr_grey {
            background-color: #f2f2f2;
        }

        & td.textclr_blue {
            color: #31719b;
        }

        & td.overflowsuppname {
            max-width: 105px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            cursor: pointer;
            white-space: nowrap;
        }
    }
}

fieldset.suppwithmajordiff_fsborder {
    border: 1px solid #fc7d72;
}

.modal.recodash_rateconflict_modalbox.show .modal-dialog {
    max-width: 1550px;
    margin: 2rem auto auto auto;

    & .modal-header.recodash_rateconflict_header {
        background-color: #eee;
        padding: 0px 15px;

        & h4.modal-title.recodash_rateconflict_title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px 4px 0px;
        }
    }

    table.table.table-striped.recodash_rateconflict_tb {
        & thead tr th {
            border-top: 0px;
            font-size: 12px;
            font-weight: 500;
            padding-bottom: 0px;
            padding-top: 0px;
            text-align: center;
        }

        & tr td {
            font-size: 11px;
            padding: 0.25rem 0.3rem;
            font-weight: 500;
        }

        tr.rateconflict_toprow th {
            border-bottom: 0px !important;
            padding-bottom: 0px !important;
        }

        tr.rateconflict_secondrow th {
            border-bottom: 0px !important;
            padding-bottom: 0px !important;
        }

        tr.rateconflict_thirdrow th {
            padding-bottom: 5px !important;
        }

        .rateconflict_bgclr_yell {
            background-color: #f4f2fb !important;
        }

        .rateconflict_bgclr_grey {
            background-color: #fdf4e7 !important;
        }

        .rateconflict_bgclr_purpl {
            background-color: #fff8dc !important;
        }

        tr.invoicewisereco_textclr_blue th {
            color: #00f;
            font-size: 9px !important;
            font-weight: 500;
        }

        td.textclr_blue {
            color: #31719b;
            cursor: pointer;
        }

        .textclr_red {
            color: red !important;
        }

        span.green_circleclr {
            color: #8bc34a;
            font-size: 8px;
        }
    }

}

fieldset.reco_dashboard_monthwisetaxval_fs {
    border: 1px solid #31719b;

    & legend.reco_dashboard_monthwisetaxval_leg {
        width: auto;

        & h4 {
            font-size: 1.2rem;
            font-weight: 500;
            position: relative;
            top: -2px;
            padding: 0px 5px;
            margin-bottom: 0px;
        }
    }

    .hide {
        display: none;
    }

    .reco_dashboard_flipbox {
        min-height: 225px;
        position: relative;
        width: 100%;
        text-align: center;
        transition: transform 2.5s;
        transform-style: preserve-3d;
        animation: 0.5s elase;

    }

    .roate_div {
        transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .flip-card-back {
        transform: rotateY(180deg);
    }


    .suppwithmajordiff_midborder {
        border-top: 1px solid #fc7d72 !important;
    }

    p.ratetaxsumm_textfsnodata {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }



    & table.table.table-striped.reco_dashboard_top5taxrate_tb {

        & thead tr th {
            font-size: 12px;
            text-align: center;
            border-top: 0;
            font-weight: 500;
            color: #31719b;
            padding-top: 5px;
            padding-bottom: 5px;
            vertical-align: baseline;
        }

        & tr td {
            font-size: 11px;
            font-weight: 500;
            padding: 0.25rem 0.75rem;
        }

        & tr.reco_dashboard_top5taxrate_rowbgclr_grey {
            background-color: #f2f2f2;
        }

        & td.textclr_blue {
            color: #31719b;
        }

        & td.overflowsuppname {
            max-width: 105px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            cursor: pointer;
            white-space: nowrap;
        }
    }
}

fieldset.monthwisetax_fsboder {
    border: 1px solid #fc7d72;
}

fieldset.reco_dashboard_Settings_fs {
    border: 1px solid #fc7d72;

    & legend.reco_dashboard_Settings_leg {
        width: auto;

        & h4 {
            font-size: 1.2rem;
            font-weight: 500;
            position: relative;
            top: -2px;
            padding: 0px 5px;
            margin-bottom: 0px;
        }
    }

    h4.recodash_tolerance_textfs {
        font-size: 1rem;
        font-weight: 500;
    }

    form.form.form-row.recodash_setting_frmbox {
        & label {
            font-size: 12px !important;
            font-weight: 500;
        }

        & input.form-control {
            font-size: 12px;
            font-weight: 500;
        }

        input.form-control:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25);
        }


        button.btn.btn-default.reco_dashboard_Settings_donebtn {
            background-color: #31719b;
            color: #fff;
            padding: 3px 15px 5px 15px;
            font-size: 12px;
            font-weight: 500;
            border: 0px;
            border-radius: 40px;
        }

    }
}

.modal.recodash_top5rateconf_modalbox.show .modal-dialog {
    margin: 3rem auto auto auto;
    max-width: 800px;



    & .modal-header.recodash_top5rateconf_header {
        background-color: #eee;
        padding: 0px 15px 0px 15px;

        & h4.modal-title.recodash_top5rateconf_title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px;
        }

        & button.close {
            position: relative;
            top: 2px;
        }

        & button:focus {
            outline: 1px dotted;
            outline: 0px auto -webkit-focus-ring-color;
        }
    }

    & table.table.table-striped.recodash_top5rateconf_modal_table {
        & thead tr th {
            border-top: 0;
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            background-color: #fff;
            vertical-align: initial;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        & tr td {
            font-size: 11px;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
            vertical-align: baseline;
        }
    }

    & p.expreco_recodash_show_more_fs {
        color: #31719b;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }

    & button.btn.btn-default.reco_dashboard_donebtn {
        background-color: #31719b;
        border-color: #31719b;
        border-radius: 40px;
        color: #fff;
        border: 0px;
        padding: 3px 20px 5px;
        font-size: 12px;
        font-weight: 500;
    }

}

button.reco_dashboard_ratewiserecobtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    border: 0px;
    padding: 3px 10px 5px;
    font-size: 12px;
    font-weight: 500;
}

.recodash_gifimgmainbox {

    & span.recodash_gifimgmainbox_bottomleft {
        background-color: rgba(196, 196, 196, 0.48);
        position: fixed;
        width: 5%;
        border-radius: 0px 205px 0px 0px;
        padding: 0px 124px 0px 19px;
        margin-left: -1.1rem;
        transform: rotate(0deg);
        bottom: 0px;
        cursor: pointer;
        overflow: hidden;
        transition: all 2s ease 0s;
    }

    & span.recodash_gifimgmainbox_bottomleft img {
        position: relative;
        top: 1rem;
        bottom: 0px;
    }
}

.recodash_gifimgmainbox:hover .recodash_gifimgmainbox_bottomleft {
    transform: scale(1.7);
    bottom: 2.1rem;
    margin-left: 1.1rem;
    box-shadow: rgb(134, 134, 134) 0px 2px 10px;
    overflow: hidden;
    transition: all 2s ease 0s;
}

.recodash_textclr_matched {
    color: rgb(0, 200, 0) !important;
}

.recodash_textclr_partia {
    color: rgb(246, 89, 79) !important;
}

.recodash_textclr_inbooksonly {
    color: rgb(199, 77, 77) !important;
}

.recodash_textclr_in2bonly {
    color: rgb(51, 105, 30) !important;
}



fieldset.recodash_amendedinvoicesumm_fs {
    border: 1px solid #31719b;

    & legend.recodash_amendedinvoicesumm_leg {
        width: auto;

        & h4 {
            font-size: 1.2rem;
            font-weight: 500;
            padding: 0px 5px;
        }
    }

    & table.table.table-striped.recodash_amendedinvoicesumm_tb {
        & thead tr th {
            border-top: 0px;
            font-size: 12px;
            font-weight: 500;
            padding-bottom: 5px;
            padding-top: 5px;
            text-align: center;
        }

        & tr td {
            font-size: 11px;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
        }

        & td.recodash_amendedinvoicesumm_textover {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            max-width: 250px;
        }
    }
}

.modal.recodash_amendedinvoicesumm_modal.show .modal-dialog {
    margin: 3rem auto auto auto;
    max-width: 780px;
    z-index: 1050;

    & .modal-header.recodash_amendedinvoicesumm_header {
        background-color: #eee;
        padding: 0px 15px;

        & h4.modal-title.recodash_amendedinvoicesumm_title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px 4px 0px;
        }

        & button.close {
            position: relative;
            top: 2px;
        }

        & button:focus {
            outline: 1px dotted;
            outline: 0px auto -webkit-focus-ring-color;
        }
    }


    & table.table.table-striped.recodash_amendedinvoicesumm_table {
        & thead tr th {
            border-top: 0px;
            font-size: 12px;
            font-weight: 500;
            padding-bottom: 5px;
            padding-top: 5px;
            text-align: center;
        }

        & tr td {
            font-size: 11px;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
        }

        & td.textclr_blue {
            color: #31719b;
            cursor: pointer;
        }
    }
}

.modal.recodash_AmendedInvoicesDetails_modal.show {
    background-color: #222222bf;

    & .modal-dialog {
        margin: 3rem auto auto auto;
        max-width: 1480px;

        & .modal-header.recodash_AmendedInvoicesDetails_header {
            background-color: #eee;
            padding: 0px 15px;

            & h4.modal-title.recodash_AmendedInvoicesDetails_title {
                width: 100%;

                .recodash_AmendedInvoicesDetails_checkbox_left {
                    font-size: 1rem;
                    font-weight: 500;
                    position: relative;
                    top: 5px;

                    .form-check-inline.recodash_AmendedInvoicesDetails_checkbox {
                        vertical-align: middle;
                        font-size: 12px;
                        font-weight: 500;
                        margin: 0px;
                    }
                }


                h5.recodash_AmendedInvoicesDetails_innertitlefs {
                    font-size: 14px;
                    font-weight: 500;
                    padding-top: 9px;
                    margin-bottom: 0px;
                }

                span.recodash_AmenInvDetails_showall_text {
                    font-size: 12px;
                    font-weight: 500;
                    padding-right: 10px;
                    vertical-align: super;
                    position: relative;
                    top: 2px;
                }


                label.switch.recodash_AmenInvDetails_sw {
                    position: absolute;
                    display: inline-block;
                    width: 23px;
                    height: 11px;
                    top: 11px;
                }

                label.switch.recodash_AmenInvDetails_sw input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                label.switch.recodash_AmenInvDetails_sw .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #4c4c4c;
                    transition: .4s;
                }

                label.switch.recodash_AmenInvDetails_sw .slider:before {
                    position: absolute;
                    content: "";
                    height: 7.3px;
                    width: 3px;
                    top: 2px;
                    left: 3px;
                    background-color: rgb(242 242 242);
                    transition: .4s;
                    outline-color: rgb(242 242 242);
                }

                label.switch.recodash_AmenInvDetails_sw input:checked+.slider {
                    background-color: #8bc34a;
                }


                label.switch.recodash_AmenInvDetails_sw input:checked+.slider:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                    left: -9px;
                }

                /* Rounded sliders */
                label.switch.recodash_AmenInvDetails_sw .slider.round {
                    border-radius: 34px;
                }

                label.switch.recodash_AmenInvDetails_sw .slider.round:before {
                    border-radius: 16%;
                }
            }
        }


        & table.table.table-striped.recodash_AmendedInvoicesDetails_table {
            & thead tr th {
                border-top: 0px;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                padding: 5px 0.5px;
            }

            tr.recodash_amend_toproworigin th {
                border-bottom: 1px solid #ddd;
            }

            & tr td {
                font-size: 11px;
                padding: 0.25rem 0.5rem;
                font-weight: 500;
            }

            & .cellbgclr_lightyell {
                background-color: rgb(253, 244, 231);
            }

            & .cellbgclr_grey {
                background-color: rgb(244, 242, 251);
            }

            tr.recodash_amend_toprow th {
                border-bottom: 0px !important;
                padding-bottom: 0px !important;
            }

            tr.topamt_textclr th {
                color: #00f;
                font-size: 9px !important;
            }
        }
    }
}

td.textclr_blue {
    color: #31719b;
    cursor: pointer;
}

//css for conflict summery:-

// reco_dashboard.scss

.reco_dashboard_Conflict_sumtop_fs {
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

.reco_dashboard_Conflict_Summary_leg {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

// .table-responsive {
//     overflow-x: auto;
// }

// .table {
//     width: 100%;
//     margin-bottom: 1rem;
//     background-color: transparent;
// }

// .table th,
// .table td {
//     padding: 0.75rem;
//     vertical-align: top;
//     border-top: 1px solid #dee2e6;
//     text-align: center; // Add this line to center-align all table data cells
// }

// .table thead th {
//     vertical-align: bottom;
//     border-bottom: 2px solid #dee2e6;
// }

// .table tbody+tbody {
//     border-top: 2px solid #dee2e6;
// }

// .table-striped tbody tr:nth-of-type(odd) {
//     background-color: rgba(0, 0, 0, 0.05);
// }

.recodash_Summary_rowtextclr_blue {
    color: blue;
}

.recodash_Summary_rowtextclr_grey {
    color: black;
}

.recodash_Summary_textclr_blue {
    color: blue;
    cursor: pointer;
}

.text_cursorpointer {
    cursor: pointer;
}

.textclr_blueclick {
    color: blue;
    cursor: pointer;
}

.disabled-month {
    color: grey;
}

.recodash_totlebgclr_yell {
    background-color: yellow;
    text-align: center; // Add this line to center-align the total cells
}

.k-widget.k-grid.table-striped.vendorwiseinvoices .k-grid-content.k-virtual-content {
    max-height: 580px !important;
    // overflow-y: auto!important;
}

.k-widget.k-window.gstr9reportspopup .k-window-content,
.k-prompt-container {
    padding: 5px 0px;
    overflow-x: hidden;
}

.sorting_disabled .k-link {
    cursor: default !important;
}

.modal_amendedinvoice {
    .k-grid-header {

        .k-i-sort-desc-sm,
        .k-i-sort-asc-sm {
            position: absolute;
            bottom: 6px;
            right: 9px;
        }
    }
}

.k-widget.k-grid.table-striped.vendor-wise-pending-invoice-dashbaord .k-grid-content.k-virtual-content {
    overflow-y: hidden !important;
}

.k-widget.k-grid.table-striped.supplier-delaying-r1-dashbaord .k-grid-content.k-virtual-content {
    overflow-y: hidden !important;
}

.k-widget.k-grid.table-striped.supplier-with-major-diff-dashboard .k-grid-content.k-virtual-content {
    overflow-y: hidden !important;
}

.k-widget.k-grid.table-striped.tax-rates-major-diff-dashboard .k-grid-content.k-virtual-content {
    overflow-y: hidden !important;
}


fieldset.supplierwithmajordiff_dashboard {
    margin-top: 0px !important;
}

fieldset.reco_dashboard_taxvaltrend_fs {
    border: 1px solid #31719b;
}

.reco_dashboard_taxablevaltrend_innerdiv {
    display: block;

    & .reco_dashboard_taxablevaltrend_leftside {
        float: left;
        padding: 0 5px;

        & h4 {
            font-size: 1.2rem;
            font-weight: 500;
            position: relative;
            top: 5px;
            padding: 0px 5px;
            margin-bottom: 0px;
        }
    }

    & .reco_dashboard_taxablevaltrend_innerhightdiv {
        height: 1px;
        width: 100%;
        position: relative;

    }

    & .reco_dashboard_taxablevaltrend_rightsiddiv {
        float: right;
        padding: 0 5px;

        h4.reco_dashboard_taxablevaltrend_rotabox {
            cursor: pointer;
        }
    }

}

.reco_dashboard_taxablevaltrend_topMidbor {
    border-top: 1px solid #31719b;
    display: flex;
    position: relative;
    margin-top: 18px;
}


.windowwrapperfix_widthpopup {

    .k-window-titlebar.k-dialog-titlebar {
        padding: 4px 11px;
        background-color: #eee;
    }

    // .k-icon.k-i-close::before {
    //     color: white;
    // }
    .k-dialog-titlebar{
      color: black;
    }

    &.k-window-content,
    &.k-prompt-container {
        padding: 5px 0px;
        overflow-x: hidden;
    }

    .ammend_invoicedet_heading_title {
        .heading_checkbox {
            display: flex;
            align-items: center;
    
            label {
                margin-bottom: 0;
                color: black
            }
        }
    
        .ammend_invoicedet_heading {
            display: flex;
            justify-content: space-evenly;
            margin: auto;
        }
    
        .headingshowall_label {
            margin-right: 29px;
        }
    
        span.headertogglebtn {
            top: 7.6px;
            right: 25px;
        }
        
    }
    .k-button:hover, .k-button.k-state-hover {
        background-color: #eee !important;
        border-color: #eee !important;
        color: black !important;
        background-image: none !important;
    }
    .k-window-content {
        padding: 5px;
        overflow-x: hidden;
    }
    .modal_amendedinvoice .k-grid-header{
        margin-right: 17px;
        background: white;
    }
}
.graphchart_showing{
  .k-chart-surface{
    g text{
        fill: black;
    }
  }
}
.traxable-trand-dashbaord {

    .footerclrbggreendesign {
        background: rgb(235, 245, 224);
        font-weight: 700;
        color: rgb(49, 113, 155);
    }

    &.overflow_manage {
        .k-grid-footer {
            padding: 0px 0px 0px 0px !important;
            overflow: hidden;
        }
    }

    .k-grid-content {
        overflow-y: auto;
    }

    .k-grid-footer {
        background: #ebf5e0;

        .k-grid-footer-wrap {
            border-right-color: transparent;
        }
    }

}

.filter_in2b_only{
    vertical-align: text-bottom;
    color: rgb(51, 105, 30);
}
.filter_inbooks_only{
    vertical-align: text-bottom;
    color: rgb(199, 77, 77);
}
.filter_patialymatched_only{
    color:rgb(255, 59, 59);
}
.filter_matched_only{
    color:rgb(0, 200, 0);
}

.textfloat_weight{
    float: right;
    font-weight: 500;
}
.cursor_pointer{
    cursor: pointer;
}

.minwidth90 {
    min-width: 90px;
}

.reco-dashboard-2b-data-warning-box {
    display: inline-block;
    width: 100%;
}

.card.reco-dashboard-2b-data-warning-box-text {

    background-color: #f29d95;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 5px 3px 5px;
    display: inline-block;
    color: black;
}

.bckgrdclblueclr{
    background-color: #f4f2fb;
}
.bckgrdclrcoralclr{
    background-color: #fdf4e7;
}


/* reco dash tic claim card box css*/

.recodashboard_itcclaim_cardbox {
    background: linear-gradient(to right, #31719B, #FC7D72);
    color: white;
    border-radius: 5px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    padding: 2px 3px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
    transform: perspective(1000px) rotateY(0deg);
    

    & h5.recodashboard_itcclaim_titlebox {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 0px;
    }

    table.table.recodashboard_itcclaim_table.mb-0 {
        background-color: transparent;
        
        tr td {
            font-size: 11px;
            font-weight: 500;
            padding: .2rem .3rem !important;
            border-bottom: 1px solid #dee2e6;
            border-top: 0px solid #dee2e6;
            color: #fff;
        }
    }

    .recodashboard_itcclaim_gototracker {
        & .recodashboard_itcclaim_gototrackerlink {
            font-size: 12px;
            font-weight: 700;
            color: #fff;
        }
    }

}

.recodashboard_itcclaim_cardbox:hover {
    transform: perspective(1000px) rotateY(10deg);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}





/* media code */

@media (min-width: 320px) and (max-width: 421px) {
    .reco_dashboard_invoicesbox_inline {
        display: block;
    }
}

@media (min-width: 422px) and (max-width: 640px) {
    .reco_dashboard_invoicesbox_inline {
        display: block;
    }

}

@media (min-width: 913px) and (max-width: 1025px) {

    fieldset.reco_dashboard_top5taxrate_fs legend.reco_dashboard_top5taxrate_leg .reco_dashboard_top5taxrate_innerdiv .reco_dashboard_top5taxrate_leftside h4 {
        font-size: 14px;
    }
    fieldset.reco_dashboard_top5taxrate_fs legend.reco_dashboard_top5taxrate_leg .reco_dashboard_top5taxrate_topMidbor {
        margin-top: 19px;
    }
    .reco_dashboard_flipbox .k-chart.k-widget .k-chart-surface {
        height: auto;
        max-height: 100%;
    }

}
@media (min-width: 1026px) and (max-width: 1171px) {

    fieldset.reco_dashboard_top5taxrate_fs legend.reco_dashboard_top5taxrate_leg .reco_dashboard_top5taxrate_innerdiv .reco_dashboard_top5taxrate_leftside h4 {
        font-size: 1.1rem;
    }
   
    .reco_dashboard_flipbox .k-chart.k-widget .k-chart-surface {
        height: auto;
        max-height: 100%;
    }

}
@media (min-width: 1172px) and (max-width: 1281px) {

    fieldset.reco_dashboard_top5taxrate_fs legend.reco_dashboard_top5taxrate_leg .reco_dashboard_top5taxrate_innerdiv .reco_dashboard_top5taxrate_leftside h4 {
        font-size: 1.1rem;
    }
   
    .reco_dashboard_flipbox .k-chart.k-widget .k-chart-surface {
        height: auto;
        max-height: 100%;
    }

}

@media (min-width: 768px) and (max-width: 1281px) {
    .reco_dashboard_btnbox {
        float: right;
    }
    .card.reco_dashboard_supp_comm_box {
        position: relative;
        top: 7px;
    }
    h4.reco_dashboard_supp_comm_titlefs {
        font-size: 0.8rem;
    }

}

@media (min-width: 1170px) and (max-width: 1370px) {
    h4.reco_dashboard_supp_comm_titlefs {
          font-size: 0.8rem !important;
      }




  
  }

@media (min-width: 1371px) and (max-width: 1495px) {
  h4.reco_dashboard_supp_comm_titlefs {
        font-size: 0.8rem !important;
    }

}

@media (min-width: 1601px) and (max-width: 1921px) {
    .card.reco_dashboard_supp_comm_box {
        width: 90%;
        left: 2rem;
    }
    button.btn.btn-default.reco_dashboard_manyinvoicelink_btnbox {
        min-width: 170px;
    }
    button.btn.btn-default.reco_dashboard_emaildata_btnbox {
        min-width: 170px;
    }
    .reco_dashboard_btnbox {
        position: relative;
        vertical-align: middle;
        top: 11px;
    }
  
  }
