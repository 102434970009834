body{   
    background-color: #ffffff!important;
     font-size: 1rem;
     font-weight: normal ;
     line-height: 1.5;
     color: #212529;
     text-align: left;
     -webkit-font-smoothing: antialiased;
     font-weight: 500 !important;
     font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",;
 }

 
::placeholder{
    color: #9c9c9c !important;
    font-size: 11px;
}

a.dropdown-item.btn.btn-primary:active {
    color: #222;
    background-color: #ffffff;
    border-color: #ffffff;
}.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .1rem rgba(241, 241, 241, 0) !important;
}

.authaccount_innborder {
    border: 1px solid #c3e9f1;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}


.card.gst_authgaccount_cardbox_width {
    margin: auto 5rem;
    

    .gst_factorauth_using_gaccount_leftimageboxbgclr_blue {
        vertical-align: bottom;
        background-color: #c3e9f1 !important;
        padding: 10px 7px 0px 7px;
        max-height: 100%;
        height: 100%;
        border-radius: 7px 0px 0px 7px;

    }

    h4.authgaccount_titlefstopmargin {
        font-size: 21px !important;
        font-weight: 600 !important;
        color: #31719b;
        padding-top: 2rem;
    }

    .authgaccount_titlefstopmargin_paddbox {
        padding: 10px 10px;
    }
}



.card.gst_factorauth_using_gaccount_cardbox {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 10px 10px;

    & .gst_factorauth_using_gaccount_leftimage {
        vertical-align: middle;
    }

    & h4.gst_factorauth_using_gaccount_titlefs {
        font-size: 1.2rem;
        font-weight: 500;
        color: #31719b;
        padding-top: 3rem;
    }

    p.gst_factorauth_using_gaccount_textfsclr {
        font-size: 12px;
        font-weight: 500;
        color: #fc7d72;
        padding-top: 10px;
    }

    button.btn.btn-default.gst_factorauth_gauthbtn_bgclr {
        font-size: 12px;
        font-weight: 500;
        background-color: #fff;
        color: #31719b;
        border: 1px solid #31719b;
        border-radius: 10px;
        padding: 3px 10px 5px 10px;
    }
    button.btn.btn-default.gst_factorauth_gauthbtn_bgclr:hover {
       background-color: #31719b;
        color: #fff;
    }

}



.modal.gst_googleauthentication_module.show .modal-dialog {
    max-width: 400px;
    margin: 3rem auto auto auto;

    .modal-header.gst_googleauthentication_header {
        background-color: #eee;
        padding: 0px 15px;

        & h4.modal-title {
            font-size: 1rem;
            font-weight: 500;
            padding: 4px 0px;
        }

        button.close {
            position: relative;
            top: 2px;
        }
        button:focus {
            outline: 1px dotted;
            outline: 0px auto -webkit-focus-ring-color;
        }
    }

    & h5.gst_googleauthentication_titlefs {
        font-size: 14px;
        font-weight: 500;
    }
    .gst_googleauthentication_listbox {

        ol.gst_googleauthentication_ollistbox {
            padding-left: 13px;
            li {
                font-size: 12px;
                font-weight: 500;
                color: #393939;
            }

        }
    }

    button.btn.btn-default.gst_factorauth_scanbar_nextbtn {
        font-size: 12px;
        font-weight: 500;
        background-color: #fff;
        color: #31719b;
        border: 1px solid #31719b;
        border-radius: 10px;
        padding: 3px 20px 5px 20px;
    }
    button.btn.btn-default.gst_factorauth_scanbar_nextbtn:hover {
        background-color: #31719b;
        color: #fff;
    }
    button.btn.btn-default.gst_factorauth_scanbar_nextbtn:focus {
        outline: 0;
        box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
    }

}

.modal.gst_gauth_verifycode_module.show {
    background-color: rgb(39 39 39 / 83%);

    .modal-dialog {
        margin: 4rem auto auto auto;
        max-width: 370px;

        .modal-header.gst_gauth_verifycode_header {
            background-color: #eee;
            padding: 0px 15px;

            h4.modal-title {

                font-size: 1rem;
                font-weight: 500;
                padding: 4px 0px;
    
            }
        }
        
    }

    p.gst_gauth_verifycode_textfs {
        font-size: 12px;
        font-weight: 500;
    }

    .form-group.gst_gauth_verifycode_inputgrp {
        input.form-control.gst_gauth_verifycode_inputbox {
            font-size: 12px;
            font-weight: 500;
        }
        input.form-control.gst_gauth_verifycode_inputbox:focus {
            border-color: #66afe9;
            outline: 0;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
        }
    }
    button.btn.btn-default.gst_factorauth_scanbar_verifybtn {
        font-size: 12px;
        font-weight: 500;
        background-color: #fff;
        color: #31719b;
        border: 1px solid #31719b;
        border-radius: 10px;
        padding: 3px 20px 5px 20px;
    }
    button.btn.btn-default.gst_factorauth_scanbar_verifybtn:hover {
        background-color: #31719b;
        color: #fff;
    }
    button.btn.btn-default.gst_factorauth_scanbar_verifybtn:focus {
        outline: 0;
        box-shadow: 0 0 0 .0rem rgba(0, 123, 255, .25);
    }
}



@media(min-width:320px) and (max-width: 767px){
    .card.gst_authgaccount_cardbox_width {
        margin: auto 0rem;
    }
}

@media(min-width:768px) and (max-width: 821px){
    .card.gst_authgaccount_cardbox_width {
        margin: auto 0rem;
    }
}