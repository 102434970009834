body {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

/*===================start header css===============*/
header.expreco_headersection {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    background-color: #fff;
    /*--------- media code header box ---------*/
}

header.expreco_headersection .header-sticky {
    position: fixed;
    top: 78px;
    left: 25%;
    z-Index: 99992;
    width: 50%;
    text-align: center;
}

header.expreco_headersection nav.navbar {
    padding: 0px 0px;
}

header.expreco_headersection ul li {
    list-style-type: none;
    display: inline-block;
}

header.expreco_headersection li.nav-item.dropdown.profileuserpaddtop a.nav-link.dropdown-toggle {
    padding-right: 5px;
    padding-left: 4px;
    padding-top: 20px;
}

header.expreco_headersection span.profile_imgbgclr {
    padding: 8px 11px 8px 11px;
    color: #31719b;
    background-color: #f2f2f2;
    border-radius: 50%;
}

header.expreco_headersection span.profile_imgbgclr svg {
    position: relative;
    top: -2px;
    left: 0px;
}

header.expreco_headersection span.activeuserpro svg {
    height: 10px;
    width: 10px;
    float: right;
    top: 3px;
    position: relative;
    display: inline-flex;
    color: #4caf50;
}

header.expreco_headersection span.prousersigupicon img {
    float: right;
    height: 14px;
    width: 14px;
    top: 3px;
    position: relative;
}

header.expreco_headersection .dropdown-menu.show.profileuserdropdshow {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15), 0 5px 20px 0 rgba(21, 27, 38, 0.08);
    box-sizing: border-box;
    border-radius: 1px;
    font-size: 14px;
    margin-top: 6px;
    border-radius: 0;
    max-width: 100%;
    right: 0;
    left: auto;

    & a.dropdown-item.btn.btn-primary {
        font-weight: 500;
    }

    & button.dropdown-item.btn.btn-primary {
        font-size: 12px;
        font-weight: 500;
        border-radius: 0px;
        padding: 0.3rem 0.5rem !important;
    }

    & .dropdown-divider {
        margin: 0.2rem 0;
    }

    & .dropdown-item:hover {
        color: #16181b;
        text-decoration: none;
        background-color: #e9ecef;
        border-radius: 0px;
    }
}

header.expreco_headersection span.tds_boxalign {
    right: 0;
    left: 0.5rem;
    position: relative;
}

header.expreco_headersection svg:not(:host).svg-inline--fa,
header.expreco_headersection svg:not(:root).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
}

header.expreco_headersection .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

header.expreco_headersection .refrsh_sync {
    margin: 0 8px;
    cursor: pointer;
    color: #31719b;
}

header.expreco_headersection span.tds_sync_status {
    text-align: right;
    color: rgb(252, 125, 114);
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    padding-right: 0rem;
}

header.expreco_headersection .expreco_dispinlinerightnav {
    float: right;
    display: inline-flex;
}

// header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox {
//   position: relative;
//   top: -3rem;
// }
header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox span.nav-link-btn.callmebgclr {
    background-color: #31719b;
    color: #fff;
    padding: 1px 4px 3px 4px !important;
    position: relative;
    border-radius: 4px;
    font-size: 13px;
    top: 3px;
    cursor: pointer;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show {
    top: 3.1rem;
    margin: 0px -5rem;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15), 0 5px 20px 0 rgba(21, 27, 38, 0.08);
    box-sizing: border-box;
    font-size: 14px;
    max-width: 100%;
    padding: 0px 1px;
    min-width: 14rem;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .dropdown-item.headerscetbborder {
    font-size: 12px;
    padding: 0.4rem 0.5rem;
    border-bottom: 1px solid #e8e3e3;
    font-weight: 500;
    border-radius: 0px;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .dropdown-item.headerscetbborder:hover {
    background-color: transparent;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .dropdown-item.headerscetbborder span.closeiconright {
    float: right;
    color: #31719b;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .textboxinnersect {
    padding: 0.5rem 0.5rem;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .textboxinnersect p.contentarea {
    font-size: 10.5px;
    color: #909090;
    font-weight: 500;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .textboxinnersect .inputboxarea input.form-control.inputcallme {
    font-size: 12px;
    padding: 0.37rem 0.75rem;
    height: calc(1.2em + 0.75rem + 2px);
    border: 1px solid #c6c2c2;
    outline-color: transparent;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .textboxinnersect .callmebtnbox {
    padding: 0.5rem 0.1rem 0rem 0.1rem;
    text-align: center;
}

header.expreco_headersection .expreco_dispinlinerightnav ul.expreco_ul_callmebox .dropdown-menu.cllmeinnerdown.show .textboxinnersect .callmebtnbox .callmedonebtn {
    background-color: #33729c;
    color: #fff;
    outline-color: transparent;
    padding: 3px 15px 5px 15px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 40px;
}

header.expreco_headersection .expreco_headerlogodispinle {
    display: inline-flex;
    position: relative;
    top: 0px;
}

header.expreco_headersection .expreco_headerlogodispinle ul.compboxtoppadd {
    top: 0.3rem;
    position: relative;
    padding-left: 0;
    margin-bottom: 0px;
}

header.expreco_headersection .expreco_headerlogodispinle ul.compboxtoppadd .infoicongstin {
    display: inline-block;
    cursor: pointer;
}

header.expreco_headersection span.dropdown.expreco_header_dropdownbox {
    width: 68px !important;
    padding: 0;
    font-size: 12px;
}

header.expreco_headersection span.dropdown.expreco_header_dropdownbox button.btn.btn-default.dropdown-toggle {
    padding: 0px 5px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
    border-color: #f2f2f2;
    color: #656565;
    background-color: #f2f2f2 !important;
}

header.expreco_headersection span.dropdown.expreco_header_dropdownbox .btn.focus,
header.expreco_headersection span.dropdown.expreco_header_dropdownbox .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

header.expreco_headersection span.dropdown.expreco_header_dropdownbox span.dropdown-menu.expreco_header_dropdown_menu {
    border-color: #f2f2f2;
    color: #656565;
    background-color: #f2f2f2 !important;
    min-width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

header.expreco_headersection span.dropdown.expreco_header_dropdownbox span.dropdown-menu.expreco_header_dropdown_menu div.dropdown-item {
    padding: 3px 5px;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
}

span.recoheader_usertetxoverflow {
    max-width: 108px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: inline-block;
}


/* -------- start media code  ------------*/
@media (max-width: 767px) {
    header.expreco_headersection .expreco_dispinlinerightnav {
        display: block;
    }

    header.expreco_headersection li.nav-item.dropdown.dropleft.callmemainbox {
        min-width: 75px;
        float: right;
        left: 5px;
    }

    span.recohead_compnametextoverflow {
        max-width: 165px !important;
    }

    .partywise_holdtights_box {

        min-width: 80% !important;
        width: 80% !important;
    }

    span.ignoresection_noboxbgclr {
        padding: 6px 3px !important;
    }

    span.Party_wise_reconciliation_datetorl_swbox {
        padding: 0px 17px 0px 2px !important;
    }

    input.form-control.Party_wise_reconciliation_datetorl_input {
        min-width: 40px !important;
        max-width: 40px !important;
        width: 40px !important;
    }

}

@media (min-width: 768px) and (max-width: 821px) {

    header.expreco_headersection li.nav-item.dropdown.dropleft.callmemainbox {
        min-width: 75px;
        float: right;
        left: 5px;
    }

    header.expreco_headersection li.nav-item.dropdown.profileuserpaddtop {
        float: right;
        right: 0px;
        top: -1px !important;
    }

    span.recohead_compnametextoverflow {
        max-width: 127px;
    }

    .partywise_holdtights_box {
        min-width: 65% !important;
        width: 65% !important;
        float: right;
    }

    .partywise_holdtights_defaulttextbox span.partywise_holdtights_clsicon {
        right: -2px;
    }
}

@media (min-width: 822px) and (max-width: 913px) {

    header.expreco_headersection li.nav-item.dropdown.dropleft.callmemainbox {
        min-width: 75px;
        float: right;
        left: 5px;
    }

    header.expreco_headersection li.nav-item.dropdown.profileuserpaddtop {
        float: right;
        right: 0px;
    }

    span.recohead_compnametextoverflow {
        max-width: 150px;
    }

    .partywise_holdtights_box {
        width: 300px !important;
        min-width: 300px !important;
        width: 300px !important;
    }

    .partywise_holdtights_defaulttextbox span.partywise_holdtights_clsicon {
        right: -2px !important;
    }
}

@media (min-width: 914px) and (max-width: 1025px) {

    header.expreco_headersection li.nav-item.dropdown.dropleft.callmemainbox {
        min-width: 75px;
        float: right;
        left: 5px;
    }

    header.expreco_headersection li.nav-item.dropdown.profileuserpaddtop {
        float: right;
        right: 0px;
        top: -8px !important;
    }

    .partywise_holdtights_box {
        max-width: 245px !important;
        min-width: 245px !important;
        width: 245px !important;
    }

    .partywise_holdtights_defaulttextbox span.partywise_holdtights_clsicon {
        right: 12px !important;
    }
}

@media (min-width: 1026px) and (max-width: 1151px) {

    header.expreco_headersection li.nav-item.dropdown.dropleft.callmemainbox {
        min-width: 75px;
        float: right;
        left: 16px !important;
        top: 5px !important;
    }

    header.expreco_headersection li.nav-item.dropdown.profileuserpaddtop {
        float: right;
        right: 0px;
    }

    .partywise_holdtights_box {
        max-width: 250px !important;
        min-width: 250px !important;
        width: 250px !important;
    }

}

@media (min-width: 1152px) and (max-width: 1281px) {

    .partywise_holdtights_box {
        max-width: 300px !important;
        min-width: 300px !important;
        width: 300px !important;
    }

}

@media (min-width: 1282px) and (max-width: 1400px) {

    .partywise_holdtights_box {
        max-width: 270px !important;
        min-width: 270px !important;
        width: 270px !important;
    }

}

/*===================end header css===============*/
/* ----- syncnow modal css ----*/
.modal.syncnow_IncomeTax_credentials_modal.show .modal-dialog {
    margin: 3rem auto auto;
    max-width: 360px;
}

.modal.syncnow_IncomeTax_credentials_modal.show .modal-dialog p.syncnow_IncomeTax_credentials_tracescread_fs {
    margin-bottom: 0px;
    padding: 5px 10px 5px 10px;
    background-color: #dddddd;
    font-size: 1rem;
    font-weight: 500;
}

.modal.syncnow_IncomeTax_credentials_modal.show .modal-dialog form.from.row.syncnow_IncomeTax_credentials_frmrow .form-group {
    margin-bottom: 0.5rem;
}

.modal.syncnow_IncomeTax_credentials_modal.show .modal-dialog form.from.row.syncnow_IncomeTax_credentials_frmrow label {
    font-size: 12px;
    color: #777171;
}

.modal.syncnow_IncomeTax_credentials_modal.show .modal-dialog form.from.row.syncnow_IncomeTax_credentials_frmrow input.form-control {
    height: calc(1.2em + 0.75rem + 2px) !important;
    padding: 0.2rem 0.75rem;
    font-size: 12px;
    font-weight: 500;
}

.modal.syncnow_IncomeTax_credentials_modal.show .modal-dialog button.btn.btn-default.syncnow_IncomeTax_credentials_subbtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    padding: 3px 10px 5px 10px;
    font-size: 12px;
    font-weight: 500;
    vertical-align: sub;
    border: 0px;
}
span.dropdown.partywise_reco_perioyr_dropbox.no-padding{
    padding: 0!important;
}
span.dropdown.partywise_reco_perioyr_dropbox {
    background-color: #f2f2f2;
    padding: 1px 0px 2px 0px !important;
}

span.dropdown.partywise_reco_perioyr_dropbox span.btn.btn-primary.dropdown-toggle {
    background-color: transparent;
    color: #31719b;
    padding: 0px 2px 0px 3px;
    font-size: 12px;
    font-weight: 500;
    border: 0px;
    vertical-align: bottom;
}

span.dropdown.partywise_reco_perioyr_dropbox span.dropdown-menu.partywise_reco_perioyr_dropboxmenu a,
span.dropdown.partywise_reco_perioyr_dropbox span.dropdown-menu.partywise_reco_perioyr_dropboxmenu a:hover,
span.dropdown.partywise_reco_perioyr_dropbox span.dropdown-menu.partywise_reco_perioyr_dropboxmenu li.nav-item.dropdown.userprodropdlist a.nav-link.dropdown-toggle {
    color: #31719b !important;
}

span.dropdown.partywise_reco_perioyr_dropbox span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show div.dropdown-item {
    padding: 2px 5px 2px 5px;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    display: inline-flex;
}

span.dropdown.partywise_reco_perioyr_dropbox span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show .dropdown-divider {
    padding: 0px 0px;
    margin: 0px;
}

span.dropdown-menu.yearsseldropbox_width.show {
    min-width: 101px !important;
}

span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show {
    min-width: 127px;
    padding: 0px;
    max-width: 100%;
    width: 100%;
    border-radius: 0px;
    overflow-x: auto;
    max-height: 320px;
}

span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show div.dropdown-item input.form-control.multiselbox_inputbox {
    height: calc(0.5em + 0.75rem + 0px);
    font-size: 11px;
    width: 13px;
    position: relative;
    top: 1px;
}

span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show div.dropdown-item input.form-control.multiselbox_inputbox:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show span.fytext_fs {
    vertical-align: middle;
    padding: 10px 4px;
    line-height: 0px;
    color: #16181b;
}

span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show div.dropdown-item.active,
span.dropdown-menu.partywise_reco_perioyr_dropboxmenu.show .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ffffff;
}


.partywise_holdtights_hidetextbox {
    position: absolute;
    z-index: 999;
    top: 5rem;
    background-color: #fff1a5;
    padding: 10px 10px;
    width: 419px;
    border: 1px solid #a6a6a6;
    box-shadow: 0px 4px 15px #a6a6a6;
}

.partywise_holdtights_hidetextbox .partywise_holdtights_synmin {
    padding-left: 23px;
    text-align: center;
}

.partywise_holdtights_hidetextbox .partywise_holdtights_contentbox h4 {
    font-size: 1rem;
    font-weight: 700;
}

.partywise_holdtights_hidetextbox .partywise_holdtights_contentbox p.partywise_holdtights_textfs {
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
}

.partywise_holdtights_hidetextbox a.show_iconbox {
    color: #f37b72;
    cursor: pointer;
    position: relative;
    top: -4px;
    right: 6px;
}

.partywise_holdtights_hidetextbox a.show_iconbox:hover {
    text-decoration: none;
}

.partywise_holdtights_hidetextbox span.partywise_holdtights_clsicon {
    position: absolute;
    top: -3px;
    right: 9px;
    cursor: pointer;
}

.partywise_holdtights_defaulttextbox span.partywise_holdtights_clsicon {
    position: absolute;
    top: -2px;
    right: 12px;
    cursor: pointer;
}

// span.dropdown.partywisereco_headerdroplist_yrwidth span.btn.btn-primary.dropdown-toggle {
//     width: 95px;
// }

span.dropdown.partywisereco_headerdroplist_monthwidth span.btn.btn-primary.dropdown-toggle {
    width: 123px;
}

span.dropdown.partywisereco_headerdroplist_yrwidth span.btn.btn-primary.dropdown-toggle {
    width: 105px;
}

.partywise_holdtights_hidetextbox span.partywise_holdtights_count_greentextclr {
    color: green;
}

/*# sourceMappingURL=header.css.map */


/*-------------------------------------*/

.progress-sync-popup {
    .progress-bar {
        overflow: visible !important;
    }


    .show-progress-count {
        float: right;
        position: absolute;
        right: 0;
        margin-right: 20px;
    }
}

.reco-drop-down.dropdown-toggle::after {

    margin-top: 5px;
    margin-right: 5px;

}

span.recohead_compnametextoverflow {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: block;
}

/*------ hold tights css box--------*/
.partywise_holdtights_box {
    display: inline-block;
    position: relative;
    top: 2px;
    max-width: 363px;
    min-width: 363px;
    width: 363px;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox {
    background-color: #fff1a5;
    padding: 1px 5px;
    border: 1px solid #b3b3b3;
    box-shadow: 0px 1px 4px #b3b3b3;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox .partywise_holdtights_syngif {
    position: relative;
    top: 0px;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox .partywise_holdtights_contentbox {
    text-align: center;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox .partywise_holdtights_contentbox h4 {
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 0px;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox .partywise_holdtights_contentbox p.partywise_holdtights_textfs {
    font-size: 10px;
    font-weight: 500;
    font-style: italic;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox a.partyrec_iconright {
    right: 11px !important;
    position: relative;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox a.show_iconbox {
    cursor: pointer;
    position: relative;
    top: -4px;
    right: 0px;
    color: #333;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox a.show_iconbox:hover {
    text-decoration: none;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox span.partywise_holdtights_clsicon {
    vertical-align: text-bottom;
    cursor: pointer;
}

.partywise_holdtights_box .partywise_holdtights_defaulttextbox span.partywise_holdtights_count_greentextclr {
    color: green;
}

.partywise_holdtights_hidetextbox {
    position: absolute;
    z-index: 999;
    top: 5rem;
    background-color: #fff1a5;
    padding: 10px 10px;
    width: 419px;
    border: 1px solid #a6a6a6;
    box-shadow: 0px 4px 15px #a6a6a6;

    .partywise_holdtights_hidetextbox .partywise_holdtights_synmin {
        padding-left: 23px;
        text-align: center;
    }

    .partywise_holdtights_hidetextbox .partywise_holdtights_contentbox h4 {
        font-size: 1rem;
        font-weight: 700;
    }

    .partywise_holdtights_hidetextbox .partywise_holdtights_contentbox p.partywise_holdtights_textfs {
        font-size: 12px;
        font-weight: 500;
        font-style: italic;
    }

    .partywise_holdtights_hidetextbox a.show_iconbox {
        color: #f37b72;
        cursor: pointer;
        position: relative;
        top: -4px;
        right: 11px;
    }

    .partywise_holdtights_hidetextbox a.show_iconbox:hover {
        text-decoration: none;
    }

    .partywise_holdtights_hidetextbox span.partywise_holdtights_clsicon {
        position: relative;
        top: 8px;
        right: -8px;
        cursor: pointer;
    }

    .partywise_holdtights_hidetextbox span.partywise_holdtights_count_greentextclr {
        color: green;
    }

}

.rotate_icon {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

.disabledoption {
    pointer-events: none;
    opacity: 0.4;
    cursor: default;
}

.sync_stripchallanfooter .exprit_expriebox_btnboxsss {
    padding: 2px 5px 2px 1px;
    font-weight: 500;
    white-space: initial;
    max-width: initial;
    display: inline-block;
    margin: 0px 1px;
    font-size: 14px;
    width: 100%;
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    /* color: #ff4131; */
    /* cursor: pointer;*/
    border-radius: 40px;
}

.sync_stripchallanfooter .updatebtn {
    margin-left: 1rem;
    background-color: #31719b;
    border: none;
    padding: 3px 10px 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    color: white;
    font-weight: 500;
}

.drc-border-anibar {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-size: 100%;
    font-weight: 900;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    --thickness: 0.20rem !important;
    --roundness: 1.1rem !important;
    --color: #0f6297;
    --opacity: 1;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    background: #f2f2f2;
    box-shadow: 0 2px 4px 0 #eee;
    border: none;
    border-radius: var(--roundness);
    cursor: pointer;
    display: block;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 4px 14px;
    border: 1px solid #5a839d;

}


.drc-border-anibar:after {
    border: 0 solid;
    border: 0 solid;
    box-sizing: border-box;
    box-sizing: border-box;
}

.drc-border-anibar:before {
    border: 0 solid;
    border: 0 solid;
    box-sizing: border-box;
    box-sizing: border-box;
}

.drc-border-anibar:disabled {
    cursor: default;
}

.drc-border-anibar:-moz-focusring {
    outline: auto;
}

.drc-border-anibar svg {
    // vertical-align: middle;
    border-radius: var(--roundness);
    display: block;
    filter: url(#glow);
    height: calc(100% + 2px);
    left: -1px;
    position: absolute;
    top: -1px;
    width: calc(100% + 2px);
    z-index: -100;
}

.sync_stripchallanfooter .updatebtn {
    margin-left: 1rem;
    background-color: #31719b;
    border: none;
    padding: 3px 10px 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    color: white;
    font-weight: 500;
}

@-webkit-keyframes snake {
    to {
      stroke-dashoffset: -300%;
    }
  }
  
  @keyframes snake {
    to {
      stroke-dashoffset: -300%;
    }
  }

rect {
    -webkit-animation-play-state: running;
    animation-play-state: running;
    opacity: var(--opacity);
}

rect:hover {
    filter: brightness(1.2);

}

rect:active {
    --opacity: 0;
}

rect.react_attribute {
    fill: none;
    stroke: var(--color);
    stroke-width: var(--thickness);
    rx: var(--roundness);
    stroke-linejoin: round;
    stroke-dasharray: 190%;
    stroke-dashoffset: 80;
    -webkit-animation: snake 5s linear infinite;
    animation: snake 5s linear infinite;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    width: 100%;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    opacity: var(--opacity);
}

.barclsiconrefresh {
    position: absolute;
    left: 7px;
    top: 8px;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}