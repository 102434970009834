.form-control {
    font-size: 12px;
    font-weight: 500;
}

.form-group label {
    font-size: 12px;
    color: #777171;
}
.red_text {
    color: #ff2c39;
}
.filteriocnsizeplus{cursor: pointer;}
/*=========== inner content css =========*/
input.form-control.searchbox {
    width: 100%;
}
span.searchiconlistofcomp {
    top: 1.7rem;
    position: relative;
    left: 0.5rem;
    color: #cab9b9;
    font-size: 14px;
}

.mainsearchbox .has-search .form-control {
    padding-left: 2.375rem;
}

.btn.btn-default.gstrretregbtnclr {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 28px;
  color: #fff;
  /* padding: 4px 15px 5px; */
  font-size: 12px;
  font-weight: 500;
    /* top: 4px; */
    float: right;
  position: relative;
}

.btn.btn-default.gstrretregbtnclr.marginLT {
    margin-left: 10px;
  }

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.listofcomph h4 {
    font-size: 1.2rem;
    margin-left: 8rem;
    text-align: left;
}
.clientmaster_bulkimpt_titlebox h4 {
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    left: 4rem;
}
.clientmaster_bulkimpt_titlebox {
    display: inline-flex;
}
td.overflowtooltipcont {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    
}
td.compannameovertooltip {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: auto;
}
td.pricpleadd2areco {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
td.consofbus {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    
}
input.searchbox::placeholder {
    color: #e4e4e4;
}
/*tr.disabled {
    color: #c7bfbf;
}*/

button.btn.btn-default.float-right.returnregbtn {
    padding: .3rem 1.3rem;
    font-size: 15px;
    margin-top: 2px;
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 40px;
    color: #fff;
    outline-color: #31719b;
    box-shadow: 0 0 0 0.0rem #31719b;
}
button.btn.btn-default.float-right.returnregbtn:focus {
    box-shadow: 0 0 0 0.0rem #31719b;
}

/*=========== inner content css =========*/
table.table.table-striped.listofcompniestb thead tr th span.filteriocnsizeplus img {
    width: 25px;
}
table.table.table-striped.listofcompniestb thead tr th span.filteriocnsize img {
    width: 16px;
}
table.table.table-striped.listofcompniestb thead tr th {
    border-top: 0px;
    font-size: 13.5px!important;
    text-align: center;
	padding: 6px 0px;
	font-weight: 500;
    vertical-align: bottom;
}
table.table.table-striped.listofcompniestb tr td {
    font-size: 12px;
    padding: .25rem .75rem;
    border: 0px;
}
span.dark_color_text {
    color: #31719b;
    cursor: pointer;
}
span.editiocnwidth img {
    width: 16px;
}
ul.pagination {
    font-size: 13.5px!important;
}
ul.pagination li.page-item.active {
    z-index: 3;
    color: #fff;
    background-color: #31719b !important;
    border-color: #31719b !important;
}


table.table.table-striped.usermanagetb tr td span img {
    width: 16px!important;
    margin: 0 4px;
}
.form-group.registerflot .MyDatePicker input.form-control {
    border: 0px;
    background-color: transparent;
    -webkit-user-modify: read-only;
}
.switcherbtnact.editcomptopp .switch.btn {
    border-radius: 40px;
    top: 5.7px;
    min-height: calc(0em + .75rem + 0px + 0px) !important;
}
.switcherbtnact.editcomptopp .switch.btn span.switch-on.btn.btn-outline-success {
    padding: 3px;
    border-color: #28a745;
    outline-color: #28a745;
}
.switcherbtnact.editcomptopp .switch.btn span.switch-off.btn.btn-outline-secondary {
    padding: 3px;
    border-color: #28a745;
    outline-color: #28a745;
}
/*============ start Speetch Bubble css ==================*/
.SPwrapper {
    max-width: 92px;
    margin: 6px 38px;
    top: 0rem;
    margin-top: 11.0rem;
    position: absolute;
}
.SPwrapper .speechbubblecmp {
    background-color: #f6fcff;
    color: #333;
    font-size: 8px;
    line-height: 1.7;
    padding: 0.5px 3px;
    cursor: default;
    text-align: left;
    border: 1px solid #dad8d8;
    margin-bottom: 0px;
    border-color: #fc7d72;
}
.speechbubblecmp p {
    padding: 0px 0px;
    margin-bottom: 3px;
}


.SPwrapper .speechbubblecmp:nth-child(2n+1):after {
    content: "";
    margin-top: 0px;
    position: absolute;
    bottom: 22.5px;
    padding-top: 1px;
    transform: rotate( 316deg);
    left: 84px;
    border-width: 0px 0px 14px 14px;
    border-style: solid;
    border-color: #dddddd transparent;
    display: block;
    width: 0;
    border-color: #fc7d7200 #fbb6b0 #fbb6b0 #fbb6b000;
}
.SPwrapper .speechbubblecmp:nth-child(2n+1) {
    border-left: 5px solid #fc7d72;
    border-color: #fbb6b0;
}
/*============ end leftside Speetch Bubble css ==================*/
/*============ start create comp. Speetch Bubble css ==================*/
.createcompSPwrapper {
    max-width: 133px;
    margin-left: 16.5rem;
    top: 0rem;
    margin-top: 7.5rem;
    position: absolute;
}
.createcompSPwrapper .createcompSPcmp {
    background-color: #f6fcff;
    color: #333;
    font-size: 9px;
    line-height: 1.7;
    padding: 0.5px 3px;
    cursor: default;
    text-align: left;
    border: 1px solid #dad8d8;
    margin-bottom: 0px;
    border-color: #fc7d72;
}
.createcompSPcmp p {
    padding: 0px 0px;
    margin-bottom: 3px;
}


.createcompSPwrapper .createcompSPcmp:nth-child(2n+1):after {
    content: "";
    margin-top: 0px;
    position: absolute;
    bottom: -6.5px;
    padding-top: 0px;
    transform: rotate( 404deg);
    left: 56px;
    border-width: 0px 0px 14px 14px;
    border-style: solid;
    border-color: #dddddd transparent;
    display: block;
    width: 0;
    border-color: #fc7d7200 #fbb6b0 #fbb6b0 #fbb6b000;
}
.createcompSPwrapper .createcompSPcmp:nth-child(2n+1) {
    border-left: 5px solid #fc7d72;
    border-color: #fbb6b0;
}

/* user expire smsalert css */
.card.uexpirepaddtopbrem {
    padding: 6px 16px;
    background-color: #f2f2f2;
    border-right: 0 solid;
    border-radius: 0;
    bottom: 0;
    position: fixed;
    z-index: 1030;
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.125) !important;
}
img.info_imgalertbox {
    width: 16px;
    padding-top: 2px;
}
span.expalerttext {
    font-weight: 700;
    color: #fc7d72;
}
.userexpirebox span.userexpiretextfs {
    font-size: 12px;
    vertical-align: middle;
    
}
button.btn.btn-default.paynowbtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 15px 5px 15px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}


.card.uexpirepaddtopbrem_expirebox {
    padding: 6px 16px;
    background-color: #f2f2f2;
    border-right: 0 solid;
    border-radius: 0;
    bottom: 0;
    font-size: 12px;
    position: fixed;
    z-index: 1030;
    width: 100%;
    cursor: pointer;
    border-top: 1px solid rgba(0,0,0,.125)!important;
}

span.exprit_expriebox {
    background-color: #31719b;
    border: 1px solid #fc7d72;
    position: absolute;
    padding: 3px 10px 3px 10px;
    top: -3.0rem;
    font-weight: 700;
    white-space: initial;
    max-width: 77px;
    display: inline-block;
    left: auto;
    right: auto;
    margin: 0px 1px 0px -26px;
    color: #fff;
    border-radius: 24px 24px 0px 0px;
}
.exprit_expriebox_btnbox {
    position: absolute;
    padding: 10px 5px 13px 1px;
    top: -3rem;
    font-weight: 500;
    white-space: initial;
    max-width: initial;
    display: inline-block;
    margin: 0px 1px;
    font-size: 14px;
    width: 212px;
    background-color: #31719b;
    border: 1px solid #fc7d72;
    color: #fff;
    border-radius: 24px 24px 0px 0px;
    cursor: pointer;
}
span.clickimg_icon {
    position: absolute;
    right: 0rem;
    bottom: 1px;
}
/*end user expire smsalert css */


p.youtrialexptextfs {
    font-size: 12px;
}
.modal.yourtrialexpirpoup.show .modal-dialog {
    margin: 10rem auto 0rem;
}

.modal-header.yourtrialexpirhead {
    padding: 1px 7px;
    background-color: #eee;
}
.modal-header.yourtrialexpirhead .trialexpttle {
    font-size: 1rem;
	padding-top: 4px;
}

button.btn.btn-default.trialexppaynowbtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 7px 15px;
    font-size: 12px;
    font-weight: 500;	
}
.modal-header.yourtrialexpirhead button.close:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

table.table.table-striped.clientmastertableimg thead tr th {
    border-top: 0px;
    font-size: 13.5px!important;
    text-align: center;
    padding: 6px 0px;
    font-weight: 500;
    vertical-align: bottom;
}
table.table.table-striped.clientmastertableimg tr td {
padding: 0px;
    font-size: 12px;
    font-weight: 400;
    text-align: center !important;
}
table.table.table-striped.clientmastertableimg tr td a {
    text-decoration: none;
    color: #31719b;
}

tr.clientmaster_blankpageimg-bgclr td{
    background-color: #eee;
}
tr.clientmaster_colpadd td {
padding: 20px 0.75rem !important;
}

.clientmaster_tbbgimg {
    background-position: center bottom;
    background-size: cover;
    background-image: url(../../images/clientmaster-bgimg.jpg);
    width: 100%;
    height: 200px;
}
span.clickhere-textfs {
    position: relative;
    top: 5.1rem;
    bottom: 0px;
} 

table.table.table-striped.monthwiseimgtb tr td {
    font-size: 11px;
    font-weight: 500;
      padding: 0px !important;
  }
  
  tr.clientwise_bgclr td {
    background-color: #eee;
    border-bottom: 1px solid #fff;
  }
  tr.clientwise_cellpadd td {
    padding: 17px 0.75rem !important;
  }
  tr.monthwise_bgclr td {
      background-color: #eee;
  }
  tr.clientwise_whitebgclr td {
    background-color: #fff;
  }
  tr.monthwise_cellpadd td {
    padding: 17px 0.75rem !important;
    background-color: #eee;
    border-bottom: 1px solid #fff;
  }
  .clientwise_tbbgimg {
    background-position: center bottom;
    background-size: cover;
    background-image: url(../../images/clientwise_bgimg.jpg);
    width: 100%;
    height: 280px;
  }
  .monthwise_tbbgimg {
    background-position: center bottom;
    background-size: cover;
    width: 100%;
    height: 180px;
  }
  span.clickhere-textfs {
    position: relative;
    top: 5.1rem;
    bottom: 0px;
  }
  span.monthwise-clickhere-textfs {
    position: relative;
    top: 5.3rem;
    bottom: 0px;
  }

#set_profile_pincode .close{
    display: none;
}
  

@media only screen and (min-width: 576px){
.yourtrialexpirpoup .modal-dialog {
    max-width: 300px;
    margin: 1.75rem auto;
}
}

a.btn.btn-default.clientmaster_bulkimp_btnbgclr {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 4px 15px 5px;
    font-size: 12px;
    font-weight: 500;
    left: 53%;
    top: 4px;
    position: relative;
}

/*============ start media code ==================*/

@media only screen and (max-width: 767px){
	.listofcomph h4 {
		font-size: 1.2rem;
		margin-left: 0rem;
		text-align: center;
	}
.exprit_expriebox_btnbox {
        position: relative;
        top:1rem;
    }
}

/*speechbubble media ================*/

@media only screen and (max-width: 639px){
    .SPwrapper {
        max-width: 87px;
        margin: 0px -0.5px;
        margin-top: 12.5rem;
        width: 24px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 12.5px;
        left: 20px;
        border-width: 0px 0px 6px 6px;
    }
    .speechbubblecmp p {
        font-size: 3px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 1px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        margin-left: 7.7rem;
        margin-top: 10.5rem;
    }
    .createcompSPwrapper .createcompSPcmp:nth-child(2n+1) {
        border-left: 3px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper .createcompSPcmp {
        font-size: 8px;
      }
}
@media only screen and (min-width: 640px) and (max-width: 767px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 39.7px;
        margin-top: 14.1rem;
        width: 49px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 5.5px;
        left: 43px;
        border-width: 0px 0px 10px 10px;
    }
    .speechbubblecmp p {
        font-size: 4px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        margin-left: 12.3rem;
        margin-top: 11.0rem;
    }
    .createcompSPwrapper .createcompSPcmp {
        font-size: 7px;
    }
    .createcompSPwrapper .createcompSPcmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPcmp p {
        padding: 0px 0px;
        margin-bottom: 0px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 800px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 0.5px;
        margin-top: 11.2rem;
        width: 46px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 16.5px;
        left: 40px;
        border-width: 0px 0px 10px 10px;
        
    }
    .speechbubblecmp p {
        font-size: 5px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 9.2rem;
        margin-top: 8.5rem;
    }
 .exprit_expriebox_btnbox {
        position: relative;
        top:1rem;
    }
}
@media only screen and (min-width: 801px) and (max-width: 991px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 17.5px;
        margin-top: 12rem;
        width: 46px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 12.5px;
        left: 38px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 5px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        max-width: 133px;
        margin-left: 9.7rem;
        top: 0rem;
        margin-top: 8.8rem;
    }
   .exprit_expriebox_btnbox {
        position: relative;
        top:1rem;
    }
}


@media only screen and (min-width: 992px) and (max-width: 1025px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 2.5px;
        margin-top: 11.7rem;
        width: 46px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 21.5px;
        left: 38px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 5px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        max-width: 133px;
        margin-left: 9.7rem;
        top: 0rem;
        margin-top: 8.5rem;
    }
    span.exprit_expriebox_btnbox {
        position: relative;
        top:1rem;
    }
}
@media only screen and (min-width: 1026px) and (max-width: 1170px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 21.5px;
        margin-top: 11.9rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 23.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        margin-left: 12.0rem;
    }
}
@media only screen and (min-width: 1171px) and (max-width: 1349px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 41.5px;
        margin-top: 10.9rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 54px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        margin-left: 15.0rem;
        top: 0.3rem;
    }
}
@media only screen and (min-width: 1350px) and (max-width: 1440px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 66.5px;
        margin-top: 11.1rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 55.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
    }
    .speechbubblecmp p {
        font-size: 9px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        margin-left: 16.3rem;
    }
}

@media only screen and (min-width: 1681px) and (max-width: 1791px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 238.5px;
        margin-top: 10.8rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 54px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 27.4rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 1792px) and (max-width: 1991px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 290.8px;
        margin-top: 11.0rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 54px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 30.9rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 1992px) and (max-width: 2191px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 405.8px;
        margin-top: 11.0rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 38.0rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 2192px) and (max-width: 2791px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 46.8rem;
        margin-top: 11.1rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 59.4rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 2792px) and (max-width: 2892px) {
    .SPwrapper {
        max-width: 92px;
        margin: 6px 89.2rem;
        margin-top: 11.1rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 101.8rem;
        max-width: 135px;
    }
}
@media only screen and (min-width: 2893px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 131.9rem;
        margin-top: 10.9rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 144.8rem;
        max-width: 137px;
    }
}
@media only screen and (min-width: 1350px) and (max-width: 1440px){
    .SPwrapper {
        max-width: 92px;
        margin: 0px 61.5px;
        margin-top: 11rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        margin-left: 16.3rem;
        margin-top: 7.9rem;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1680px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 139.5px;
        margin-top: 11.0rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 54px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }

    .createcompSPwrapper {
        margin-left: 21.4rem;
    }
}
@media only screen and (min-width: 1681px) and (max-width: 1791px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 238.5px;
        margin-top: 10.8rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 54px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 27.4rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 1792px) and (max-width: 1991px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 290.8px;
        margin-top: 11.0rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 54px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 30.9rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 1992px) and (max-width: 2191px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 405.8px;
        margin-top: 11.0rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 38.0rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 2192px) and (max-width: 2791px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 41.4rem;
        margin-top: 11.1rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 59.4rem;
        max-width: 134px;
    }
}
@media only screen and (min-width: 2792px) and (min-width: 2892px) {
    .SPwrapper {
        max-width: 92px;
        margin: 6px 89.2rem;
        margin-top: 11.1rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 101.8rem;
        max-width: 135px;
    }
}
@media only screen and (min-width: 2893px){
    .SPwrapper {
        max-width: 92px;
        margin: 6px 131.9rem;
        margin-top: 10.9rem;
        width: 61px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1):after {
        bottom: 27.5px;
        left: 53px;
        border-width: 0px 0px 14px 14px;
        
    }
    .speechbubblecmp p {
        font-size: 7px;
        margin-bottom: 0px;
    }
    .SPwrapper .speechbubblecmp:nth-child(2n+1) {
        border-left: 2px solid #fc7d72;
        border-color: #fbb6b0;
    }
    .createcompSPwrapper {
        margin-left: 144.8rem;
        max-width: 137px;
    }
}

/*  */

.overflowtextliablity {
    /* max-width: 96px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
  
  .input-group.exprcllist_searchbox input.form-control {
    font-weight: 500;
    padding: 9px 0px 9px 5px !important;
    font-size: 11px;
    border-radius: 0.25rem 0rem 0rem 0.25rem;
    border-right: 0px solid #ced4da;
    min-width: 260px;
  }
  
  span.input-group-text.expIT_search_icon {
  
    background-color: #fff;
    color: #31719b;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-left: 0px solid #ced4da;
    cursor: pointer;
  }
  
  .input-group.exprcllist_searchbox input.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
  }

  .exp_IT_clientlist_textfs h4 {
    font-size: 1.2rem;
    left: 4rem;
    position: relative;
    font-weight: 500 !important;
  }

  button.btn.btn-primary.by2abtnct {
    border-radius: 0px;
    padding: 0.1rem 1rem 0.2rem 1rem;
    color: #fff;
    background-color: #fc7d72;
    border-color: #f2f2f2;
    border: 1px solid #31719b;
    outline-color: #fc7d72;
    font-size: 14px;
    FONT-WEIGHT: 500;
}
button.btn.btn-primary.by2bbtnct {
    border-radius: 0px;
    padding: 0.1rem 1rem 0.2rem 1rem;
    color: #31719b;
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    font-weight: 500;
}

button.btn.btn-primary.by2abtnct.by2aborsct {
    border-top: 1px solid #31719b;
    border-bottom: 1px solid #31719b;
    border-right: 1px solid #31719b;
    border-left: 1px solid #31719b ;
}
button.btn.btn-primary.by2abtnct.by2bbtnborct {
    border-top: 1px solid #31719b;
    border-bottom: 1px solid #31719b;
    border-right: 1px solid #31719b;
    border-left: 0px solid #31719b;
}
button.btn.btn-primary.by2abtnct.by2aborsct.allbtnbordernonleft {
    border-left: 0px;
}
button.btn.btn-primary.by2abtnct.by2aborsct.allbtnbordernonright {
    border-right: 0px;
}

button.btn.btn-primary.by2abtnct {
    border-radius: 0px;
    padding: 0.1rem 1rem 0.2rem 1rem;
    color: #fff;
    background-color: #fc7d72;
    border-color: #f2f2f2;
    border: 1px solid #31719b;
    outline-color: #fc7d72;
    font-size: 14px;
    FONT-WEIGHT: 500;
}
button.btn.btn-primary.by2bbtnct {
    border-radius: 0px;
    padding: 0.1rem 1rem 0.2rem 1rem;
    color: #31719b;
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #31719b;
    outline-color: #31719b;
    font-size: 14px;
    font-weight: 500;
}

button.btn.btn-primary.clientmast_allrightbor {
    border-right: 0px;
}



/*-------- cl master banner css  ----------*/
.clientmaster_leftbottom_bannerbox {
    position: fixed;
    width: 11%;
    border-radius: 0 0 0 0;
    margin-left: -.6rem;
    bottom: 6px;
    overflow: hidden;
    transition: 2s;
    box-shadow: 0 1px 16px #000;
    cursor: pointer;
    z-index: 9999;
}